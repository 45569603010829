import * as Types from '../../../../generated/graphql';

import { ProjectBasicFragment } from './ProjectBasicFragment';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OwnedProjectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OwnedProjectsQuery = { __typename: 'Query', viewer: { __typename: 'UserViewer', id: string, ownedProjects: Array<(
      { __typename: 'Project' }
      & ProjectBasicFragment
    )> } };


export const OwnedProjectsQueryDocument = gql`
    query ownedProjectsQuery {
  viewer {
    id
    ... on UserViewer {
      ownedProjects {
        ...ProjectBasicFragment
      }
    }
  }
}
    ${ProjectBasicFragment}`;

/**
 * __useOwnedProjectsQuery__
 *
 * To run a query within a React component, call `useOwnedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnedProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOwnedProjectsQuery(baseOptions?: Apollo.QueryHookOptions<OwnedProjectsQuery, OwnedProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OwnedProjectsQuery, OwnedProjectsQueryVariables>(OwnedProjectsQueryDocument, options);
      }
export function useOwnedProjectsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OwnedProjectsQuery, OwnedProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OwnedProjectsQuery, OwnedProjectsQueryVariables>(OwnedProjectsQueryDocument, options);
        }
export type OwnedProjectsQueryHookResult = ReturnType<typeof useOwnedProjectsQuery>;
export type OwnedProjectsQueryLazyQueryHookResult = ReturnType<typeof useOwnedProjectsQueryLazyQuery>;