import { useCallback } from 'react';
import { useManagementApiClient } from 'modules/managementApi';
import { UpdateUserProfileInput } from 'generated/graphql';
import { useUpdateProfileMutation } from '../gql/generated/updateProfileMutation';
import { ViewerBasicQueryDocument } from '../gql/generated/viewerBasicQuery';

export function useUpdateProfile() {
  const client = useManagementApiClient();

  const [updateMutation, response] = useUpdateProfileMutation({
    client,
    refetchQueries: [{ query: ViewerBasicQueryDocument }],
    awaitRefetchQueries: true,
  });

  const updateProfile = useCallback(
    function (profile: UpdateUserProfileInput) {
      return updateMutation({
        variables: {
          input: profile,
        },
      }).then(value => value.data && value.data.updateUserProfile);
    },
    [updateMutation]
  );

  return [
    updateProfile,
    {
      loading: response.loading,
      profile: response.data && response.data.updateUserProfile,
      error: response.error,
    },
  ] as const;
}
