import { INTERCOM_APP_ID } from '../env';

interface Tracker {
  trackEvent(event: TrackEvent, metaData?: Record<string, any>): void;
}
export enum TrackEvent {
  PROJECT_CREATED = 'Created Project',
}
class IntercomTracker implements Tracker {
  trackEvent(event: TrackEvent, metaData?: Record<string, any>) {
    window.Intercom('trackEvent', event, metaData);
  }
}

class DebugTracker implements Tracker {
  trackEvent(event: TrackEvent, metaData?: Record<string, any>) {
    console.info('trackEvent', event, JSON.stringify(metaData, null, 2)); // eslint-disable-line no-console
  }
}

export const tracker: Tracker = INTERCOM_APP_ID
  ? new IntercomTracker()
  : new DebugTracker();
