import React from 'react';
import { castArray } from 'lodash';
import { PRODUCT_NAME } from 'env';

const DELIMITER = ' - ';
const SUFFIX = `${PRODUCT_NAME}`;

export const getTitleWithoutSuffix = () => {
  return window.document.title.replace(SUFFIX, '').split(DELIMITER);
};

export function PageTitle(props: {
  title: string | (string | undefined | null)[];
}) {
  React.useEffect(() => {
    window.document.title = [
      castArray(props.title).filter(Boolean).join(DELIMITER),
      SUFFIX,
    ]
      .filter(Boolean)
      .join(DELIMITER);
    return () => {
      window.document.title = SUFFIX;
    };
  }, [props.title]);

  return null;
}
