import { useManagementApiClient } from 'modules/managementApi';
import { UserFragment } from '../gql/generated/UserFragment';
import { useViewerBasicQuery } from '../gql/generated/viewerBasicQuery';

type ViewerUser = {
  id: string;
  user: UserFragment;
};

export function useUser(skip?: boolean) {
  const client = useManagementApiClient();
  const { loading, data, error } = useViewerBasicQuery({
    client,
    skip,
  });

  if (error) {
    throw error;
  }

  return {
    loading,
    error,
    user: data && ((data.viewer as ViewerUser).user as UserFragment),
  };
}
