import * as React from 'react';
import { AuthError } from 'components/Error/Error';
import { GlobalErrorOverlay } from 'components/Error/GlobalErrorOverlay';
import { Button, Inline, Text } from '@hygraph/baukasten';
import { Link } from 'react-router-dom';
import { AskForHelpCTA } from 'components/Error/AskForHelpCTA';
import { trans } from 'i18n';

interface Props {
  error: AuthError;
  resetError: () => void;
}

export const AuthErrorDisplay: React.FC<Props> = ({ error, resetError }) => (
  <GlobalErrorOverlay>
    <Text lineHeight="medium" color="neutral.500">
      {error.message}
    </Text>
    <Inline>
      <AskForHelpCTA errorMessage={error.message} />
      <Button<PropsOf<Link>> size="large" onClick={resetError} as={Link} to="/">
        {trans('Return to Login')}
      </Button>
    </Inline>
  </GlobalErrorOverlay>
);
