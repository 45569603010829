import React, { useState, useEffect } from 'react';

interface LocalStorageProvider {
  context: React.Context<any>;
  storageKey: string;
  children?: React.ReactNode;
  provideAlso?: Record<string, any>;
}

export function getStoredItem(storageKey: string) {
  const storage = localStorage.getItem(storageKey);
  try {
    return storage ? JSON.parse(storage) : undefined;
  } catch (e) {
    console.error(e);
    return storage;
  }
}

function setStoredItem(storageKey: string, value?: any) {
  if (typeof value === 'undefined') localStorage.removeItem(storageKey);
  else localStorage.setItem(storageKey, JSON.stringify(value));
}

export const LocalStorageProvider: React.FC<LocalStorageProvider> = props => {
  const {
    context: Context,
    storageKey,
    children = null,
    provideAlso = {},
  } = props;
  const [item, setItem] = useState(getStoredItem(storageKey));
  let mounted = true;

  useEffect(() => {
    return () => {
      mounted = false; // eslint-disable-line
    };
  }, []);

  const saveItem = newItem => {
    setStoredItem(storageKey, newItem);
    if (mounted) setItem(newItem);
  };

  return (
    <Context.Provider
      value={{
        item,
        setItem: saveItem,
        ...provideAlso,
      }}
    >
      {children}
    </Context.Provider>
  );
};
