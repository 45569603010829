import {
  object as yupObject,
  project as projectValidation,
  string as yupString,
} from '@graphcms/validation';
import { trans } from 'i18n';
import { useGetRegions } from './useGetRegions';

export const useProjectSchemaValidation = () => {
  const { regions } = useGetRegions();
  const selectableRegions =
    regions?.filter(r => r.selectable).map(item => item.id) ?? [];
  const regionValidator = yupString()
    .required('Region is a required field')
    .min(1, trans('Region is a required field'))
    .oneOf(selectableRegions, ({ value }) =>
      value.length
        ? `The selected region "${value}" is a paid-only region`
        : 'Region is a required field'
    );

  const validator = yupObject().shape({
    name: projectValidation.name.required(),
    description: projectValidation.description.nullable(),
    region: regionValidator,
  });

  return { validator };
};
