import { HygraphLogoFull } from 'lib/presentation/icons/HygraphLogoFull';
import { Link } from 'react-router-dom-v5-compat';
import { Box, Flex, PropsOf } from '@hygraph/baukasten';

export default function ProjectListTopBar() {
  return (
    <Box position="fixed" zIndex={100} left={0} width="100%" height="64">
      <Flex
        width="100%"
        height="100%"
        backgroundColor="white"
        borderBottom="layout"
        borderBottomColor="neutral.150"
        alignItems="center"
        justifyContent="center"
        paddingRight="var(--removed-body-scroll-bar-size, 0)"
      >
        <Flex<PropsOf<typeof Link>> as={Link} to="/">
          <HygraphLogoFull height="40" />
        </Flex>
      </Flex>
    </Box>
  );
}
