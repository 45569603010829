export function welcomeSurveyQuery() {
  return `#graphql
    query welcomeSurvey {
      welcomeSurveyCompaniesLogos(stage: PUBLISHED) {
        id
        logo {
          id
          url
        }
        logoTitle
      }
      welcomeSurveyCasesStudies(stage: PUBLISHED) {
        id
        title
        authorName
        authorRole
        authorPicture {
          id
          url
        }
      }
    }
  `;
}
