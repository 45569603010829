import { ExternalLink } from '@hygraph/icons';
import { Button, ButtonProps } from '@hygraph/baukasten';
import { trans } from 'i18n';

export function LearnMoreExternalLink(
  props: ButtonProps<PropsOf<'a'>> & { href: string }
) {
  return (
    <Button<PropsOf<'a'>>
      variant="link"
      iconAfter={ExternalLink}
      as="a"
      target="_blank"
      alignSelf="baseline"
      children={<>{trans('Learn more')}</>}
      {...props}
    />
  );
}
