import {
  AppContentPermission,
  AppPermissions,
  AppPermissionsInput,
  AppSchemaPermission,
  AppWebhooksPermission,
  AppUserPermission,
  FieldAppElementFeature,
} from 'generated/graphql';
import { trans } from 'i18n';
import {
  AppElementFragment,
  AppElementFragment_FieldAppElement_,
  AppElementFragment_FormSidebarAppElement_,
  AppElementFragment_PageAppElement_,
} from './gql/generated/AppElementFragment';

export type AppPermissionScope = keyof Omit<AppPermissions, '__typename'>;
export type AppPermissionTypes = AppPermissions[AppPermissionScope];

export const PermissionTypeLabel: Record<AppPermissionTypes, string> = {
  NONE: trans('No access'),
  READ: trans('Read only'),
  READ_WRITE: trans('Read / Write'),
};

export const PermissionScopeLabel: Record<AppPermissionScope, string> = {
  CONTENT: trans('Content API'),
  SCHEMA: trans('Schema'),
  WEBHOOKS: trans('Webhooks'),
  USER: trans('User'),
};

export const getPossiblePermissionsForScope = <T extends AppPermissionScope>(
  scope: T
) => {
  switch (scope) {
    case 'CONTENT': {
      return Object.values(AppContentPermission);
    }
    case 'SCHEMA': {
      return Object.values(AppSchemaPermission);
    }
    case 'WEBHOOKS': {
      return Object.values(AppWebhooksPermission);
    }
    case 'USER': {
      return Object.values(AppUserPermission);
    }
    default:
      return assertUnreachable(scope);
  }
};

export function assertUnreachable(impossible: never): never {
  throw new Error(`Can never be ${impossible}`);
}

export const defaultAppPermissions: Required<AppPermissionsInput> = {
  CONTENT: AppContentPermission.NONE,
  SCHEMA: AppSchemaPermission.NONE,
  WEBHOOKS: AppWebhooksPermission.NONE,
  USER: AppUserPermission.NONE,
};

export const AppElement = {
  isAppElement(element: any): element is AppElementFragment {
    if (!element) return false;
    return (
      AppElement.isFieldAppElement(element) ||
      AppElement.isSidebarAppElement(element) ||
      AppElement.isPageAppElement(element)
    );
  },

  isFieldAppElement(
    appElement: AppElementFragment
  ): appElement is AppElementFragment_FieldAppElement_ {
    if (!appElement) return false;
    return appElement?.__typename === 'FieldAppElement';
  },

  isSidebarAppElement(
    appElement: any
  ): appElement is AppElementFragment_FormSidebarAppElement_ {
    if (!appElement) return false;
    return appElement.__typename === 'FormSidebarAppElement';
  },

  isPageAppElement(
    appElement: any
  ): appElement is AppElementFragment_PageAppElement_ {
    if (!appElement) return false;
    return appElement.__typename === 'PageAppElement';
  },

  hasListRenderer(appElement: AppElementFragment) {
    if (!appElement) return false;
    if (!AppElement.isFieldAppElement(appElement)) return false;

    return appElement.features.includes(FieldAppElementFeature.ListRenderer);
  },

  hasFormRenderer(appElement: AppElementFragment) {
    if (!appElement) return false;
    if (!AppElement.isFieldAppElement(appElement)) return false;

    return appElement.features.includes(FieldAppElementFeature.FieldRenderer);
  },

  hasTableRenderer(appElement: AppElementFragment) {
    if (!appElement) return false;
    if (!AppElement.isFieldAppElement(appElement)) return false;

    return appElement.features.includes(FieldAppElementFeature.TableRenderer);
  },
};

export const getAppInstallUrl = (apiId: string, full = false) => {
  return `${full ? window.location.origin : ''}/apps/${apiId}/new`;
};
