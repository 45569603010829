import * as Types from '../../../../generated/graphql';

import { ProjectBasicFragment } from '../../../projects/gql/generated/ProjectBasicFragment';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RejectInviteMutationVariables = Types.Exact<{
  input: Types.RejectInviteInput;
}>;


export type RejectInviteMutation = { __typename: 'Mutation', rejectInvite: { __typename: 'RejectInvitePayload', invite: { __typename: 'Invite', id: string, project: (
        { __typename: 'Project' }
        & ProjectBasicFragment
      ) } } };


export const RejectInviteMutationDocument = gql`
    mutation rejectInviteMutation($input: RejectInviteInput!) {
  rejectInvite(input: $input) {
    invite {
      id
      project {
        ...ProjectBasicFragment
      }
    }
  }
}
    ${ProjectBasicFragment}`;

/**
 * __useRejectInviteMutation__
 *
 * To run a mutation, you first call `useRejectInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectInviteMutation, { data, loading, error }] = useRejectInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectInviteMutation(baseOptions?: Apollo.MutationHookOptions<RejectInviteMutation, RejectInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectInviteMutation, RejectInviteMutationVariables>(RejectInviteMutationDocument, options);
      }
export type RejectInviteMutationHookResult = ReturnType<typeof useRejectInviteMutation>;