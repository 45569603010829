import React, { useState } from 'react';
import { ApolloInlineError } from 'components/Error/ApolloErrorDisplay/ApolloInlineError';
import {
  Button,
  ButtonProps,
  Alert,
  Text,
  Dialog,
  Heading,
  DialogHeader,
  DialogContent,
  DialogFooter,
  Stack,
} from '@hygraph/baukasten';
import { ApolloError } from '@apollo/client';
import { trans } from 'i18n';
import { WithModalProps } from 'components/Modal/ModalContext';

interface SimpleConfirmDialogProps {
  title: string;
  alertMessage?: string;
  confirmButtonText: string;
  confirmButtonColor: ButtonProps['variantColor'];
  confirmButtonVariant?: ButtonProps['variant'];
  onConfirm: () => Promise<any>;
  bodyText?: React.ReactNode;
  loadingText?: string;
}

export const SimpleConfirmDialog = ({
  title,
  alertMessage,
  confirmButtonText,
  confirmButtonColor = 'primary',
  confirmButtonVariant = 'solid',
  onConfirm,
  onRequestClose,
  bodyText,
  loadingText = '',
}: WithModalProps<SimpleConfirmDialogProps>) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  return (
    <Dialog isOpen onDismiss={onRequestClose}>
      <DialogHeader>
        <Heading as="h4" fontWeight="medium">
          {title}
        </Heading>
      </DialogHeader>
      <DialogContent>
        <Stack gap="8">
          <ApolloInlineError error={error}>
            {(err, key) => (
              <Alert whiteSpace="pre-wrap" key={key}>
                {err.message || err}
              </Alert>
            )}
          </ApolloInlineError>

          <Text as="span">{bodyText}</Text>
          {alertMessage && (
            <Alert variantColor="warning" showStatusIcon>
              {alertMessage}
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogFooter>
        <Button
          onClick={e => {
            e.stopPropagation();
            onRequestClose();
          }}
          variant="ghost"
          size="large"
          data-test="SimpleConfirmDialogCancelAction"
          disabled={isSubmitting}
        >
          {trans('Cancel')}
        </Button>
        <Button
          type="submit"
          onClick={e => {
            e.stopPropagation();
            setSubmitting(true);
            onConfirm()
              .then(() => {
                setSubmitting(false);
                onRequestClose();
              })
              .catch(error => {
                setSubmitting(false);
                setError(error);
              });
          }}
          variant={confirmButtonVariant}
          size="large"
          variantColor={confirmButtonColor}
          disabled={isSubmitting}
          loading={isSubmitting}
          loadingText={loadingText}
          data-test="SimpleConfirmDialogConfirmAction"
        >
          {confirmButtonText}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
