import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
export type PendingInviteFragment = { __typename: 'Invite', id: string, code: string, createdAt: any, acceptedAt?: any | null | undefined, expiresAt: any, roles: Array<string>, status: Types.InviteStatus, issuer?: { __typename: 'User', profile: { __typename: 'Profile', name: string, picture?: string | null | undefined } } | null | undefined, project: { __typename: 'Project', id: string, name: string, description?: string | null | undefined, picture?: string | null | undefined } };

export const PendingInviteFragment = gql`
    fragment PendingInviteFragment on Invite {
  id
  code
  createdAt
  acceptedAt
  expiresAt
  issuer {
    profile {
      name
      picture
    }
  }
  roles
  project {
    id
    name
    description
    picture
  }
  status
}
    `;