import { Box, Card, CardProps, Flex } from '@hygraph/baukasten';
import React, { useEffect, useRef, useState } from 'react';

// TODO: make it global
interface Props extends CardProps {
  overlap: React.ReactNode;
}
export const CardOverlap: React.FC<Props> = props => {
  const { overlap, children, ...rest } = props;
  const [top, setTop] = useState(0);
  const elementRef = useRef<HTMLInputElement>();

  useEffect(() => {
    const { clientHeight } = elementRef?.current || {};
    if (clientHeight) setTop(clientHeight);
  }, [elementRef]);

  return (
    <Box>
      <Card alignItems="center" justifyContent="center" my="layoutM" {...rest}>
        <Flex
          position="relative"
          top={-(top / 2)}
          ref={elementRef}
          justifyContent="center"
        >
          {overlap}
        </Flex>
        <Box px="layoutXS" pb="layoutXS">
          {children}
        </Box>
      </Card>
    </Box>
  );
};
