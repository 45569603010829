import {
  REGIONAL_APPS_HOSTNAME,
  REGIONAL_MANAGEMENT_HOSTNAME_TEMPLATE,
} from 'env';

export const regionalBaseUrl = (regionId: string) => {
  const { protocol, port } = location;
  const regionSubdomain = regionId.toLocaleLowerCase();
  const hostname = REGIONAL_APPS_HOSTNAME!.replace('{region}', regionSubdomain);
  return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
};

const regionalManagementHostName = (regionId: string) =>
  REGIONAL_MANAGEMENT_HOSTNAME_TEMPLATE.replace(
    '{region}',
    regionId.toLowerCase()
  );

const isSecure = () => location.protocol.slice(0, -1).endsWith('s');

export const regionalApiUrl = (regionId?: string) => {
  if (!regionId) return;
  const { protocol } = location;
  const apiUrl = new URL(
    `${protocol}//${regionalManagementHostName(regionId)}/graphql`
  );
  return apiUrl.toString();
};

export const regionalSubscriptionsUrl = (regionId?: string) => {
  if (!regionId) return '';
  const hostName = regionalManagementHostName(regionId);
  const url = new URL(`ws${isSecure() ? 's' : ''}://${hostName}/subscriptions`);

  return url.toString();
};
