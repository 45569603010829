import { AvatarProps, Avatar } from '@hygraph/baukasten';
import appAvatarPlaceholder from 'modules/apps/assets/appAvatarPlaceholder.svg';

export function AppAvatar(props: AvatarProps) {
  return (
    <Avatar
      onError={e => {
        const nativeEvent = e.nativeEvent;
        if (nativeEvent.type === 'error') {
          const currentImg = nativeEvent.currentTarget as HTMLImageElement;
          currentImg.setAttribute('src', appAvatarPlaceholder);
        }
      }}
      square
      {...props}
    />
  );
}
