import { useManagementApiClient } from 'modules/managementApi';
import { ProjectBasicFragment } from '../gql/generated/ProjectBasicFragment';
import { useProjectQuery } from '../gql/generated/projectQuery';

export type ViewerProject = {
  id: string;
  project: ProjectBasicFragment;
};

export function useProject(id: string) {
  const client = useManagementApiClient();
  const { loading, data, error } = useProjectQuery({
    client,
    variables: {
      id,
    },
  });

  return {
    loading,
    error,
    viewer: data && (data.viewer as ViewerProject),
  };
}
