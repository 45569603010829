import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
export type AppElementFragment_FieldAppElement_ = { __typename: 'FieldAppElement', features: Array<Types.FieldAppElementFeature>, fieldType: Types.SimpleFieldType, id: string, name: string, apiId: string, type: Types.AppElementType, src: string, description?: string | null | undefined, config?: any | null | undefined, app: { __typename: 'App', id: string, apiId: string, avatarUrl: string } };

export type AppElementFragment_FormSidebarAppElement_ = { __typename: 'FormSidebarAppElement', id: string, name: string, apiId: string, type: Types.AppElementType, src: string, description?: string | null | undefined, config?: any | null | undefined, app: { __typename: 'App', id: string, apiId: string, avatarUrl: string } };

export type AppElementFragment_PageAppElement_ = { __typename: 'PageAppElement', id: string, name: string, apiId: string, type: Types.AppElementType, src: string, description?: string | null | undefined, config?: any | null | undefined, app: { __typename: 'App', id: string, apiId: string, avatarUrl: string } };

export type AppElementFragment = AppElementFragment_FieldAppElement_ | AppElementFragment_FormSidebarAppElement_ | AppElementFragment_PageAppElement_;

export const AppElementFragment = gql`
    fragment AppElementFragment on IAppElement {
  id
  name
  apiId
  type
  src
  description
  config
  app {
    id
    apiId
    avatarUrl
  }
  ... on FieldAppElement {
    features
    fieldType
  }
}
    `;