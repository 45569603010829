import { SVGIconSFC } from './types';

export const HygraphLogoFull: SVGIconSFC = props => (
  <svg viewBox="0 0 269 75" fill="none" {...props}>
    <path
      d="M87.1216 33.192V51H79.3611V35.32C79.3611 34.3307 79.1174 33.444 78.63 32.66C78.1614 31.8573 77.524 31.2227 76.718 30.756C75.9307 30.2893 75.0496 30.056 74.0749 30.056C73.1001 30.056 72.2097 30.2893 71.4037 30.756C70.5976 31.2227 69.9603 31.8573 69.4916 32.66C69.023 33.444 68.7887 34.3307 68.7887 35.32V51H61.0281L61 9H68.7606L68.7887 25.072C69.726 24.176 70.8226 23.476 72.0785 22.972C73.3532 22.468 74.6935 22.216 76.0994 22.216C78.1426 22.216 79.989 22.7107 81.6386 23.7C83.307 24.6893 84.6379 26.0147 85.6314 27.676C86.6249 29.3187 87.1216 31.1573 87.1216 33.192Z"
      fill="#090E24"
    />
    <path
      d="M110.138 23H118.405L102.996 65H94.7858L99.847 51L89.7245 23H98.2162L103.727 40.444L110.138 23Z"
      fill="#090E24"
    />
    <path
      d="M141.579 23H149.34V51.392C149.34 53.464 148.918 55.3307 148.075 56.992C147.231 58.672 146.078 60.1093 144.616 61.304C143.173 62.4987 141.504 63.4133 139.611 64.048C137.737 64.6827 135.75 65 133.65 65C131.663 65 129.76 64.608 127.942 63.824C126.124 63.0587 124.512 61.9947 123.106 60.632C121.7 59.2693 120.603 57.7013 119.816 55.928L126.902 52.708C127.295 53.6413 127.83 54.444 128.505 55.116C129.198 55.788 129.985 56.3013 130.866 56.656C131.766 57.0293 132.694 57.216 133.65 57.216C134.681 57.216 135.665 57.0947 136.603 56.852C137.559 56.6093 138.411 56.2453 139.161 55.76C139.911 55.2747 140.502 54.668 140.933 53.94C141.364 53.212 141.579 52.3627 141.579 51.392V48.144C140.717 49.208 139.667 50.076 138.43 50.748C137.212 51.4013 135.796 51.728 134.184 51.728C132.16 51.728 130.257 51.3453 128.476 50.58C126.714 49.8147 125.158 48.76 123.809 47.416C122.459 46.0533 121.4 44.4853 120.631 42.712C119.863 40.9387 119.479 39.0347 119.479 37C119.479 34.9653 119.863 33.0613 120.631 31.288C121.4 29.5147 122.459 27.956 123.809 26.612C125.158 25.2493 126.714 24.1853 128.476 23.42C130.257 22.6547 132.16 22.272 134.184 22.272C135.796 22.272 137.212 22.608 138.43 23.28C139.667 23.9333 140.717 24.792 141.579 25.856V23ZM134.297 44.308C135.572 44.308 136.724 43.9813 137.755 43.328C138.786 42.656 139.592 41.7693 140.174 40.668C140.773 39.5667 141.073 38.344 141.073 37C141.073 35.656 140.773 34.4333 140.174 33.332C139.592 32.212 138.786 31.3253 137.755 30.672C136.724 30.0187 135.572 29.692 134.297 29.692C133.041 29.692 131.888 30.0187 130.838 30.672C129.807 31.3253 128.983 32.212 128.364 33.332C127.745 34.4333 127.436 35.656 127.436 37C127.436 38.344 127.745 39.576 128.364 40.696C128.983 41.7973 129.807 42.6747 130.838 43.328C131.888 43.9813 133.041 44.308 134.297 44.308Z"
      fill="#090E24"
    />
    <path
      d="M155.341 51L155.313 23H163.073L163.101 25.072C164.038 24.176 165.135 23.476 166.391 22.972C167.666 22.468 169.006 22.216 170.412 22.216C171.443 22.216 172.474 22.3653 173.505 22.664L170.44 30.476C169.765 30.196 169.081 30.056 168.387 30.056C167.413 30.056 166.522 30.2893 165.716 30.756C164.91 31.2227 164.273 31.8573 163.804 32.66C163.336 33.444 163.101 34.3307 163.101 35.32V51H155.341Z"
      fill="#090E24"
    />
    <path
      d="M195.105 23H202.866V51H195.077L194.712 48.088C193.981 49.1707 193.043 50.048 191.9 50.72C190.756 51.392 189.416 51.728 187.879 51.728C185.78 51.728 183.821 51.336 182.002 50.552C180.184 49.768 178.581 48.6853 177.194 47.304C175.807 45.9227 174.72 44.3267 173.932 42.516C173.164 40.6867 172.78 38.7267 172.78 36.636C172.78 34.6387 173.145 32.772 173.876 31.036C174.607 29.3 175.638 27.7693 176.969 26.444C178.3 25.1187 179.828 24.0827 181.552 23.336C183.296 22.5893 185.17 22.216 187.176 22.216C188.901 22.216 190.447 22.58 191.816 23.308C193.203 24.036 194.412 24.96 195.443 26.08L195.105 23ZM187.682 44.308C188.957 44.308 190.11 43.9813 191.141 43.328C192.172 42.6747 192.987 41.7973 193.587 40.696C194.187 39.576 194.487 38.344 194.487 37C194.487 35.6373 194.187 34.4053 193.587 33.304C192.987 32.2027 192.172 31.3253 191.141 30.672C190.11 30.0187 188.957 29.692 187.682 29.692C186.389 29.692 185.217 30.0187 184.167 30.672C183.118 31.3253 182.284 32.212 181.665 33.332C181.046 34.4333 180.737 35.656 180.737 37C180.737 38.344 181.046 39.576 181.665 40.696C182.302 41.7973 183.146 42.6747 184.196 43.328C185.245 43.9813 186.407 44.308 187.682 44.308Z"
      fill="#090E24"
    />
    <path
      d="M216.536 65H208.775V23H216.536V26.024C217.435 24.9227 218.504 24.0267 219.741 23.336C220.978 22.6267 222.422 22.272 224.071 22.272C226.114 22.272 228.026 22.6547 229.807 23.42C231.607 24.1853 233.181 25.2493 234.531 26.612C235.881 27.956 236.94 29.5147 237.708 31.288C238.477 33.0613 238.861 34.9653 238.861 37C238.861 39.0347 238.477 40.948 237.708 42.74C236.94 44.532 235.881 46.1093 234.531 47.472C233.181 48.816 231.607 49.8707 229.807 50.636C228.026 51.4013 226.114 51.784 224.071 51.784C222.422 51.784 220.978 51.4293 219.741 50.72C218.504 50.0107 217.435 49.1147 216.536 48.032V65ZM223.959 29.692C222.684 29.692 221.531 30.028 220.5 30.7C219.488 31.3533 218.682 32.2307 218.082 33.332C217.501 34.4333 217.21 35.656 217.21 37C217.21 38.344 217.501 39.576 218.082 40.696C218.682 41.7973 219.488 42.6747 220.5 43.328C221.531 43.9813 222.684 44.308 223.959 44.308C225.233 44.308 226.396 43.9813 227.445 43.328C228.514 42.6747 229.357 41.7973 229.976 40.696C230.613 39.5947 230.932 38.3627 230.932 37C230.932 35.6747 230.623 34.4613 230.004 33.36C229.386 32.24 228.542 31.3533 227.473 30.7C226.424 30.028 225.252 29.692 223.959 29.692Z"
      fill="#090E24"
    />
    <path
      d="M268 33.192V51H260.239V35.32C260.239 34.3307 259.996 33.444 259.508 32.66C259.04 31.8573 258.402 31.2227 257.596 30.756C256.809 30.2893 255.928 30.056 254.953 30.056C253.978 30.056 253.088 30.2893 252.282 30.756C251.476 31.2227 250.839 31.8573 250.37 32.66C249.901 33.444 249.667 34.3307 249.667 35.32V51H241.906L241.878 9H249.639L249.667 25.072C250.604 24.176 251.701 23.476 252.957 22.972C254.232 22.468 255.572 22.216 256.978 22.216C259.021 22.216 260.867 22.7107 262.517 23.7C264.185 24.6893 265.516 26.0147 266.51 27.676C267.503 29.3187 268 31.1573 268 33.192Z"
      fill="#090E24"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.923 9.99986L26.1924 15.0001L17.4619 19.9997L8.73058 25V34.9999V40.0001V44.9997V50V55.0003L17.4619 50L26.1924 44.9997V40.0001V34.9999L17.4619 40.0001V30.0003L26.1924 25L34.923 19.9997V25V30.0003V34.9999V40.0001V44.9997V50L26.1924 55.0003L17.4619 59.9999L8.73058 65.0001L0 69.9997L8.73058 75L17.4619 69.9997L26.1924 65.0001L34.923 59.9999L43.6543 55.0003V44.9997V34.9999V25V21.1647V15.0001V5.00029L34.923 0V9.99986Z"
      fill="#090E24"
    />
  </svg>
);
