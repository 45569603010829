import {
  Button,
  Dialog,
  DialogFooter,
  DialogContent,
  DialogHeader,
  Heading,
  Progress,
  Stack,
  Combobox,
  Text,
  Alert,
} from '@hygraph/baukasten';
import { GField } from 'components/GField';
import { trans } from 'i18n';
import { useViewerApp } from 'modules/apps/hooks/useViewerApp';
import { Form } from 'react-final-form';
import {
  useParams,
  useLocation,
  useNavigate,
} from 'react-router-dom-v5-compat';
import { validateWithYup } from 'utils';
import { object, string } from '@graphcms/validation';
import { DialogAppPreview } from './DialogAppPreview';
import { useProjects } from 'modules/projects/hooks';
import { gql, useQuery } from '@apollo/client';
import { useManagementApiClient } from 'modules/managementApi';
import { regionalApiUrl } from 'modules/projects/helpers/regionalProjectUrl';
import { ProjectBasicFragment } from 'modules/projects/gql/generated/ProjectBasicFragment';

import { useGetStudioOrClassicURL } from 'hooks/useGetStudioOrClassicURL';
import { ApolloErrorDisplay } from 'components/Error/ApolloErrorDisplay';

export function AppInstallScopePicker() {
  const { appId } = useParams<{ appId: string }>();
  const { search } = useLocation();
  const { loading: appLoading, app, error } = useViewerApp(appId!);
  const { loading: projectsLoading, projectListData } = useProjects();

  const { getUrl } = useGetStudioOrClassicURL();

  const navigate = useNavigate();
  const resetError = () => navigate('/apps');

  if (appLoading || projectsLoading) return <Progress variant="slim" />;
  if (error) {
    return <ApolloErrorDisplay error={error} resetError={resetError} />;
  }

  const projects = app.allowedProjects ?? projectListData.projects;

  return (
    <Form<{ projectId: string; environmentName: string }>
      initialValues={{ projectId: '', environmentName: '' }}
      onSubmit={({ projectId, environmentName }) => {
        const project = projects.find(p => p.id === projectId);
        if (!project) return;
        const targetUrl = `${getUrl(project, environmentName)}/apps/${
          app.apiId
        }/new${search ? `?` + new URLSearchParams(search).toString() : ''}`;

        window.location.href = targetUrl.replace('master', environmentName);
      }}
      validate={validateWithYup(
        object({
          projectId: string()
            .trim()
            .nullable()
            .required(trans('Field is required')),
          environmentName: string()
            .trim()
            .nullable()
            .required(trans('Field is required')),
        })
      )}
      render={({ handleSubmit, values, form }) => {
        return (
          <Dialog isOpen>
            <form onSubmit={handleSubmit}>
              <DialogHeader>
                <Heading as="h4" styleAs="h4" fontWeight={500}>
                  {trans('App installation')}
                </Heading>
              </DialogHeader>
              <DialogContent>
                {app ? (
                  <Stack gap="24">
                    <Stack gap="8">
                      <Text
                        fontSize={'copy'}
                        lineHeight="sm"
                        color="neutral.800"
                      >
                        {projects.length > 0
                          ? trans(
                              `Choose the project and environment in which you'd like to install {{appName}}`,
                              { appName: app.name }
                            )
                          : trans(
                              'Add a project to install the app {{appName}}',
                              { appName: app.name }
                            )}
                      </Text>

                      <DialogAppPreview app={app} />
                    </Stack>
                    {projects.length > 0 ? (
                      <>
                        <GField
                          name="projectId"
                          label={trans('Project name')}
                          render={({ input, hasError }) => {
                            return (
                              <Combobox
                                items={projects.map(project => ({
                                  label: project.name,
                                  value: project.id,
                                }))}
                                {...input}
                                clearable
                                selectedItem={input.value}
                                hasError={hasError}
                                onChange={selected => {
                                  input.onChange(selected);
                                  form.change('environmentName', '');
                                }}
                              />
                            );
                          }}
                        />

                        <EnvironmentPicker
                          project={projects.find(
                            p => p.id === values.projectId
                          )}
                        />
                      </>
                    ) : (
                      <Alert variantColor="info" showStatusIcon>
                        {trans(
                          "It seems like you don't have any projects or required permissions to add this app on projects. Create a new project or contact the admin to continue."
                        )}
                      </Alert>
                    )}
                  </Stack>
                ) : (
                  <Alert>{trans('App not found')}</Alert>
                )}
              </DialogContent>
              <DialogFooter>
                <Button
                  size="large"
                  variant="ghost"
                  type="button"
                  onClick={() => {
                    const currentPath = window.location.pathname;
                    navigate(-1);

                    // fallback behavior if user cant go back anywhere
                    setTimeout(() => {
                      if (window.location.pathname === currentPath) {
                        navigate('/');
                      }
                    }, 500);
                  }}
                >
                  {trans('Cancel')}
                </Button>
                {(() => {
                  if (!app && !appLoading) return null;
                  if (projects.length === 0)
                    return (
                      <Button
                        size="large"
                        type="button"
                        onClick={() => {
                          navigate('/');
                        }}
                      >
                        {trans('Create new project')}
                      </Button>
                    );

                  return (
                    <Button size="large" type="submit">
                      {trans('Install app')}
                    </Button>
                  );
                })()}
              </DialogFooter>
            </form>
          </Dialog>
        );
      }}
    />
  );
}

const envQuery = gql`
  query Environments($id: ID!) {
    viewer {
      id
      ... on UserViewer {
        project(id: $id) {
          environments {
            id
            displayName
            name
          }
          viewerAsMember {
            isOwner
            roles {
              id
              managementPermissions {
                action
              }
            }
          }
        }
      }
    }
  }
`;

type EnvironmentQuery = {
  viewer: {
    project: {
      environments: {
        id: string;
        displayName: string;
        name: string;
      }[];
      viewerAsMember: {
        isOwner: boolean;
        roles: {
          id: string;
          managementPermissions: {
            action: string;
          }[];
        }[];
      };
    };
  };
};

function EnvironmentPicker({ project }: { project?: ProjectBasicFragment }) {
  const client = useManagementApiClient();

  const { loading, data } = useQuery<EnvironmentQuery>(envQuery, {
    variables: { id: project?.id },
    context: {
      apiUrl: regionalApiUrl(project?.region?.id),
    },
    client,
    fetchPolicy: 'cache-first',
    skip: !project,
  });

  const hasInstallationPermission = data?.viewer?.project.viewerAsMember.roles
    .map(r => r.managementPermissions)
    .flat()
    .find(permission => permission.action === 'APP_INSTALLATION_CREATE');

  const shouldShowPermissionAlert =
    !hasInstallationPermission && !loading && project;

  return (
    <>
      <GField
        name="environmentName"
        label={trans('Environment')}
        render={({ input, hasError }) => {
          return (
            <Combobox
              disabled={loading}
              items={(data?.viewer?.project.environments || []).map(env => ({
                label: env.displayName,
                value: env.name,
              }))}
              {...input}
              selectedItem={input.value}
              clearable
              hasError={hasError}
            />
          );
        }}
      />

      {shouldShowPermissionAlert && (
        <Alert variantColor="info" showStatusIcon>
          {trans(
            "You don't have the permission to install the app in that project. Please contact the admin of the project."
          )}
        </Alert>
      )}
    </>
  );
}
