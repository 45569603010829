import { FC } from 'react';
import { Button, Stack, Heading, Text, Box, Flex } from '@hygraph/baukasten';
import { Error404 } from '@hygraph/icons';
import { trans } from 'i18n';
import { Link } from 'react-router-dom-v5-compat';

export const PageNotFound: FC = () => (
  <Stack
    alignItems="center"
    justifyContent="center"
    height="100vh"
    width="100%"
    maxWidth="688"
    mx="auto"
    gap="40"
  >
    <Stack gap="28" alignItems="center">
      <Flex
        size="80"
        bg="neutral.150"
        justifyContent="center"
        alignItems="center"
        borderRadius="small"
      >
        <Box as={Error404} color="neutral.500" size="36" width="auto" />
      </Flex>
      <Stack alignItems="center">
        <Heading as="h1" fontSize="h1" lineHeight="h2">
          {trans('404 - Page not found')}
        </Heading>
        <Text color="neutral.500" fontSize="h4">
          {trans("It looks like the page you're looking for doesn't exist.")}
        </Text>
      </Stack>
    </Stack>
    <Button<PropsOf<typeof Link>> size="large" as={Link} to="..">
      {trans('Return to Projects')}
    </Button>
  </Stack>
);
