import {
  Dialog,
  DialogContent,
  DialogFooter,
  Button,
  DialogHeader,
  Stack,
  Label,
  Alert,
  Heading,
  Tooltip,
} from '@hygraph/baukasten';

import { WithModalProps } from 'components/Modal/ModalContext';
import { trans } from 'i18n';
import { CopyTextInput } from 'lib/presentation/CopyTextInput';
import { getAppInstallUrl } from '../helpers';
import { IApp } from 'generated/graphql';

export const ShareAppModal = (props: WithModalProps<{ app: IApp }>) => {
  const { onRequestClose, app } = props;
  return (
    <Dialog aria-label="Share app URL modal" onDismiss={onRequestClose} isOpen>
      <DialogHeader>
        <Heading as="h4" styleAs="h4" fontWeight={500}>
          {trans('Share')} {app.name}
        </Heading>{' '}
      </DialogHeader>
      <DialogContent>
        <Stack gap="16">
          <Label fontSize={'copy'}>
            {trans('Copy the link below to share this app with another user.')}
          </Label>
          <Tooltip label={trans('Click to copy')}>
            <CopyTextInput
              data-autofocus
              message={trans('Share URL copied to clipboard')}
              value={getAppInstallUrl(app.apiId, true)}
            />
          </Tooltip>
          <Alert variantColor="info" showStatusIcon>
            {trans(
              'Note: We currently allow only admin & developer roles to install apps on a project'
            )}
          </Alert>
        </Stack>
      </DialogContent>
      <DialogFooter>
        <Button size="large" onClick={onRequestClose}>
          {trans('Close')}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
