import {
  Box,
  Card,
  Divider,
  Image as BaukastenImage,
  Text,
} from '@hygraph/baukasten';
import { useModal } from 'components/Modal/useModal';
import { ProjectTitle } from 'components/ProjectList/ProjectListView';
import { StarterTemplate, Template } from 'generated/graphql';
import { trans } from 'i18n';
import { useEffect, useState } from 'react';
import { CreateTemplateDialog } from './CreateTemplateDialog';
import { useLocation } from 'react-router-dom';

export function CreateTemplateCard(props: {
  template: Template | StarterTemplate | any;
}) {
  const { template } = props;
  const { showModal } = useModal();
  const { search } = useLocation();
  const [preloadCoverPicture, setPreloadCoverPicture] =
    useState<HTMLImageElement | null>(null);

  useEffect(() => {
    if (template.coverPicture) {
      const img = new Image();
      img.src = template.coverPicture;
      img.onload = () => setPreloadCoverPicture(img);
    }
  }, [template.coverPicture]);

  // INFO: This is needed for the welcome page when clicking on the "Select one of our most popular templates" survey
  useEffect(() => {
    if (
      template.coverPicture &&
      preloadCoverPicture &&
      search.includes(`newTemplate=${template.id}`)
    ) {
      showModal(CreateTemplateDialog, {
        template,
        preloadCoverPicture,
      });
    }
  }, [
    template.id,
    template.coverPicture,
    template,
    preloadCoverPicture,
    search,
    showModal,
  ]);

  return (
    <Card
      key={template.id}
      onClick={() =>
        showModal(CreateTemplateDialog, {
          template,
          preloadCoverPicture,
        })
      }
      data-test="CreateTemplateCard"
      data-cy={template.name}
      data-testid={template.id}
      width="252"
      height="300"
      overflow="hidden"
      pb="l"
      borderRadius="medium"
      cursor="pointer"
      sx={{
        transition: '0.3s',
        '&:hover': {
          transform: 'translate3d(0, -4px, 0)',
          boxShadow: 'default',
        },
      }}
    >
      <Box
        width="248"
        height="180"
        role="presentation"
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontSize="62px"
        letterSpacing="-4px"
        style={{ textIndent: '-4px' }}
        fontWeight={600}
        sx={{ objectFit: 'cover' }}
        {...(template.picture
          ? { as: BaukastenImage, src: template.picture }
          : {
              bg: 'neutral.900',
              color: 'white',
              children: `${template.name
                .charAt(0)
                .toUpperCase()}${template.name.charAt(1)}`,
            })}
      />
      <Divider m="0" borderBottomWidth="0.1px" />
      <Box m="m">
        <Box
          maxWidth="100%"
          justifyContent="center"
          alignItems="center"
          minHeight="4em"
        >
          <ProjectTitle hasDescription={Boolean(template.description)}>
            {template.name}
          </ProjectTitle>
          {template.description && (
            <Text
              fontSize="tiny"
              color="neutral.500"
              lineHeight="18px"
              mb="s"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {template.description}
            </Text>
          )}
        </Box>
        {template.details && (
          <Text fontSize="interface" color="brand.500">
            {trans('Get started')}
          </Text>
        )}
      </Box>
    </Card>
  );
}
