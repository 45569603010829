import { AppLayout } from './AppLayout';
import { AppForm } from './AppForm';
import { trans } from 'i18n';
import { PageTitle } from 'lib/presentation/PageTitle';

export function NewApp() {
  return (
    <AppLayout>
      <PageTitle title={trans('New app')} />
      <AppForm />
    </AppLayout>
  );
}
