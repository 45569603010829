import { useCallback } from 'react';
import { useManagementApiClient } from 'modules/managementApi';
import { useUpdateAppSecretsMutation } from '../gql/generated/updateAppSecrets';
import { UpdateAppSecretsInput } from 'generated/graphql';

export function useUpdateAppSecrets() {
  const client = useManagementApiClient();

  const [updateMutation, { loading, data, error }] =
    useUpdateAppSecretsMutation({
      client,
    });

  const updateAppSecrets = useCallback(
    function (input: UpdateAppSecretsInput) {
      return updateMutation({
        variables: {
          input,
        },
      }).then(({ data }) => data && data.updateAppSecrets);
    },
    [updateMutation]
  );

  return [
    updateAppSecrets,
    {
      loading,
      secrets: data?.updateAppSecrets,
      error,
    },
  ] as const;
}
