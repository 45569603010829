import * as React from 'react';
import { Card, Heading, Flex, Box } from '@hygraph/baukasten';
import { Add as AddIcon } from '@hygraph/icons';
import { trans } from 'i18n';
import { useModal } from 'components/Modal/useModal';
import { CreateProjectDialog } from 'modules/projects/components/CreateProject/CreateProjectDialog';
import {
  Events,
  rudderstackTrack,
} from 'modules/tracking/hooks/useTrackRudderstack';
import { useLocation } from 'react-router-dom';

const CreateProjectCard: React.FC = () => {
  const { showModal } = useModal();

  const { search } = useLocation();

  // INFO: This is needed for the welcome page when clicking on the "Give me a clean project" button
  React.useEffect(() => {
    if (search.includes('newProject=true')) {
      showModal(CreateProjectDialog);
    }
  }, [search, showModal]);

  return (
    <Card
      overflow="hidden"
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      width="100%"
      flexDirection="column"
      cursor="pointer"
      backgroundColor="white"
      borderRadius="medium"
      sx={{
        transition: '0.3s',
        '&:hover': {
          transform: 'translate3d(0, -4px, 0)',
        },
      }}
      data-test="CreateProjectCard"
      data-cy="fromScratch"
      px="layoutM"
      py="layoutL"
      tabIndex={0}
      onClick={() => {
        rudderstackTrack(Events.CREATE_PROJECT, {
          type: 'create empty project',
        });
        showModal(CreateProjectDialog);
      }}
    >
      <Box
        backgroundColor="menuItemActiveBg"
        width="64"
        height="64"
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="medium"
      >
        <Box
          as={AddIcon}
          size="36"
          color="primary"
          width="100%"
          pointerEvents="none"
        />
      </Box>
      <Flex maxWidth="100%" minHeight="4em" alignItems="center">
        <Heading
          overflow="hidden"
          display="-webkit-box"
          minWidth="100%"
          fontSize="copy"
          lineHeight="18px"
          fontWeight={500}
          color="neutral.500"
          sx={{
            lineClamp: 2,
            boxOrient: 'vertical',
          }}
        >
          {trans('Add project')}
        </Heading>
      </Flex>
    </Card>
  );
};

export default CreateProjectCard;
