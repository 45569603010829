import { useCallback } from 'react';
import { useManagementApiClient } from 'modules/managementApi';
import {
  useSendUserFeedbackMutation,
  SendUserFeedbackMutationVariables,
} from '../gql/generated/sendFeedbackMutation';

export function useSendFeedback() {
  const client = useManagementApiClient();

  const [sendUserFeedbackMutation, response] = useSendUserFeedbackMutation({
    client,
  });

  const sendFeedback = useCallback(
    function (variables: SendUserFeedbackMutationVariables) {
      return sendUserFeedbackMutation({ variables });
    },
    [sendUserFeedbackMutation]
  );

  return [
    sendFeedback,
    {
      loading: response.loading,
      id: response.data && response.data.sendUserFeedback.id,
      error: response.error,
    },
  ] as const;
}
