import * as Types from '../../../../generated/graphql';

import { UserFragment } from './UserFragment';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ViewerBasicQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ViewerBasicQuery = { __typename: 'Query', viewer: { __typename: 'UserViewer', id: string, user: (
      { __typename: 'User' }
      & UserFragment
    ) } };


export const ViewerBasicQueryDocument = gql`
    query viewerBasicQuery {
  viewer {
    id
    ... on UserViewer {
      user {
        ...UserFragment
      }
    }
  }
}
    ${UserFragment}`;

/**
 * __useViewerBasicQuery__
 *
 * To run a query within a React component, call `useViewerBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerBasicQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerBasicQuery(baseOptions?: Apollo.QueryHookOptions<ViewerBasicQuery, ViewerBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerBasicQuery, ViewerBasicQueryVariables>(ViewerBasicQueryDocument, options);
      }
export function useViewerBasicQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerBasicQuery, ViewerBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerBasicQuery, ViewerBasicQueryVariables>(ViewerBasicQueryDocument, options);
        }
export type ViewerBasicQueryHookResult = ReturnType<typeof useViewerBasicQuery>;
export type ViewerBasicQueryLazyQueryHookResult = ReturnType<typeof useViewerBasicQueryLazyQuery>;