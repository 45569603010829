import {
  Button,
  Card,
  Inline,
  Progress,
  Stack,
  Text,
  IconButton,
  DropdownMenuItem,
  DropdownMenuList,
  DropdownMenu,
  DropdownMenuTrigger,
  Box,
  Flex,
  Heading,
  Image,
  Tooltip,
} from '@hygraph/baukasten';
import { Link, useNavigate } from 'react-router-dom-v5-compat';

import { AppLayout } from './AppLayout';
import { useViewerApps } from '../hooks/useViewerApps';
import {
  Add,
  Download,
  ChevronLeft,
  DeleteFilled,
  ShareFill,
  ExternalLink,
  MoreFill,
  PencilFill,
} from '@hygraph/icons';
import { useLocation } from 'react-router-dom';
import { trans } from 'i18n';
import { getAppInstallUrl } from '../helpers';

import { PRODUCT_DOMAIN } from 'env';
import createAppIllustration from 'modules/apps/assets/createAppIllustration.svg';
import exploreAppIllustration from 'modules/apps/assets/exploreAppIllustration.svg';
import { ShareAppModal } from './ShareAppModal';
import { useModal } from 'components/Modal/useModal';
import { DeleteAppModal } from './DeleteAppModal';
import { PageTitle } from 'lib/presentation/PageTitle';
import { AppAvatar } from './AppAvatar';
import { LearnMoreExternalLink } from 'lib/presentation';

export function AppsList() {
  const { apps = [], loading } = useViewerApps();
  const { state } = useLocation<{ prevTitle: string }>();
  const navigate = useNavigate();
  const { showModal } = useModal();
  if (loading) return <Progress variant="slim" />;

  const prevTitle =
    state?.prevTitle && state.prevTitle !== trans('Your apps')
      ? state.prevTitle
      : '';

  const hasApps = apps.length > 0;
  return (
    <AppLayout>
      <PageTitle title={trans('Your apps')} />
      <Stack gap={hasApps ? '56' : '24'}>
        <Stack gap="24">
          <Stack gap="16">
            <Button
              mr="auto"
              variant="link"
              variantColor="secondary"
              {...(prevTitle
                ? { onClick: () => navigate(-1) }
                : { as: Link, to: '/' })}
              iconBefore={ChevronLeft}
            >
              {prevTitle
                ? trans('Back to {{prevPath}}', { prevPath: prevTitle })
                : trans('Back to project list')}
            </Button>

            {hasApps && (
              <Inline alignItems="flex-start">
                <Stack gap="8">
                  <Text
                    fontWeight={700}
                    fontSize="h2"
                    as="h2"
                    lineHeight="32px"
                  >
                    {trans('Your Apps')}
                  </Text>
                  <Text fontSize={'interface'} color="neutral.500">
                    {trans(
                      'Build apps to enhance and extend the capabilities of the platform.'
                    )}{' '}
                    <LearnMoreExternalLink
                      href={`https://${PRODUCT_DOMAIN}/docs/app-framework`}
                    />
                  </Text>
                </Stack>
                <Button<PropsOf<typeof Link>>
                  as={Link}
                  iconBefore={Add}
                  to="new"
                  ml="auto"
                  alignSelf="baseline"
                >
                  {trans('Add new app')}
                </Button>
              </Inline>
            )}
          </Stack>

          {hasApps && (
            <Stack gap="12">
              {apps.map(app => (
                <Card
                  data-testid={app.apiId}
                  key={app.id}
                  borderRadius="medium"
                  border="card"
                  borderColor="neutral.100"
                  p="16"
                  as={Inline}
                  alignItems="flex-start"
                  flexWrap="nowrap"
                  sx={{
                    ':hover': {
                      boxShadow: 'card',
                    },
                  }}
                >
                  <AppAvatar src={app.avatarUrl} size="avatar.80" />
                  <Stack gap="4" paddingTop="20">
                    <Text
                      lineHeight="sm"
                      as="h4"
                      fontSize="medium"
                      fontWeight={500}
                      color="neutral.900"
                    >
                      {app.name}
                    </Text>
                    {app.description && (
                      <Text
                        fontSize="tiny"
                        color="neutral.500"
                        lineHeight="small"
                      >
                        {app.description}
                      </Text>
                    )}
                  </Stack>
                  <Inline ml="auto" gap="8" flexShrink={0} paddingTop="24">
                    <Tooltip label={trans('Install app')} placement="bottom">
                      <IconButton<PropsOf<typeof Link>>
                        icon={Download}
                        variant="ghost"
                        size="medium"
                        iconSize="icon.16"
                        as={Link}
                        to={getAppInstallUrl(app.apiId, false)}
                      />
                    </Tooltip>

                    <DropdownMenu>
                      <DropdownMenuTrigger
                        as={IconButton}
                        icon={MoreFill}
                        variant="ghost"
                        size="medium"
                        iconSize="icon.16"
                      />
                      <DropdownMenuList side="bottom" align="end">
                        <DropdownMenuItem
                          iconBefore={PencilFill}
                          as={Link}
                          to={app.apiId}
                        >
                          {trans('Edit app')}
                        </DropdownMenuItem>

                        <DropdownMenuItem
                          iconBefore={ShareFill}
                          onSelect={() => {
                            showModal(ShareAppModal, { app });
                          }}
                        >
                          {trans('Share app')}
                        </DropdownMenuItem>

                        <DropdownMenuItem
                          iconBefore={DeleteFilled}
                          variantColor="danger"
                          onSelect={() => {
                            showModal(DeleteAppModal, { app });
                          }}
                        >
                          {trans('Delete app')}
                        </DropdownMenuItem>
                      </DropdownMenuList>
                    </DropdownMenu>
                  </Inline>
                </Card>
              ))}
            </Stack>
          )}
        </Stack>

        <Stack gap="24">
          <Flex
            border="card"
            borderColor="neutral.200"
            borderRadius="small"
            bg="neutral.50"
            alignItems="center"
            px="52"
            py="40"
            gap="52"
          >
            <Stack gap="24">
              <Stack gap="12">
                <Heading
                  as="h2"
                  fontSize="h2"
                  lineHeight="h3"
                  fontWeight="bold"
                  color="neutral.850"
                >
                  {trans('Create and add your own app!')}
                </Heading>
                <Text
                  color="neutral.600"
                  fontSize="interface"
                  lineHeight="medium"
                >
                  {trans(
                    'Extend the core capabilities that Hygraph offers by creating your own custom apps and sharing them with other users.'
                  )}
                </Text>

                <LearnMoreExternalLink
                  lineHeight="small"
                  sx={{ '&&': { fontSize: 'interface' } }}
                  href={`https://${PRODUCT_DOMAIN}/docs/app-framework/first-steps/overview`}
                  iconBefore={ExternalLink}
                  iconAfter={undefined}
                >
                  {trans('How to build Hygraph apps')}
                </LearnMoreExternalLink>
              </Stack>
              <Box>
                <Button<PropsOf<typeof Link>>
                  iconBefore={Add}
                  as={Link}
                  to="new"
                >
                  {trans('Add new app')}
                </Button>
              </Box>
            </Stack>
            <Image
              src={createAppIllustration}
              flexShrink={0}
              height="220"
              width="384"
            />
          </Flex>
          <Flex
            border="card"
            borderColor="neutral.200"
            borderRadius="small"
            gap="24"
            padding="16"
            alignItems="center"
          >
            <Image src={exploreAppIllustration} size="96" flexShrink={0} />

            <Stack gap="8">
              <Heading styleAs="h4" fontWeight={700}>
                {trans('Want to explore existing Apps?')}
              </Heading>
              <Text color="neutral.600" fontSize="copy">
                {trans(
                  'Explore a growing ecosystem of starter projects, schema templates, apps, and more, to supercharge your Hygraph projects.'
                )}
              </Text>
            </Stack>
            <Box flexShrink={0}>
              <LearnMoreExternalLink
                href={`https://${PRODUCT_DOMAIN}/marketplace`}
                iconBefore={ExternalLink}
                iconAfter={undefined}
                sx={{ '&&': { fontSize: 'interface' } }}
              >
                {trans('Go to Marketplace')}
              </LearnMoreExternalLink>
            </Box>
          </Flex>
        </Stack>
      </Stack>
    </AppLayout>
  );
}
