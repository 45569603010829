import { Box, Flex, Heading, Text, Select, Option } from '@hygraph/baukasten';
import { CreateTemplateCard } from 'modules/projects/components/CreateProject/CreateTemplateCard';
import {
  ProjectListWrapper,
  ProjectsStack,
} from 'components/ProjectList/ProjectListView';
import { ITemplate } from 'generated/graphql';
import { trans } from 'i18n';
import { useState } from 'react';
import CreateProjectCard from '../../modules/projects/components/CreateProject/CreateProjectCard';

enum FilterOptions {
  All = 'All',
  Template = 'Template',
  StarterTemplate = 'StarterTemplate',
}

const ProjectListViewFilter = (props: {
  filterOption: FilterOptions;
  onSelect: (filterOption: FilterOptions) => void;
}) => {
  const { filterOption, onSelect } = props;
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Flex alignItems="center">
        <Text
          color="neutral.500"
          fontSize="interface"
          lineHeight="16px"
          fontWeight={500}
          mr="s"
        >
          {trans('Show')}
        </Text>
        <Select
          defaultValue={filterOption}
          size="small"
          onValueChange={onSelect}
        >
          <Option value={FilterOptions.All}>{trans('All')}</Option>
          <Option value={FilterOptions.Template}>{trans('Templates')}</Option>
          <Option value={FilterOptions.StarterTemplate}>
            {trans('Starters')}
          </Option>
        </Select>
      </Flex>
    </Flex>
  );
};

interface ProjectListTemplatesProps {
  templates: ITemplate[];
}

export const ProjectListTemplates = (props: ProjectListTemplatesProps) => {
  const { templates } = props;
  const [filterOption, setFilterOption] = useState(FilterOptions.All);

  const filteredTemplates =
    filterOption !== FilterOptions.All
      ? // @ts-expect-error
        templates.filter(template => template?.__typename == filterOption)
      : templates;

  return (
    <Box bg="neutral.50">
      <ProjectsStack>
        <Box ml="72px" mr="50px">
          <Flex justifyContent="space-between" alignItems="center">
            <Heading fontSize="h4" fontWeight={700}>
              {trans('Create new project')}
            </Heading>
            <ProjectListViewFilter
              filterOption={filterOption}
              onSelect={(filterOption: FilterOptions) =>
                setFilterOption(filterOption)
              }
            />
          </Flex>
          <Text color="neutral.600" fontSize="copy">
            {trans(
              'Create a blank project or start from a Starter or Schema template.'
            )}
          </Text>
        </Box>
        <ProjectListWrapper
          gridTemplateColumns="repeat(auto-fill, 250px)"
          gridGap={24}
        >
          <CreateProjectCard />
          {filteredTemplates?.map(template => (
            <CreateTemplateCard key={template.id} template={template} />
          ))}
        </ProjectListWrapper>
      </ProjectsStack>
    </Box>
  );
};
