import * as React from 'react';
import { AccountMenu } from '../../Account/AccountMenu';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  Box,
  Avatar,
  Flex,
} from '@hygraph/baukasten';
import { UserFragment } from 'modules/user/gql/generated/UserFragment';

interface ProjectListBottomBar {
  logout: ({ logoutFromStudio: boolean }) => void;
  user: UserFragment;
}

const ProjectListBottomBar: React.FC<ProjectListBottomBar> = ({
  logout,
  user,
}) => {
  return (
    <Flex
      position="fixed"
      height="56"
      width="100%"
      px="m"
      border="1px solid"
      borderColor="neutral.150"
      bottom={0}
      left={0}
      alignItems="center"
      justifyContent="flex-start"
      backgroundColor="white"
    >
      <DropdownMenu>
        <DropdownMenuTrigger
          as={Box}
          border="none"
          p={0}
          bg="transparent"
          height="36"
          cursor="pointer"
        >
          <Avatar
            size="avatar.40"
            title={user.profile.name}
            src={
              user.profile.picture !== null ? user.profile.picture : undefined
            }
            alt={user.profile.name}
            placeholder={user.profile.name}
          />
        </DropdownMenuTrigger>
        <AccountMenu logout={() => logout({ logoutFromStudio: true })} />
      </DropdownMenu>
    </Flex>
  );
};

export default ProjectListBottomBar;
