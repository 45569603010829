import React, { createContext, useContext, useMemo } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createClient } from './createClient';
import { useAuthClient } from '@hygraph/client-auth';

const ManagementApiContext = createContext<
  ApolloClient<NormalizedCacheObject> | undefined
>(undefined);

export function useManagementApiClient() {
  const client = useContext(ManagementApiContext);
  if (typeof client === 'undefined') {
    throw 'Not inside of a management api context';
  }
  return client;
}

export const ManagementApiProvider: React.FC<{
  logger?: typeof console;
  apiUrl: string;
  subscriptionsUrl: string;
}> = ({ logger = console, apiUrl, subscriptionsUrl, ...rest }) => {
  const { token = '', logout } = useAuthClient();
  const client = useMemo(
    () =>
      createClient({
        logger,
        token,
        onAuthError: logout,
        apiUrl,
        subscriptionsUrl,
      }),
    [logger, token, logout, apiUrl, subscriptionsUrl]
  );
  return <ManagementApiContext.Provider value={client} {...rest} />;
};
