import { LocalStorageProvider } from 'utils';
import InviteContext from './InviteContext';

const InviteProvider = ({ storageKey = 'Last_Invite_Code', ...props }) => (
  <LocalStorageProvider
    context={InviteContext}
    storageKey={storageKey}
    {...props}
  />
);

export default InviteProvider;
