import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CommonAssetConfigQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CommonAssetConfigQuery = { __typename: 'Query', viewer: { __typename: 'UserViewer', id: string, commonAssetConfig: { __typename: 'CommonFilestack', apiKey: string, path: string, security: { __typename: 'CommonFilestackSecurityOptions', enabled: boolean, auth?: { __typename: 'FilestackSecurityAuthOptions', policy: string, signature: string } | null | undefined } } } };


export const CommonAssetConfigQueryDocument = gql`
    query commonAssetConfigQuery {
  viewer {
    id
    ... on UserViewer {
      commonAssetConfig {
        apiKey
        path
        security {
          enabled
          auth {
            policy
            signature
          }
        }
      }
    }
  }
}
    `;

/**
 * __useCommonAssetConfigQuery__
 *
 * To run a query within a React component, call `useCommonAssetConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommonAssetConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommonAssetConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useCommonAssetConfigQuery(baseOptions?: Apollo.QueryHookOptions<CommonAssetConfigQuery, CommonAssetConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommonAssetConfigQuery, CommonAssetConfigQueryVariables>(CommonAssetConfigQueryDocument, options);
      }
export function useCommonAssetConfigQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommonAssetConfigQuery, CommonAssetConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommonAssetConfigQuery, CommonAssetConfigQueryVariables>(CommonAssetConfigQueryDocument, options);
        }
export type CommonAssetConfigQueryHookResult = ReturnType<typeof useCommonAssetConfigQuery>;
export type CommonAssetConfigQueryLazyQueryHookResult = ReturnType<typeof useCommonAssetConfigQueryLazyQuery>;