import * as Types from '../../../../generated/graphql';

import { AppFragment_App_, AppFragment_AppForOwner_, AppFragment_AppWithSecrets_ } from './AppFragment';
import { gql } from '@apollo/client';
import { AppFragment } from './AppFragment';
export type AppWithSecretsFragment = (
  { __typename: 'AppWithSecrets', clientId?: string | null | undefined, clientSecret?: string | null | undefined, accessType: Types.AppAccessType }
  & AppFragment_AppWithSecrets_
);

export const AppWithSecretsFragment = gql`
    fragment AppWithSecretsFragment on AppWithSecrets {
  ...AppFragment
  clientId
  clientSecret
  accessType
}
    ${AppFragment}`;