import { FC } from 'react';
import InviteConsumer from 'modules/user/invite/InviteConsumer';
import ProjectInvite from './ProjectInvite';
import { useToast } from '@hygraph/baukasten';
import { useAcceptInvite } from 'modules/invites/hooks';
import { trans } from 'i18n';

const InviteCodeActions: FC = () => {
  const toast = useToast();
  const [acceptInviteMutation] = useAcceptInvite();

  return (
    <InviteConsumer>
      {({ code, clearCode }) => {
        if (code) {
          async function acceptInvite() {
            try {
              await acceptInviteMutation({ code });
              toast({
                variantColor: 'success',
                description: trans('Invite accepted successfully'),
              });
              clearCode();
            } catch {
              toast({
                description: trans(
                  'Something wrong happened while processing the invitation'
                ),
              });
              clearCode();
            }
          }
          return (
            <ProjectInvite
              code={code}
              clearCode={clearCode}
              onError={message =>
                toast({
                  description: message,
                })
              }
              actions={{ accept: acceptInvite }}
            />
          );
        }
        return null;
      }}
    </InviteConsumer>
  );
};

export default InviteCodeActions;
