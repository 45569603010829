export { validateWithYup } from './validation';
export * from './helpers';
export * from './Tracker';
export * from './LocalStorageProvider';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function noop(...args: any[]): void {
  return undefined;
}

export function generateRandomId() {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substring(2, 11);
}

const uuidRegex =
  /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i;

const uuidRegexWithoutDashes = /^[0-9A-Fa-f]{32}$/;

export const isUUID = (uuid: string) => {
  if (uuid.includes('-')) {
    return uuidRegex.test(uuid);
  }

  return uuidRegexWithoutDashes.test(uuid);
};

/**
 * Format string to UUID format
 * @param {string} value string of 32 hexadecimal numbers
 * @returns {string} formatted UUID string
 */
export function formatUUID(value: string): string {
  const hypenPositions = [8, 12, 16, 20];

  const insert = <T>(source: T[], position: number, value: T): T[] => {
    return [...source.slice(0, position), value, ...source.slice(position)];
  };

  if (!uuidRegexWithoutDashes.test(value)) {
    return value;
  }

  let array = value.split('');
  let offset = 0;
  for (const num of hypenPositions) {
    const position = num + offset++;
    array = insert(array, position, '-');
  }
  return array.join('');
}
