import { memo } from 'react';
import isAfter from 'date-fns/isAfter';
import { usePendingInvite } from 'modules/user/hooks/usePendingInvite';
import { trans } from 'i18n';

interface Props {
  code: string;
  onError: (any) => void;
  clearCode: () => void;
  actions: {
    accept: () => void;
  };
}

const ProjectInvite: React.FC<Props> = ({
  code,
  clearCode,
  actions,
  onError,
}) => {
  const { error, loading, pendingInvite } = usePendingInvite(code);

  if (error) {
    onError(
      trans(
        'Something wrong happened while fetching the invitation. Please try again later'
      )
    );
    clearCode();
    return null;
  }

  if (loading) {
    return null;
  }

  if (!pendingInvite) {
    onError(trans('No invite found with this code.'));
    clearCode();
    return null;
  }

  const { acceptedAt, expiresAt } = pendingInvite;

  if (acceptedAt) {
    onError(trans('You already joined this project'));
    clearCode();
    return null;
  }

  if (isAfter(new Date(), new Date(expiresAt))) {
    onError(trans('This invitation is expired'));
    clearCode();
    return null;
  }

  actions.accept();

  return null;
};

export default memo(ProjectInvite, (prev, current) => {
  return prev.code === current.code;
});
