import * as Types from '../../../../generated/graphql';

import { AppFragment_App_, AppFragment_AppForOwner_, AppFragment_AppWithSecrets_ } from './AppFragment';
import { gql } from '@apollo/client';
import { AppFragment } from './AppFragment';
export type AppForOwnerFragment = (
  { __typename: 'AppForOwner', accessType: Types.AppAccessType, allowedUsers?: Array<string> | null | undefined }
  & AppFragment_AppForOwner_
);

export const AppForOwnerFragment = gql`
    fragment AppForOwnerFragment on AppForOwner {
  ...AppFragment
  accessType
  allowedUsers
}
    ${AppFragment}`;