import * as Types from '../../../../generated/graphql';

import { AppSummaryFragment_App_, AppSummaryFragment_AppForOwner_, AppSummaryFragment_AppWithSecrets_ } from './AppSummaryFragment';
import { gql } from '@apollo/client';
import { AppSummaryFragment } from './AppSummaryFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ViewerAppsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ViewerAppsQuery = { __typename: 'Query', viewer: { __typename: 'UserViewer', id: string, apps: Array<(
      { __typename: 'App' }
      & AppSummaryFragment_App_
    ) | (
      { __typename: 'AppForOwner' }
      & AppSummaryFragment_AppForOwner_
    ) | (
      { __typename: 'AppWithSecrets' }
      & AppSummaryFragment_AppWithSecrets_
    )> } };


export const ViewerAppsQueryDocument = gql`
    query ViewerAppsQuery {
  viewer {
    id
    ... on UserViewer {
      apps {
        ...AppSummaryFragment
      }
    }
  }
}
    ${AppSummaryFragment}`;

/**
 * __useViewerAppsQuery__
 *
 * To run a query within a React component, call `useViewerAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerAppsQuery(baseOptions?: Apollo.QueryHookOptions<ViewerAppsQuery, ViewerAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerAppsQuery, ViewerAppsQueryVariables>(ViewerAppsQueryDocument, options);
      }
export function useViewerAppsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerAppsQuery, ViewerAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerAppsQuery, ViewerAppsQueryVariables>(ViewerAppsQueryDocument, options);
        }
export type ViewerAppsQueryHookResult = ReturnType<typeof useViewerAppsQuery>;
export type ViewerAppsQueryLazyQueryHookResult = ReturnType<typeof useViewerAppsQueryLazyQuery>;