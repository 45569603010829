import { Progress, isToastActive, useToast } from '@hygraph/baukasten';
import { useAuthClient } from '@hygraph/client-auth';
import { PageNotFound } from 'components/App/PageNotFound';
import { Login } from 'components/Auth/Login';
import { PRODUCT_NAME } from 'env';
import { trans } from 'i18n';
import { useEffect, useRef } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CompatRoute, useLocation } from 'react-router-dom-v5-compat';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import { AuthWithProvider as Auth } from '../Auth';
import { ErrorBoundary } from '../Error';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';
import { VerifyEmail } from './VerifyEmail';

function useUpdateServiceWorker() {
  const location = useLocation();
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.update();
      });
    }
  }, [location.pathname]);
}

function App() {
  const toast = useToast();
  const { initiateSignup } = useAuthClient();
  const lastToastId = useRef<string | number | undefined>();
  serviceWorkerRegistration.register({
    onUpdate: registration => {
      const clickHandler = () =>
        registration.waiting
          ? registration.waiting.postMessage({ type: 'SKIP_WAITING' })
          : location.reload();
      const toastExists =
        !!lastToastId.current && isToastActive(lastToastId.current);
      lastToastId.current = toast({
        duration: false,
        variantColor: 'dark',
        title: trans('New version available'),
        description: trans(
          'An improved version of {{productName}} is available, please refresh to update.',
          { productName: PRODUCT_NAME }
        ),
        actionBtnTitle: trans('Refresh to update'),
        onClick: clickHandler,
        id: lastToastId.current,
        update: toastExists,
      });
    },
  });

  // Update service worker whenever there is a path change
  useUpdateServiceWorker();

  /**
   * Place here only routes that should work without being logged in.
   * If a route is only for logged-in users, then it should be placed in the AuthenticatedRoutes component
   */
  return (
    <ErrorBoundary>
      <Switch>
        <CompatRoute exact path="/login" render={() => <Login />} />
        <CompatRoute
          exact
          path="/signup"
          render={() => {
            initiateSignup();
            return null;
          }}
        />
        <CompatRoute
          exact
          path="/auth"
          render={() => (
            <Auth>
              <Progress variant="slim" />
            </Auth>
          )}
        />
        <CompatRoute exact path="/auth/verify" render={() => <VerifyEmail />} />
        <CompatRoute exact path="/404" render={() => <PageNotFound />} />
        <Route
          path="/" // FIX ME index
        >
          <AuthenticatedRoutes />
        </Route>
        <Redirect to="404" />
      </Switch>
    </ErrorBoundary>
  );
}

export default App;
