import { Alert, CheckBox, FieldSet, Input, Stack } from '@hygraph/baukasten';
import { GField } from 'components/GField';
import { CheckboxGField } from 'components/GField/CheckboxGField';
import { trans } from 'i18n';
import { useForm } from 'react-final-form';
import { RegionSelector } from './RegionSelector';

export function CloneProjectForm(props: { hideCloneOptions?: boolean }) {
  const { hideCloneOptions } = props;
  const { change } = useForm();

  return (
    <Stack gap="24" maxWidth="564">
      <GField
        name="name"
        label={trans('Project name')}
        render={({ input, meta }) => (
          <Input
            {...input}
            onFocus={e => {
              if (!meta.touched && !meta.submitting) {
                change(input.name, ''); // INFO: clear input value on first focus as we are pre-filling its value
              }
              input.onFocus?.(e);
            }}
          />
        )}
      />
      <GField
        name="description"
        label={trans('Project description')}
        isOptional
        render={({ input }) => <Input {...input} />}
      />
      <RegionSelector
        id="CloneProjectRegion"
        label={trans('Select region for the clone')}
      />

      {!hideCloneOptions && (
        <FieldSet label={trans('Clone project with')}>
          <Stack gap="12">
            <CheckboxGField
              name="content"
              label={trans('Content and assets')}
              hint={trans("Clone the project's content as well as its assets")}
              render={({ input }) => (
                <CheckBox
                  {...input}
                  checked={input.value}
                  onChange={event => change(input.name, event.target.checked)}
                />
              )}
            />
            <CheckboxGField
              name="webhooks"
              label={trans('Webhooks')}
              hint={trans(
                "Clone the project's webhooks. They will be disabled initially"
              )}
              render={({ input }) => (
                <CheckBox
                  {...input}
                  checked={input.value}
                  onChange={event => change(input.name, event.target.checked)}
                />
              )}
            />
          </Stack>
        </FieldSet>
      )}
      <Alert variantColor="info" showStatusIcon>
        {trans('Only the master environment of the project will be cloned.')}
      </Alert>
    </Stack>
  );
}
