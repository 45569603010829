import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
export type UserFragment = { __typename: 'User', id: string, createdAt: any, profile: { __typename: 'Profile', email: string, name: string, picture?: string | null | undefined, id: string, role?: string | null | undefined, purpose?: string | null | undefined, companyName?: string | null | undefined, companySize?: string | null | undefined } };

export const UserFragment = gql`
    fragment UserFragment on IUser {
  id
  createdAt
  profile {
    email
    name
    picture
    id
    role
    purpose
    companyName
    companySize
  }
}
    `;