import * as React from 'react';
import { GraphQLError } from 'graphql';
// import { ErrorType } from 'components/Error/Error';
import { ApolloError } from '@apollo/client';
import { AskForHelpCTA } from 'components/Error/AskForHelpCTA';
import { Link } from 'react-router-dom';
import { Button, Text, StackOld, PropsOf } from '@hygraph/baukasten';
import { trans } from 'i18n';

interface Props {
  error: ApolloError;
  resetError: () => void;
}

export const ApolloErrorContent: React.FC<Props> = ({ error, resetError }) => {
  return (
    <StackOld alignItems="center">
      {error.graphQLErrors.map(
        (
          graphQLError: GraphQLError & {
            errorCode?: string;
          },
          index
        ) => {
          const err = new Error(graphQLError.message);
          return <Text key={index}>{err.message}</Text>;
        }
      )}
      {error.networkError &&
        getNetworkErrorMessages(error).map((err, index) => (
          <Text key={index}>{err}</Text>
        ))}
      <StackOld isInline>
        <AskForHelpCTA errorMessage={error.message} />
        {error.graphQLErrors.some(
          e =>
            e.extensions !== undefined &&
            e.extensions.code === 'ProjectNotFoundError'
        ) && (
          <Button<PropsOf<Link>>
            size="large"
            variantColor="primary"
            onClick={() => resetError()}
            as={Link}
            to="/"
          >
            {trans('Return to Projects')}
          </Button>
        )}
      </StackOld>
    </StackOld>
  );
};

function getNetworkErrorMessages(error: ApolloError) {
  const { networkError } = error;

  let errors = [trans('There seems to be a connection issue')];

  if (
    networkError &&
    'result' in networkError &&
    Array.isArray(networkError.result.errors)
  ) {
    errors = networkError.result.errors.reduce((acc, err) => {
      acc.push(err.message);
      return acc;
    }, []);
  }

  return errors;
}
