import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClonePublicProjectMutationVariables = Types.Exact<{
  data: Types.ClonePublicProjectInput;
}>;


export type ClonePublicProjectMutation = { __typename: 'Mutation', clonePublicProject: { __typename: 'ProjectPublicClonePayload', project: { __typename: 'Project', id: string } } };


export const ClonePublicProjectMutationDocument = gql`
    mutation clonePublicProjectMutation($data: ClonePublicProjectInput!) {
  clonePublicProject(input: $data) {
    project {
      id
    }
  }
}
    `;

/**
 * __useClonePublicProjectMutation__
 *
 * To run a mutation, you first call `useClonePublicProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClonePublicProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clonePublicProjectMutation, { data, loading, error }] = useClonePublicProjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useClonePublicProjectMutation(baseOptions?: Apollo.MutationHookOptions<ClonePublicProjectMutation, ClonePublicProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClonePublicProjectMutation, ClonePublicProjectMutationVariables>(ClonePublicProjectMutationDocument, options);
      }
export type ClonePublicProjectMutationHookResult = ReturnType<typeof useClonePublicProjectMutation>;