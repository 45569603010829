import * as Types from '../../../../generated/graphql';

import { AppFragment_App_, AppFragment_AppForOwner_, AppFragment_AppWithSecrets_ } from './AppFragment';
import { gql } from '@apollo/client';
import { AppFragment } from './AppFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ViewerAppQueryVariables = Types.Exact<{
  apiId: Types.Scalars['String'];
}>;


export type ViewerAppQuery = { __typename: 'Query', viewer: { __typename: 'UserViewer', id: string, app: (
      { __typename: 'App' }
      & AppFragment_App_
    ) | (
      { __typename: 'AppForOwner' }
      & AppFragment_AppForOwner_
    ) | (
      { __typename: 'AppWithSecrets' }
      & AppFragment_AppWithSecrets_
    ) } };


export const ViewerAppQueryDocument = gql`
    query ViewerAppQuery($apiId: String!) {
  viewer {
    id
    ... on UserViewer {
      app(apiId: $apiId) {
        ...AppFragment
      }
    }
  }
}
    ${AppFragment}`;

/**
 * __useViewerAppQuery__
 *
 * To run a query within a React component, call `useViewerAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerAppQuery({
 *   variables: {
 *      apiId: // value for 'apiId'
 *   },
 * });
 */
export function useViewerAppQuery(baseOptions: Apollo.QueryHookOptions<ViewerAppQuery, ViewerAppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerAppQuery, ViewerAppQueryVariables>(ViewerAppQueryDocument, options);
      }
export function useViewerAppQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerAppQuery, ViewerAppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerAppQuery, ViewerAppQueryVariables>(ViewerAppQueryDocument, options);
        }
export type ViewerAppQueryHookResult = ReturnType<typeof useViewerAppQuery>;
export type ViewerAppQueryLazyQueryHookResult = ReturnType<typeof useViewerAppQueryLazyQuery>;