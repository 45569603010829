import { SVGIconSFC } from 'lib/presentation/icons/types';
import { themeGet } from '@hygraph/baukasten';

const VerifyEmailIcon: SVGIconSFC = props => (
  <svg viewBox="0 0 421 313" width="421" height="313" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill={themeGet('colors.neutral.300')}
        d="M213 254.5L83 169v144h260V169z"
      />
      <path
        fill={themeGet('colors.brand.500')}
        d="M353.951 164.904l-4.095 19.911L327 178.913 331.09 159l22.861 5.904"
      />
      <path fill="#808BB0" d="M213 254.5L192.473 241 83 313h260l-109.473-72z" />
      <path
        fill={themeGet('colors.neutral.500')}
        d="M343 169.685L286.435 119l16.375 77zM117.722 139L83 169.333 129.641 200zM241.779 78.286L213.389 53 167.35 94z"
      />
      <path
        fill="#FAFAFC"
        d="M213.326 253.204l88.3-58.143-26.07-123.28-163.985 35.027 19.51 92.24z"
      />
      <path fill="#808BB0" d="M330.1 177.788v126.397L343 313V169z" />
      <path fill="#BAC2DB" d="M95.9 177.788v126.397L83 313V169z" />
      <path
        fill="#C9D2D8"
        d="M222.461 122c-2.079 0-3.947-1.414-4.401-3.477-.513-2.375 1.04-4.702 3.47-5.207l39.622-8.213c2.412-.523 4.816 1 5.338 3.38.513 2.37-1.036 4.702-3.466 5.207l-39.622 8.213c-.315.066-.63.097-.94.097M226.43 144c-2.078 0-3.946-1.414-4.4-3.478-.514-2.375 1.039-4.703 3.47-5.208l39.621-8.215c2.417-.51 4.816 1.005 5.338 3.38.513 2.372-1.035 4.704-3.465 5.209l-39.623 8.215c-.315.066-.63.097-.94.097M231.393 166c-2.08 0-3.947-1.414-4.402-3.477-.513-2.376 1.04-4.703 3.47-5.208l39.622-8.215c2.417-.514 4.816 1.005 5.338 3.38.513 2.372-1.035 4.703-3.465 5.208l-39.623 8.215c-.315.066-.63.097-.94.097"
      />
      <path
        fill={themeGet('colors.brand.500')}
        d="M199.192 235.615c-2.4 0-4.554-1.88-5.079-4.626-.592-3.16 1.2-6.256 4.004-6.927l45.718-10.93c2.788-.683 5.556 1.338 6.159 4.499.592 3.154-1.195 6.255-3.999 6.927l-45.718 10.929a4.624 4.624 0 01-1.085.128M160.951 93.904l-4.095 19.911L134 107.913 138.09 88l22.861 5.904"
      />
      <path
        fill={themeGet('colors.rose.700')}
        d="M23.06 221.22L14 205l11.597 1.656 9.058 16.224-11.595-1.66M410.761 129.586l9.894-4.586-10.763 13.307L400 142.88l10.761-13.294"
      />
      <path
        fill="#FFAB40"
        d="M112.171 66.14L92 58.405l10.139-22.947 20.173 7.728-10.14 22.952M19.675 253.777L5 246l18.785.794 14.672 7.779-18.782-.796"
      />
      <path
        fill={themeGet('colors.brand.500')}
        d="M409.771 248.543l-7.017 5.407-9.754-4.057 2.354-2.893 14.417 1.543"
      />
      <path
        fill={themeGet('colors.rose.700')}
        d="M250.139 35.018l-7.648 11.672L231 35.671 238.647 24l11.492 11.018"
      />
      <path
        fill={themeGet('colors.brand.500')}
        d="M312.783 13.993l-4.778 11.688L301 14.687 305.776 3l7.007 10.993"
      />
      <path
        fill="#FFAB40"
        d="M331.511 79.14l.069-6.979L315.067 72 315 78.979l16.511.161"
      />
      <path
        fill={themeGet('colors.brand.500')}
        d="M68.659 99.234L48 100.248l7.824-13.24L76.483 86l-7.824 13.234"
      />
      <path
        fill="#FFAB40"
        d="M140.782 10.992l-4.779 11.686L129 11.689 133.777 0l7.005 10.992"
      />
      <path
        fill={themeGet('colors.rose.700')}
        d="M14.675 129.776L0 122l18.784.793 14.672 7.779-18.781-.796"
      />
      <path
        fill="#FFAB40"
        d="M48 154.116L58.531 146l14.637 6.087-3.535 4.344L48 154.116"
      />
      <path
        fill="#101B42"
        d="M180.264 123.06l-9.068 7.24-9.068 7.241-9.069 7.242 1.794 11.325.897 5.663.897 5.662.897 5.663.897 5.663 9.068-7.241 9.068-7.241-.897-5.663-.896-5.663-9.068 7.242-1.794-11.326 9.068-7.241 9.068-7.241.897 5.663.896 5.663.897 5.662.897 5.663.897 5.662.897 5.663-9.068 7.242-9.068 7.24-9.068 7.242-9.068 7.24 10.861 4.085 9.069-7.241 9.068-7.241 9.068-7.241 9.068-7.24-1.793-11.327-1.794-11.325-1.794-11.326-.688-4.343-1.106-6.982-1.793-11.325-10.863-4.085z"
      />
    </g>
  </svg>
);

export default VerifyEmailIcon;
