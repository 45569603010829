import { useManagementApiClient } from 'modules/managementApi';
import { useCallback } from 'react';
import {
  useClonePublicProjectMutation,
  ClonePublicProjectMutationVariables,
} from '../gql/generated/clonePublicProjectMutation';
import { ProjectsQueryDocument } from '../gql/generated/projectsQuery';

export function useClonePublicProject() {
  const client = useManagementApiClient();

  const [clonePublicProjectMutation, response] = useClonePublicProjectMutation({
    client,
    refetchQueries: [{ query: ProjectsQueryDocument }],
    awaitRefetchQueries: true,
  });

  const clonePublicProject = useCallback(
    function (project: ClonePublicProjectMutationVariables) {
      return clonePublicProjectMutation({
        variables: project,
      }).then(value => value.data && value.data.clonePublicProject);
    },
    [clonePublicProjectMutation]
  );

  return [
    clonePublicProject,
    {
      loading: response.loading,
      project: response.data && response.data.clonePublicProject,
      error: response.error,
    },
  ] as const;
}
