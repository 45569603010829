import { useCallback } from 'react';
import { useManagementApiClient } from 'modules/managementApi';
import { useDeleteAccountMutation } from '../gql/generated/deleteAccountMutation';

export function useDeleteAccount() {
  const client = useManagementApiClient();

  const [deleteMutation, response] = useDeleteAccountMutation({
    client,
  });

  const deleteAccount = useCallback(
    function () {
      return deleteMutation().then(
        value => value.data && value.data.deleteAccount.deletedUserId
      );
    },
    [deleteMutation]
  );

  return [
    deleteAccount,
    {
      loading: response.loading,
      user: response.data && response.data.deleteAccount.deletedUserId,
      error: response.error,
    },
  ] as const;
}
