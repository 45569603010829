import * as Types from '../../../../generated/graphql';

import { UserFragment } from '../../../user/gql/generated/UserFragment';
import { ProjectBasicFragment } from './ProjectBasicFragment';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ProjectQuery = { __typename: 'Query', viewer: { __typename: 'UserViewer', id: string, user: (
      { __typename: 'User' }
      & UserFragment
    ), project?: (
      { __typename: 'Project' }
      & ProjectBasicFragment
    ) | null | undefined } };


export const ProjectQueryDocument = gql`
    query projectQuery($id: ID!) {
  viewer {
    id
    ... on UserViewer {
      user {
        ...UserFragment
      }
    }
    project(id: $id) {
      ...ProjectBasicFragment
    }
  }
}
    ${UserFragment}
${ProjectBasicFragment}`;

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectQueryDocument, options);
      }
export function useProjectQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectQueryDocument, options);
        }
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>;
export type ProjectQueryLazyQueryHookResult = ReturnType<typeof useProjectQueryLazyQuery>;