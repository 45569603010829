import {
  Avatar,
  Box,
  BoxProps,
  Card,
  CardProps,
  Divider,
  Flex,
  FlexProps,
  Grid,
  Heading,
  HeadingProps,
  StackOld,
  StackOldProps,
  Text,
} from '@hygraph/baukasten';
import { ModalProvider } from 'components/Modal/ModalContext';
import ModalRoot from 'components/Modal/ModalRoot';
import { ProjectListTemplates } from 'components/ProjectList/ProjectListTemplates';
import { trans } from 'i18n';
import { useTrackFullstoryUser } from 'modules/tracking/hooks/useTrackFullstoryUser';
import { UserFragment } from 'modules/user/gql/generated/UserFragment';
import React from 'react';
import InviteCodeActions from './InviteCodeActions';
import ProjectListBottomBar from './ProjectListBottomBar';
import ProjectListTopBar from './ProjectListTopBar';

import { useGetStudioOrClassicURL } from 'hooks/useGetStudioOrClassicURL';
import { ProjectsQuery } from 'modules/projects/gql/generated/projectsQuery';
import { Link } from 'react-router-dom';
import { ProjectListPendingInvites } from './ProjectListPendingInvites';
type Viewer = Extract<ProjectsQuery['viewer'], { __typename: 'UserViewer' }>;

export const ProjectsStack: React.FC<StackOldProps> = props => (
  <StackOld
    maxWidth="960"
    space="xs"
    px="ml"
    pt="xl"
    sx={{
      margin: '0 auto',
    }}
    {...props}
  />
);

export const ProjectListWrapper: React.FC<BoxProps> = props => (
  <Grid
    overflowY="auto"
    justifyContent="center"
    ml="m"
    pb="layoutM"
    pt="layoutS"
    {...props}
  />
);

// used to center single line names with multi-line names
const ProjectTextFlexWrap: React.FC<FlexProps> = props => (
  <Box
    maxWidth="100%"
    justifyContent="center"
    alignItems="center"
    m="14px"
    minHeight="4em"
    {...props}
  />
);

const ProjectCard: React.FC<CardProps> = props => (
  <Card
    width="180px"
    height="262px"
    sx={{
      transition: '0.3s',
      '&:hover': {
        transform: 'translate3d(0, -4px, 0)',
        boxShadow: 'default',
      },
    }}
    {...props}
  />
);

export const ProjectTitle: React.FC<
  { hasDescription: boolean } & HeadingProps
> = props => {
  const { hasDescription } = props;

  return (
    <Heading
      overflow="hidden"
      maxWidth="100%"
      fontSize="copy"
      lineHeight="18px"
      mb="5px"
      sx={{
        textOverflow: 'ellipsis',
        overflow: hasDescription ? 'hidden' : 'initial',
        whiteSpace: hasDescription ? 'nowrap' : 'initial',
        display: hasDescription ? 'block' : '-webkit-box',
        WebkitLineClamp: hasDescription ? '2' : '3',
        WebkitBoxOrient: 'vertical',
      }}
      {...props}
    />
  );
};

interface ProjectCardDetailsProps {
  project: TypeProject;
}

type TypeProject = Props['projects'][number] | Props['pendingProjects'][number];

const ProjectCardDetails: React.FC<ProjectCardDetailsProps> = ({ project }) => {
  const { getUrl } = useGetStudioOrClassicURL();

  return (
    <Box
      key={project.id}
      {...('region' in project
        ? { as: 'a', href: getUrl(project) }
        : { as: Link, to: `/pending/${project?.id}` })}
    >
      <ProjectCard
        data-test="ProjectCard"
        data-testid={project.id}
        borderRadius="medium"
      >
        <Avatar
          size="avatar.180"
          fontSize="62px"
          letterSpacing="-4px"
          style={{ textIndent: '-4px' }}
          borderRadius="8px 8px 0 0"
          placeholder={project.name}
          src={project.picture || undefined}
          square
          mx="auto"
          display="block"
          role="presentation"
        />
        <Divider m="0" borderBottomWidth="0.1px" />
        <ProjectTextFlexWrap>
          <ProjectTitle hasDescription={Boolean(project.description)}>
            {project.name}
          </ProjectTitle>
          {project.description && (
            <Text
              fontSize="tiny"
              color="neutral.500"
              lineHeight="16px"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {project.description}
            </Text>
          )}
        </ProjectTextFlexWrap>
      </ProjectCard>
    </Box>
  );
};

interface Props {
  projects: Viewer['projects'];
  user: UserFragment;
  logout: () => void;
  pendingProjects: Viewer['pendingProjects'];
  pendingInvites: Viewer['pendingInvites'];
  templates: Viewer['templates'];
}

export const ProjectListView: React.FC<Props> = props => {
  const { projects, logout, user, pendingInvites, pendingProjects, templates } =
    props;
  const userData = user
    ? {
        id: user.id,
        email: user.profile.email,
        createdAt: user.createdAt,
      }
    : null;

  useTrackFullstoryUser(userData);

  return (
    <ModalProvider>
      <ProjectListTopBar />
      <Box
        pt="layoutXL"
        pb="56px"
        minHeight="100vh"
        fontSize="1rem"
        sx={{
          '@media only screen and (max-width: 1200px)': {
            fontSize: '0.9rem',
          },
          '@media only screen and (max-width: 960px)': {
            fontSize: '0.8rem',
          },
        }}
      >
        {pendingInvites?.length > 0 && (
          <ProjectListPendingInvites pendingInvites={pendingInvites} />
        )}

        {pendingProjects?.length > 0 && (
          <ProjectsStack>
            <Heading fontSize="h4" fontWeight="bold" ml="72px">
              {trans('My pending projects ({{length}})', {
                length: pendingProjects.length,
              })}
            </Heading>
            <ProjectListWrapper
              pt="s"
              gridTemplateColumns="repeat(auto-fill, 180px)"
              gridGap={24}
              data-testid="PendingProjectList"
            >
              {pendingProjects.map(pendingProject => (
                <ProjectCardDetails
                  key={pendingProject.id}
                  project={pendingProject}
                />
              ))}
            </ProjectListWrapper>
          </ProjectsStack>
        )}
        {projects?.length > 0 && (
          <ProjectsStack>
            <Box ml="72px" mr="50px">
              <Flex
                justifyContent="space-between"
                alignItems="center"
                gap={'12'}
              >
                <Heading
                  title={user.profile.name}
                  fontSize="h4"
                  fontWeight={700}
                  sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  overflow="hidden"
                >
                  {trans('Welcome back, {{userName}}', {
                    userName: user.profile.name,
                  })}
                </Heading>
              </Flex>
              <Text color="neutral.600" fontSize="copy">
                {trans('Here are your recent projects')}
              </Text>
            </Box>
            <ProjectListWrapper
              pt="s"
              gridTemplateColumns="repeat(auto-fill, 180px)"
              gridGap={24}
              data-testid="ProjectList"
            >
              {projects.map(project => (
                <ProjectCardDetails key={project.id} project={project} />
              ))}
            </ProjectListWrapper>
          </ProjectsStack>
        )}

        <ProjectListTemplates templates={templates} />
      </Box>
      <InviteCodeActions />
      <ProjectListBottomBar logout={logout} user={user} />
      <ModalRoot />
    </ModalProvider>
  );
};
