import * as Types from '../../../../generated/graphql';

import { RegionFragment } from './RegionFragment';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RegionsQuery = { __typename: 'Query', viewer: { __typename: 'UserViewer', id: string, regions: Array<(
      { __typename: 'Region' }
      & RegionFragment
    )> } };


export const RegionsQueryDocument = gql`
    query regionsQuery {
  viewer {
    id
    regions {
      ...RegionFragment
    }
  }
}
    ${RegionFragment}`;

/**
 * __useRegionsQuery__
 *
 * To run a query within a React component, call `useRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegionsQuery(baseOptions?: Apollo.QueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegionsQuery, RegionsQueryVariables>(RegionsQueryDocument, options);
      }
export function useRegionsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegionsQuery, RegionsQueryVariables>(RegionsQueryDocument, options);
        }
export type RegionsQueryHookResult = ReturnType<typeof useRegionsQuery>;
export type RegionsQueryLazyQueryHookResult = ReturnType<typeof useRegionsQueryLazyQuery>;