import { useAuthClient } from '@hygraph/client-auth';

export function Login() {
  const { initiateLogin } = useAuthClient();
  const params = new URLSearchParams(location.search);
  const next = params.get('next');
  try {
    const nextUrl = next ? atob(next) : '/';
    initiateLogin(nextUrl);
  } catch (e) {
    initiateLogin('/');
  }

  return <p></p>;
}
