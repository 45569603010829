import React from 'react';
import InviteContext from './InviteContext';

const InviteConsumer = props => {
  const { children = () => null } = props;
  return (
    <InviteContext.Consumer>
      {({ item, setItem }: any) =>
        children({
          code: item,
          setCode: setItem,
          clearCode: () => setItem(undefined),
        })
      }
    </InviteContext.Consumer>
  );
};

export default InviteConsumer;
