const {
  NODE_ENV,
  REACT_APP_AUTH_COOKIE_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_CRM_API_ADDR,
  REACT_APP_GITHUB_REF,
  REACT_APP_GITHUB_SHA,
  REACT_APP_IMPERSONATE,
  REACT_APP_INTERCOM_APP_ID,
  REACT_APP_MAINTENANCE,
  REACT_APP_MANAGEMENT_API_ADDR_SUBSCRIPTIONS,
  REACT_APP_MANAGEMENT_API_ADDR,
  REACT_APP_MANAGEMENT_HOSTNAME_TEMPLATE,
  REACT_APP_PRODUCT_DOMAIN,
  REACT_APP_PRODUCT_NAME,
  REACT_APP_REGIONAL_APPS_HOSTNAME,
  REACT_APP_TEST_MODE,
  REACT_APP_LD_CLIENT_ID,
  REACT_APP_ENABLE_FULLSTORY_FOR_DIRECTORY,
  REACT_APP_STUDIO_LOGOUT_HOSTNAME,
} = process.env;

if (
  !NODE_ENV ||
  !REACT_APP_AUTH0_CLIENT_ID ||
  !REACT_APP_AUTH0_DOMAIN ||
  !REACT_APP_MANAGEMENT_API_ADDR ||
  !REACT_APP_MANAGEMENT_API_ADDR_SUBSCRIPTIONS ||
  !REACT_APP_MANAGEMENT_HOSTNAME_TEMPLATE
) {
  throw new Error(
    `You need to provide as ENV-Variable or in .env file:
    NODE_ENV,
    REACT_APP_AUTH0_CLIENT_ID,
    REACT_APP_AUTH0_DOMAIN,
    REACT_APP_MANAGEMENT_API_ADDR_SUBSCRIPTIONS,
    REACT_APP_MANAGEMENT_API_ADDR,
    REACT_APP_MANAGEMENT_HOSTNAME_TEMPLATE`
  );
}

export const AUTH_COOKIE_DOMAIN = REACT_APP_AUTH_COOKIE_DOMAIN;
export const AUTH0_CLIENT_ID = REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN = REACT_APP_AUTH0_DOMAIN;
export const CRM_API_ADDR = REACT_APP_CRM_API_ADDR;
export const GIT_COMMIT_REF = REACT_APP_GITHUB_REF;
export const GIT_COMMIT_SHA = REACT_APP_GITHUB_SHA;
export const IMPERSONATE = REACT_APP_IMPERSONATE === 'true';
export const INTERCOM_APP_ID = REACT_APP_INTERCOM_APP_ID;
export const MAINTENANCE = REACT_APP_MAINTENANCE === 'true';
export const MANAGEMENT_API_ADDR = REACT_APP_MANAGEMENT_API_ADDR;
export const MANAGEMENT_API_ADDR_SUBSCRIPTIONS =
  REACT_APP_MANAGEMENT_API_ADDR_SUBSCRIPTIONS;
export const PRODUCT_DOMAIN = REACT_APP_PRODUCT_DOMAIN;
export const PRODUCT_NAME = REACT_APP_PRODUCT_NAME;
export const REGIONAL_APPS_HOSTNAME = REACT_APP_REGIONAL_APPS_HOSTNAME;
export const STUDIO_LOGOUT_HOSTNAME = REACT_APP_STUDIO_LOGOUT_HOSTNAME;
export const REGIONAL_MANAGEMENT_HOSTNAME_TEMPLATE =
  REACT_APP_MANAGEMENT_HOSTNAME_TEMPLATE;
export const TEST_MODE = REACT_APP_TEST_MODE;
export const LD_CLIENT_ID = REACT_APP_LD_CLIENT_ID;
export const FULL_STORY_ENABLED =
  REACT_APP_ENABLE_FULLSTORY_FOR_DIRECTORY === 'true';
