import * as React from 'react';
import { Navigate } from 'react-router-dom-v5-compat';
import InviteConsumer from 'modules/user/invite/InviteConsumer';

interface InviteCodeProps {
  inviteCode: string;
}

export const InviteCode: React.FC<InviteCodeProps> = ({ inviteCode }) => (
  <InviteConsumer>
    {({ setCode, code }) => {
      if (code !== inviteCode) {
        setCode(inviteCode);
        return null;
      }
      return <Navigate to=".." replace />;
    }}
  </InviteConsumer>
);
