import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAppSecretsMutationVariables = Types.Exact<{
  input: Types.UpdateAppSecretsInput;
}>;


export type UpdateAppSecretsMutation = { __typename: 'Mutation', updateAppSecrets: { __typename: 'UpdateAppSecretsPayload', clientId?: string | null | undefined, clientSecret?: string | null | undefined } };


export const UpdateAppSecretsMutationDocument = gql`
    mutation updateAppSecretsMutation($input: UpdateAppSecretsInput!) {
  updateAppSecrets(input: $input) {
    clientId
    clientSecret
  }
}
    `;

/**
 * __useUpdateAppSecretsMutation__
 *
 * To run a mutation, you first call `useUpdateAppSecretsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppSecretsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppSecretsMutation, { data, loading, error }] = useUpdateAppSecretsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppSecretsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppSecretsMutation, UpdateAppSecretsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppSecretsMutation, UpdateAppSecretsMutationVariables>(UpdateAppSecretsMutationDocument, options);
      }
export type UpdateAppSecretsMutationHookResult = ReturnType<typeof useUpdateAppSecretsMutation>;