import {
  IconButton,
  InputGroup,
  Input,
  InputAddonAfter,
  InputProps,
} from '@hygraph/baukasten';
import { Copy } from '@hygraph/icons';
import { CopyText } from 'lib/presentation/CopyText';

export function CopyTextInput(
  props: InputProps & { value: string; message?: string }
) {
  const { value, message, ...rest } = props;
  return (
    <CopyText text={value} message={message}>
      <InputGroup>
        <Input value={value} readOnly {...rest} />
        <InputAddonAfter variant="button">
          <IconButton
            variant="outline"
            size="large"
            icon={Copy}
            iconSize="icon.20"
          />
        </InputAddonAfter>
      </InputGroup>
    </CopyText>
  );
}
