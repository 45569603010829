import Showdown from 'showdown';
import xssFilter from 'showdown-xss-filter';
import 'xss';
export const generateMarkdownPreview = (markdown: string) => {
  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    extensions: [xssFilter],
    strikethrough: true,
  });
  return converter.makeHtml(markdown);
};
