import React from 'react';

import { Box, BoxProps, Heading, Flex, mergeSx } from '@hygraph/baukasten';
import { themeGet } from '@hygraph/baukasten';
type TableProps = BoxProps;

export const Table: React.FC<TableProps> = React.forwardRef((props, ref) => (
  <Box
    as="table"
    width="100%"
    minWidth="385px"
    borderRadius="small"
    sx={{
      borderCollapse: 'collapse',
      borderStyle: 'hidden',
      boxShadow: `0 0 0 1px ${themeGet('colors.neutral.200')}`,
    }}
    ref={ref}
    {...props}
  />
));

export const Caption: React.FC<
  BoxProps & {
    caption: string;
  }
> = ({ caption, children, ...rest }) => (
  <Box as="caption" lineHeight="small" px={0} py="m" {...rest}>
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Heading
        as="h5"
        fontWeight="medium"
        textTransform="uppercase"
        fontSize="sectionTitle"
        color="neutral.600"
        letterSpacing="sectionTitle"
        lineHeight="small"
      >
        {caption}
      </Heading>
      {children}
    </Flex>
  </Box>
);

export const TableHeaderCell: React.FC<
  TableProps & { colSpan?: number; scope?: string }
> = props => (
  <Box
    as="th"
    fontSize="tiny"
    fontWeight="medium"
    lineHeight="small"
    color="neutral.800"
    py="sm"
    {...props}
  />
);

export const TableRow: React.FC<TableProps> = ({ sx, ...props }) => (
  <Box
    as="tr"
    borderTop="layout"
    borderTopColor="neutral.200"
    sx={mergeSx(
      {
        '&:first-of-type': { borderTop: 'none' },
        '&.model-group': {
          borderTop: 'tableHeader',
          borderTopColor: 'neutral.200',
          color: 'neutral.700',
        },
      },
      sx
    )}
    {...props}
  />
);

export const TableHead: React.FC<TableProps> = props => (
  <Box
    as="thead"
    borderBottom="tableHeader"
    borderColor="neutral.200"
    {...props}
  />
);

export const TableHeaderRow: React.FC<TableProps> = React.forwardRef(
  (props, ref) => (
    <Box
      ref={ref}
      as="tr"
      sx={{
        '&:first-of-type th:first-of-type': {
          borderTopLeftRadius: 'small',
        },
        '&:first-of-type th:last-of-type': {
          borderTopRightRadius: 'small',
        },
      }}
      bg="neutral.100"
      {...props}
    />
  )
);

export const TableBody: React.FC<TableProps> = React.forwardRef(
  (props, ref) => <Box as="tbody" ref={ref} {...props} />
);

export const TableCell: React.FC<TableProps & { colSpan?: number }> = props => (
  <Box as="td" {...props} />
);
