import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useToast } from '@hygraph/baukasten';
import { trans } from 'i18n';
interface CopyTextProps {
  text: string;
  message?: string;
}

export const CopyText: React.FC<CopyTextProps> = ({
  message = trans('Value copied to clipboard'),
  text,
  ...rest
}) => {
  const toast = useToast();

  return (
    <CopyToClipboard
      text={text}
      onCopy={() => toast({ variantColor: 'info', id: text, title: message })}
      {...rest}
    />
  );
};
