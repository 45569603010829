import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
export type AppPermissionsFragment = { __typename: 'AppPermissions', CONTENT: Types.AppContentPermission, SCHEMA: Types.AppSchemaPermission, WEBHOOKS: Types.AppWebhooksPermission, USER: Types.AppUserPermission };

export const AppPermissionsFragment = gql`
    fragment AppPermissionsFragment on AppPermissions {
  CONTENT
  SCHEMA
  WEBHOOKS
  USER
}
    `;