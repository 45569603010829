import { useManagementApiClient } from 'modules/managementApi';
import {
  OwnedProjectsQuery,
  useOwnedProjectsQuery,
} from '../gql/generated/ownedProjectsQuery';

export function useOwnedProjects() {
  const client = useManagementApiClient();
  const { loading, data, error } = useOwnedProjectsQuery({
    client,
  });

  return {
    loading,
    error,
    projectListData: data?.viewer as Extract<
      OwnedProjectsQuery['viewer'],
      { __typename: 'UserViewer' }
    >,
  };
}
