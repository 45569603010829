import schema from 'generated/graphql.schema.json';
import {
  ApolloClient,
  ApolloLink,
  defaultDataIdFromObject,
  FetchResult,
  from,
  HttpLink,
  InMemoryCache,
  Observable,
  Operation,
  split,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { Client, createClient as createWsClient } from 'graphql-ws';
import { GraphQLError, print } from 'graphql';

import { getRefShaCommit } from 'utils';
import { TEST_MODE } from 'env';

type Logger = typeof console;

class GraphQLWsLink extends ApolloLink {
  constructor(private client: Client) {
    super();
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable(sink => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: x => {
            if (TEST_MODE === 'rec') {
              // @ts-expect-error
              window.__OPERATIONS__.push({
                request: {
                  query: operation.query,
                  variables: operation.variables,
                },
                result: x,
              });
            }
            return sink.next(x as Record<string, any>);
          },
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        }
      );
    });
  }
}

const withToken = (token: string) =>
  setContext(() => {
    if (token) {
      return {
        headers: {
          authorization: `Bearer ${token}`,
        },
        token: `Bearer ${token}`,
      };
    }
    return {};
  });

const errorLink = (logger: Logger, onAuthError: () => void) =>
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, path, extensions }: GraphQLError) => {
        if (
          !extensions ||
          !extensions.code ||
          extensions.code === 'INTERNAL_SERVER_ERROR'
        ) {
          logger.error(
            `[Unexpected Server Error] Message: ${JSON.stringify(message)}`
          );
        } else {
          // TODO: handle authentication errors accordingly when BE refactor is done

          if (
            extensions.code === 'AuthorizationError' ||
            extensions.code === 'AuthenticationError' ||
            message.includes('unauthorized')
          ) {
            (
              graphQLErrors as ReadonlyArray<GraphQLError> & {
                login?: boolean;
              }
            ).login = true;
            onAuthError();
          }
          logger.warn(
            `[GraphQL error]: Message: ${JSON.stringify(
              message
            )}, Path: ${JSON.stringify(path)}`
          );
        }
      });
    }

    if (networkError) {
      logger.error(`[Network error]:`, networkError);

      if ('response' in networkError) {
        if (networkError.response.status === 401) {
          // @ts-expect-error
          networkError.login = true;
          onAuthError();
        }
      }
    }
  });

const connectionLink = ({
  token,
  apiUrl,
  subscriptionsUrl,
}: {
  token: string;
  apiUrl: string;
  subscriptionsUrl: string;
}) => {
  const websocketClient = createWsClient({
    url: subscriptionsUrl,
    keepAlive: 30000, // Ping the server every 30 seconds to keep the connection alive
    connectionParams: () => {
      if (token) {
        return {
          Authorization: `Bearer ${token}`,
        };
      }

      /**
       * Immediately close the WebSockets connection if there is no token.
       * This lets the app always have an authenticated WebSockets connection.
       */
      websocketClient.terminate();
    },
  });
  const wsLink = new GraphQLWsLink(websocketClient);

  return split(
    // split based on operation type
    ({ query }) => {
      const mainDefinition = getMainDefinition(query);
      return (
        mainDefinition.kind === 'OperationDefinition' &&
        mainDefinition.operation === 'subscription'
      );
    },
    wsLink,
    (operation, forward) => {
      return new HttpLink({
        uri: operation.getContext().apiUrl ?? apiUrl,
      }).request(operation, forward);
    }
  );
};

const captureOperationDuration = new ApolloLink((operation, forward) => {
  // this link event measures round trip operation in milliseconds
  operation.setContext({ startOp: performance.now() });

  return forward(operation).map(data => {
    // capture elapsed time in milliseconds
    const duration = performance.now() - operation.getContext().startOp;

    window.newrelic?.addPageAction?.('directory-webapp-graphql-executed', {
      operation: operation.operationName,
      duration,
      regional: true,
    });

    return data;
  });
});

const apolloCache = new InMemoryCache({
  possibleTypes: getPossibleTypes(),
  typePolicies: {
    Viewer: {
      fields: {},
    },
    UserViewer: {
      fields: {},
    },
    Project: {
      fields: {
        meta: {
          read() {
            return {};
          },
        },
      },
    },
    User: {
      fields: {
        createdAt: {
          read() {
            return new Date();
          },
        },
      },
    },
  },
  dataIdFromObject: object => {
    if (
      [
        'StringFieldValidations',
        'IntFieldValidations',
        'FloatFieldValidations',
      ].includes(object.__typename || '') ||
      // Disable cache normalization of union field. We query for models related to the union here and doing so causes apollo to
      // trigger the refetching of extendedModelQuery of any model which is already present in the cache. This refetching is
      // wasteful and sometimes leads to unexpected ui behavior like automatic closing of relation picker when we open it for the first time.
      object.__typename === 'Union'
    )
      return undefined;
    return defaultDataIdFromObject(object);
  },
});

export const createClient = ({
  logger = console,
  token,
  onAuthError,
  apiUrl,
  subscriptionsUrl,
}: {
  logger?: Logger;
  token: string;
  apiUrl: string;
  subscriptionsUrl: string;
  onAuthError: () => void;
}) =>
  new ApolloClient({
    cache: apolloCache,
    link: from([
      errorLink(logger, onAuthError),
      withToken(token),
      captureOperationDuration,
      connectionLink({ token, apiUrl, subscriptionsUrl }),
    ]),
    // typeDefs,

    name: 'directory-app',
    version: getRefShaCommit(),
  });

function getPossibleTypes() {
  const possibleTypes = {};

  schema.__schema.types.forEach(supertype => {
    if (supertype.possibleTypes) {
      possibleTypes[supertype.name] = supertype.possibleTypes.map(
        subtype => subtype.name
      );
    }
  });

  return possibleTypes;
}
