import {
  Dialog,
  DialogContent,
  DialogFooter,
  Button,
  DialogHeader,
  Stack,
  Label,
  Heading,
  Text,
  Input,
  Alert,
} from '@hygraph/baukasten';
import { GField } from 'components/GField';

import { WithModalProps } from 'components/Modal/ModalContext';
import { App } from 'generated/graphql';
import { trans } from 'i18n';
import { Form } from 'react-final-form';
import { validateWithYup } from 'utils';
import { object, string } from '@graphcms/validation';
import { useDeleteApp } from '../hooks/useDeleteApp';
import { noop } from 'utils';
import { DialogAppPreview } from './DialogAppPreview';

type FormValue = {
  captcha: string;
};

export const DeleteAppModal = (
  props: WithModalProps<{
    app: Pick<App, 'apiId' | 'name' | 'avatarUrl' | 'description'>;
    onDelete?: () => void;
  }>
) => {
  const { onRequestClose, app, onDelete } = props;
  const { deleteApp } = useDeleteApp();
  const schema = object({
    captcha: string()
      .required(trans('Field is required'))
      .matches(new RegExp(app.name), {
        message: trans('doesn\'t match "{{target}}"', { target: app.name }),
      }),
  });

  return (
    <Form<FormValue>
      onSubmit={() => {
        return deleteApp({ apiId: app.apiId })
          .then(onRequestClose)
          .then(onDelete);
      }}
      initialValues={{ captcha: '' }}
      validate={validateWithYup(schema)}
      render={({ handleSubmit, submitting }) => {
        return (
          <Dialog
            aria-label="Delete app URL modal"
            onDismiss={submitting ? noop : onRequestClose}
            isOpen
          >
            <DialogHeader>
              <Heading as="h4" styleAs="h4" fontWeight={500}>
                {trans('Delete {{appName}}', { appName: app.name })}
              </Heading>
            </DialogHeader>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Stack gap="28">
                  <Stack gap="8">
                    <Label fontSize="copy" lineHeight="sm">
                      {trans('Are you sure you want to delete this app?')}
                    </Label>

                    <DialogAppPreview app={app} />
                  </Stack>

                  <Stack gap="8">
                    <Text fontSize="copy">
                      {trans(
                        'To confirm, please type the name of the app below'
                      )}
                    </Text>
                    <GField
                      name="captcha"
                      render={({ input, hasError }) => (
                        <Input
                          data-testid="appNameCaptcha"
                          placeholder={app.name}
                          validationStatus={hasError ? 'invalid' : 'initial'}
                          {...input}
                        />
                      )}
                    />
                  </Stack>
                  <Alert variantColor="warning" showStatusIcon>
                    {/* TODO: add app.projectCounts */}
                    {trans(
                      'Deleting this app will impact {{projectCount}} projects that are currently using it. This action cannot be undone.',
                      { projectCount: '' }
                    )}
                  </Alert>
                </Stack>
              </DialogContent>
              <DialogFooter>
                <Button
                  size="large"
                  variant="ghost"
                  onClick={onRequestClose}
                  disabled={submitting}
                >
                  {trans('Cancel')}
                </Button>
                <Button
                  size="large"
                  type="submit"
                  variantColor="danger"
                  loading={submitting}
                  data-testid="DeleteAppModalButton"
                >
                  {trans('Delete app')}
                </Button>
              </DialogFooter>
            </form>
          </Dialog>
        );
      }}
    />
  );
};
