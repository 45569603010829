import * as React from 'react';
import { Box } from '@hygraph/baukasten';
import isEmpty from 'lodash/isEmpty';
import { Controlled } from 'react-codemirror2';
import CodeMirror from 'codemirror';
import { JSONLintHelper } from './JSONLintHelper';

import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/addon/lint/lint';
import 'codemirror/addon/lint/json-lint';
import 'codemirror/addon/lint/lint.css';

CodeMirror.registerHelper('lint', 'json', JSONLintHelper);

export function parseJSON(json: string): null | Record<string, unknown> {
  if (json === '') {
    return null;
  }

  return JSON.parse(json) as Record<string, unknown>;
}

const getCodeMirrorOptions = (lint = true) => ({
  mode: 'application/json',
  lineNumbers: true,
  gutters: ['CodeMirror-lint-markers'],
  lineWrapping: true,
  lint,
});

export function JsonInput({ value, onChange }) {
  const formattedValue = isEmpty(value) ? '' : JSON.stringify(value, null, 2);
  const [tempValue, setTempValue] = React.useState(formattedValue);

  return (
    <Box
      sx={{
        '& .CodeMirror': {
          height: 'auto',
          minHeight: '200px',
          border: 'input',
          borderColor: 'neutral.150',
          borderRadius: 'input',
          boxShadow: 'input',
          pre: {
            zIndex: 'initial',
          },
        },
        '& .CodeMirror-scroll': {
          minHeight: '198px',
        },
        '& .CodeMirror-focused': {
          borderColor: 'brand.500',
        },
        '& .CodeMirror-selected': {
          opacity: 0.4,
        },
        '& .CodeMirror-gutters': {
          zIndex: 0,
        },
      }}
    >
      <Controlled
        options={getCodeMirrorOptions(true)}
        onBeforeChange={(editor, data, val) => {
          setTempValue(val);
          try {
            if (val.trim().length == 0) {
              onChange(null);
            } else {
              onChange(parseJSON(val));
            }
          } catch (e) {
            console.warn(e);
          }
        }}
        value={tempValue}
        onChange={() => {}} // eslint-disable-line
      />
    </Box>
  );
}
