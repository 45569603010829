import * as React from 'react';
import {
  Alert,
  Box,
  Button,
  Combobox,
  Divider,
  Flex,
  Heading,
  Hint,
  IconButton,
  Inline,
  Input,
  InputAddonBefore,
  InputGroup,
  Label,
  MultiSelect,
  Option,
  PropsOf,
  Select,
  Stack,
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  TextArea,
  Tooltip,
  useToast,
} from '@hygraph/baukasten';
import arrayMutators from 'final-form-arrays';
import {
  Field,
  FieldInputProps,
  FieldRenderProps,
  Form,
} from 'react-final-form';
import * as yup from '@graphcms/validation';
import { appValidation } from '@graphcms/validation';
import { formatUUID, validateWithYup, isUUID } from 'utils';
import { trans } from 'i18n';
import { GField } from 'components/GField';
import { ApolloInlineError } from 'components/Error/ApolloErrorDisplay/ApolloInlineError';
import { FORM_ERROR } from 'final-form';
import { FieldArray } from 'react-final-form-arrays';
import { omit } from 'lodash';
import {
  Add,
  CaretDownFill,
  ChevronLeft,
  DeleteFilled,
  Info,
  Warning,
} from '@hygraph/icons';
import uuid from 'uuid';
import { useCreateApp } from '../hooks/useCreateApp';
import {
  AppAccessType,
  AppContentPermission,
  AppElementInput,
  AppElementType,
  AppSchemaPermission,
  AppWebhooksPermission,
  CreateAppInput,
  FieldAppElementFeature,
  SimpleFieldType,
  UpdateAppInput,
} from 'generated/graphql';
import { useUpdateApp } from '../hooks/useUpdateApp';
import { JsonInput } from 'utils/JsonInput';
import { yupToFormErrors } from 'utils/validation';
import { useNavigate } from 'react-router-dom-v5-compat';
import {
  AppElement,
  defaultAppPermissions,
  getAppInstallUrl,
  getPossiblePermissionsForScope,
  PermissionScopeLabel,
  PermissionTypeLabel,
} from '../helpers';
import {
  CopyTextInput,
  LearnMoreExternalLink,
  SimpleConfirmDialog,
} from 'lib/presentation';
import { Link } from 'react-router-dom';
import { useModal } from 'components/Modal/useModal';
import { DeleteAppModal } from './DeleteAppModal';
import { AppAvatar } from './AppAvatar';

import { PRODUCT_DOMAIN } from 'env';
import { AppForOwnerFragment } from 'modules/apps/gql/generated/AppForOwnerFragment';
import { useUpdateAppSecrets } from 'modules/apps/hooks/useUpdateAppSecrets';
import { AppSecretsModal } from './AppSecretsModal';
import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from 'lib/presentation/Table';

const validationSchema = yup.object({
  name: appValidation.name.required(),
  description: appValidation.description.required(),
  apiId: appValidation.apiId.required(),
  avatarUrl: appValidation.avatarUrl.required(),
  setupUrl: appValidation.setupUrl.required(),
  webhookUrl: appValidation.webhookUrl,
  configurationUrl: appValidation.configurationUrl,
  allowedProjects: yup
    .array()
    .of(
      yup
        .string()
        .test('uuid', trans('Project ID must be a valid UUID'), value =>
          isUUID(value)
        )
    )
    .nullable(true),
  allowedUsers: yup.array().of(yup.string().email()).nullable(true),
  elements: yup.array(
    // @ts-expect-error
    yup.lazy((element: AppElementInput) => {
      const common = yup.object({
        id: yup.string().required(),
        name: appValidation.element.name.required(),
        type: appValidation.element.type.required(),
        src: appValidation.element.src.required(),
        apiId: appValidation.apiId.required(),
        description: appValidation.element.description,
      });

      if (element.type === AppElementType.field) {
        return common.concat(
          yup.object({
            fieldType: appValidation.element.fieldType.required(),
            features: appValidation.element.features.required(),
          })
        );
      }

      return common;
    })
  ),
  permissions: yup.object({
    CONTENT: yup.string().oneOf(Object.keys(AppContentPermission)),
    SCHEMA: yup.string().oneOf(Object.keys(AppSchemaPermission)),
    WEBHOOKS: yup.string().oneOf(Object.keys(AppWebhooksPermission)),
  }),
  accessType: yup.string().oneOf(Object.keys(AppAccessType)),
});

const ObjectKeys = <Obj extends Record<string, unknown>>(obj: Obj) => {
  return Object.keys(obj) as (keyof Obj)[];
};

export function AppForm({ app }: { app?: AppForOwnerFragment }) {
  const [createApp] = useCreateApp();
  const [updateApp] = useUpdateApp();
  const [updateAppSecrets] = useUpdateAppSecrets();
  const openToast = useToast();
  const { showModal } = useModal();
  const navigate = useNavigate();
  const [currentAllowedEmail, setCurrentAllowedEmail] = useState('');
  const [currentAllowedProject, setCurrentAllowedProject] = useState('');

  const isEditing = !!app;

  const appHasPermission = (permissions: AppForOwnerFragment['permissions']) =>
    Object.values(omit(permissions, '__typename')).some(
      value => value !== AppSchemaPermission.NONE
    );

  const initialElements = app?.elements?.map<AppElementInput>(appElement => ({
    apiId: appElement.apiId,
    id: appElement.id,
    name: appElement.name,
    src: appElement.src,
    type: appElement.type,
    config: appElement.config,
    description: appElement.description,
    features: AppElement.isFieldAppElement(appElement)
      ? appElement.features
      : undefined,
    fieldType: AppElement.isFieldAppElement(appElement)
      ? appElement.fieldType
      : undefined,
  }));

  const allowedProjects = isEditing ? app?.allowedProjects?.map(p => p.id) : [];

  const renderAllowedUsers = ({
    input: accessTypeInput,
  }: FieldRenderProps<AppAccessType>) => {
    const isNotValidEmail = (val: string) => {
      return !yup.string().email().isValidSync(val);
    };

    const addToUserList = (input: FieldInputProps<any, HTMLElement>) => {
      if (currentAllowedEmail === '') {
        return;
      }

      const allowedUsers = new Set(input.value);
      currentAllowedEmail
        .split(',')
        .forEach(email => allowedUsers.add(email.trim()));
      input.onChange(Array.from(allowedUsers.values()));
      setCurrentAllowedEmail('');
    };

    if (accessTypeInput.value === AppAccessType.SHARED) {
      return null;
    }

    return (
      <GField
        name="allowedUsers"
        label={'Allowed Users'}
        render={({ input }) => {
          return (
            <Stack gap="12">
              <Inline width="100%" alignItems="center" gap="8" flexWrap="wrap">
                <Box flex={1}>
                  <Input
                    name="allowedUsers"
                    placeholder="Enter one or more email addresses, separated by commas"
                    value={currentAllowedEmail}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        addToUserList(input);
                      }
                    }}
                    onChange={e => {
                      setCurrentAllowedEmail(e.currentTarget.value);
                    }}
                  />
                </Box>
                <Button
                  iconBefore={Add}
                  onClick={() => addToUserList(input)}
                  size="large"
                  variant="outline"
                  variantColor="primary"
                  data-testid="AddUsersToListButton"
                >
                  {trans('Add to list')}
                </Button>
              </Inline>
              <Inline>
                {input.value.length > 0 && (
                  <Table>
                    <TableHead>
                      <TableHeaderCell
                        borderTopLeftRadius="small"
                        bg="neutral.100"
                        borderTopRightRadius="small"
                      >
                        <Flex
                          pr="s"
                          border="none"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          {trans('Members')}
                        </Flex>
                      </TableHeaderCell>
                    </TableHead>
                    <TableBody>
                      {input.value.map(email => {
                        return (
                          <Flex
                            flexDirection="row"
                            as={TableRow}
                            key={email}
                            px={12}
                            py="s"
                          >
                            <Flex
                              flex={1}
                              {...(isNotValidEmail(email)
                                ? { color: 'red.700' }
                                : {})}
                              alignItems="center"
                              as={TableCell}
                              flexWrap={'nowrap'}
                              gap="4"
                            >
                              {isNotValidEmail(email) && (
                                <Box as={Info} size="icon.16" color="red.700" />
                              )}
                              {email}
                            </Flex>
                            <IconButton
                              variant="ghost"
                              {...(isNotValidEmail(email)
                                ? { variantColor: 'danger' }
                                : {})}
                              icon={DeleteFilled}
                              onClick={() => {
                                const allowedUsers = new Set<string>(
                                  input.value
                                );
                                allowedUsers.delete(email);
                                input.onChange(
                                  Array.from(allowedUsers.values())
                                );
                              }}
                            />
                          </Flex>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </Inline>
            </Stack>
          );
        }}
      />
    );
  };

  const renderAllowedProjects = ({
    input: accessTypeInput,
  }: FieldRenderProps<AppAccessType>) => {
    const isNotValidUUID = (val: string) => {
      return !isUUID(val);
    };

    const addProjectToList = (
      fieldInput: FieldInputProps<any, HTMLElement>
    ) => {
      if (currentAllowedProject === '') {
        return;
      }

      const allowedProject = new Set(fieldInput.value);

      currentAllowedProject.split(',').forEach(id => {
        const dashedUUID = formatUUID(id.trim());
        allowedProject.add(dashedUUID);
      });
      fieldInput.onChange(Array.from(allowedProject.values()));
      setCurrentAllowedProject('');
    };

    if (accessTypeInput.value === AppAccessType.SHARED) {
      return null;
    }

    return (
      <GField
        name="allowedProjects"
        label={trans('Allowed Projects')}
        render={({ input }) => {
          return (
            <Stack gap="12">
              <Inline width="100%" alignItems="center" gap="8" flexWrap="wrap">
                <Box flex={1}>
                  <Input
                    placeholder={trans(
                      'Enter one or more project IDs, separated by commas'
                    )}
                    value={currentAllowedProject}
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        addProjectToList(input);
                      }
                    }}
                    onChange={e => {
                      setCurrentAllowedProject(e.currentTarget.value);
                    }}
                    name="allowedProjects"
                  />
                </Box>
                <Button
                  iconBefore={Add}
                  onClick={() => addProjectToList(input)}
                  size="large"
                  variant="outline"
                  variantColor="primary"
                  data-testid="AddProjectsToListButton"
                >
                  {trans('Add to list')}
                </Button>
              </Inline>
              <Inline>
                {input.value.length > 0 && (
                  <Table>
                    <TableHead>
                      <TableHeaderCell
                        borderTopLeftRadius="small"
                        bg="neutral.100"
                        borderTopRightRadius="small"
                      >
                        <Flex
                          pr="s"
                          border="none"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          {trans('Projects')}
                        </Flex>
                      </TableHeaderCell>
                    </TableHead>
                    <TableBody>
                      {input.value.map(projectId => {
                        return (
                          <Flex
                            flexDirection="row"
                            as={TableRow}
                            key={projectId}
                            px={12}
                            py="s"
                          >
                            <Flex
                              flex={1}
                              {...(isNotValidUUID(projectId)
                                ? { color: 'red.700' }
                                : {})}
                              alignItems="center"
                              as={TableCell}
                              flexWrap={'nowrap'}
                              gap="4"
                            >
                              {isNotValidUUID(projectId) && (
                                <Box as={Info} size="icon.16" color="red.700" />
                              )}
                              <pre>{projectId}</pre>
                            </Flex>
                            <IconButton
                              variant="ghost"
                              {...(isNotValidUUID(projectId)
                                ? { variantColor: 'danger' }
                                : {})}
                              icon={DeleteFilled}
                              onClick={() => {
                                const allowedProjects = new Set<string>(
                                  input.value
                                );
                                allowedProjects.delete(projectId);
                                input.onChange(
                                  Array.from(allowedProjects.values())
                                );
                              }}
                            />
                          </Flex>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </Inline>
            </Stack>
          );
        }}
      />
    );
  };

  return (
    <Form<CreateAppInput | UpdateAppInput>
      validate={validateWithYup(validationSchema)}
      onSubmit={values => {
        if (app) {
          return updateApp({
            ...values,
            apiId: app.apiId,
          })
            .then(() => {
              openToast({
                title: trans('App updated successfully'),
                variantColor: 'success',
              });
            })
            .catch(error => {
              return { [FORM_ERROR]: error };
            });
        }

        return createApp(values as CreateAppInput)
          .then(data => {
            if (!data) return;

            if (appHasPermission(data.permissions)) {
              showModal(AppSecretsModal, {
                clientId: data.clientId,
                clientSecret: data.clientSecret,
              });
            }

            openToast({
              title: trans('App created successfully'),
              variantColor: 'success',
            });

            navigate(`/apps/${data.apiId}`);
          })
          .catch(error => {
            return { [FORM_ERROR]: error };
          });
      }}
      mutators={{ ...arrayMutators }}
      subscription={{
        submitError: true,
        submitting: true,
        errors: true,
        submitErrors: true,
        submitFailed: true,
      }}
      initialValues={
        app
          ? {
              ...omit(app, ['__typename', 'id', 'createdAt', 'updatedAt']),
              elements: initialElements,
              permissions: omit(app.permissions, '__typename'),
              allowedProjects,
            }
          : {
              permissions: defaultAppPermissions,
              accessType: AppAccessType.SHARED,
              allowedUsers: [],
            }
      }
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, submitting, submitError, errors, submitFailed }) => {
        const generalHasError =
          ['name', 'description', 'apiId', 'avatarUrl', 'setupUrl'].find(
            fieldName => errors?.[fieldName]
          ) && submitFailed;
        const advancedHasError = errors?.['elements'] && submitFailed;

        return (
          <Stack gap="48" as="form" width="100%" onSubmit={handleSubmit}>
            <Stack gap="28">
              <Button<PropsOf<Link>>
                mr="auto"
                variant="link"
                variantColor="secondary"
                as={Link}
                to="/apps"
                iconBefore={ChevronLeft}
              >
                {trans('Back to apps list')}
              </Button>
              <Inline>
                {/* @ts-expect-error */}
                <AppAvatar src={app?.avatarUrl || '#'} size="60" />
                <Stack gap="4">
                  <Text
                    fontWeight={700}
                    fontSize="h2"
                    as="h2"
                    lineHeight="32px"
                  >
                    {app?.name ?? trans('App name')}
                  </Text>
                  <Text fontSize="interface" color="neutral.500">
                    {trans('For more information, check out the documentation')}{' '}
                    <LearnMoreExternalLink
                      href={`https://${PRODUCT_DOMAIN}/docs/app-framework/register-an-app`}
                    >
                      {trans('here')}
                    </LearnMoreExternalLink>
                  </Text>
                </Stack>
                <Button
                  ml="auto"
                  type="submit"
                  size="medium"
                  loading={submitting}
                >
                  {isEditing ? trans('Save changes') : trans('Register app')}
                </Button>
              </Inline>
            </Stack>
            <Stack gap="24">
              {submitError && (
                <ApolloInlineError error={submitError}>
                  {(err, key) => (
                    <Alert variantColor="error" key={key}>
                      {err.message || err}
                    </Alert>
                  )}
                </ApolloInlineError>
              )}
              <Tabs gap="32" as={Stack}>
                <Inline borderBottom="1px solid" borderColor="neutral.150">
                  <Tab variant="underline" gap="4" as={Inline}>
                    {trans('General')}
                    {generalHasError && (
                      <Box as={Warning} size="icon.16" color="red.500" />
                    )}
                  </Tab>
                  <Tab variant="underline">{trans('Permissions')}</Tab>
                  <Tab variant="underline" gap="4" as={Inline}>
                    {trans('Elements')}
                    {advancedHasError && (
                      <Box as={Warning} size="icon.16" color="red.500" />
                    )}
                  </Tab>
                  <Tab variant="underline">{trans('Sharing')}</Tab>
                </Inline>
                <TabPanels paddingY="0">
                  <TabPanel>
                    <Stack gap="24">
                      <GField
                        name="name"
                        label={trans('Name')}
                        hint={trans('Enter the name of your app')}
                        render={({ input, hasError }) => (
                          <Input
                            {...input}
                            validationStatus={hasError ? 'invalid' : 'initial'}
                            autoFocus
                          />
                        )}
                      />
                      <GField
                        name="description"
                        label={trans('Description')}
                        hint={trans(
                          'Enter the description for what your App does'
                        )}
                        render={({ input, hasError }) => (
                          <TextArea
                            autoSize
                            {...input}
                            validationStatus={hasError ? 'invalid' : 'initial'}
                          />
                        )}
                      />
                      <GField
                        name="avatarUrl"
                        label={trans('Avatar URL')}
                        hint={trans(
                          'Enter the URL that indicates where your logo is hosted'
                        )}
                        render={({ input, hasError }) => (
                          <Input
                            {...input}
                            inputMode="url"
                            validationStatus={hasError ? 'invalid' : 'initial'}
                          />
                        )}
                      />
                      <GField
                        name="apiId"
                        label={trans('API ID')}
                        hint={trans(
                          'API ID is used to construct installation URL'
                        )}
                        render={({ input, hasError }) => (
                          <InputGroup>
                            <InputAddonBefore
                              color="neutral.500"
                              fontSize="interface"
                            >
                              {window.location.origin}/apps/
                            </InputAddonBefore>
                            <Input
                              {...input}
                              readOnly={isEditing}
                              validationStatus={
                                hasError ? 'invalid' : 'initial'
                              }
                            />
                          </InputGroup>
                        )}
                      />
                      <GField
                        name="setupUrl"
                        label={trans('Setup URL')}
                        hint={trans(
                          'Enter the URL for the form you want to display when a user installs this app for the first time in Hygraph.'
                        )}
                        render={({ input, hasError }) => (
                          <Input
                            {...input}
                            inputMode="url"
                            validationStatus={hasError ? 'invalid' : 'initial'}
                          />
                        )}
                      />
                    </Stack>
                  </TabPanel>

                  <TabPanel>
                    <Stack gap="32">
                      <Alert variantColor="warning" showStatusIcon>
                        {trans(
                          "Note: You can't change API Permissions once the app is added."
                        )}
                      </Alert>
                      <Stack gap="8">
                        <Label textTransform="uppercase">
                          {trans('API permissions')}
                        </Label>
                        <Stack
                          border="1px solid"
                          borderColor="neutral.100"
                          borderRadius="small"
                          padding="16"
                          alignItems="flex-start"
                        >
                          {ObjectKeys(PermissionScopeLabel).map(scope => {
                            return (
                              <GField
                                key={scope}
                                name={`permissions.${scope}`}
                                label={PermissionScopeLabel[scope]}
                                render={({ input }) => {
                                  return (
                                    <Select
                                      as={Button}
                                      variant="outline"
                                      iconAfter={CaretDownFill}
                                      fontWeight={500}
                                      data-testid={scope}
                                      disabled={isEditing}
                                      defaultValue={input.value}
                                      onValueChange={input.onChange}
                                    >
                                      {getPossiblePermissionsForScope(
                                        scope
                                      ).map(type => (
                                        <Option
                                          value={type}
                                          key={type}
                                          disabled={isEditing}
                                          data-testid={`${scope}.${type}`}
                                        >
                                          {PermissionTypeLabel[type]}
                                        </Option>
                                      ))}
                                    </Select>
                                  );
                                }}
                              />
                            );
                          })}
                        </Stack>
                      </Stack>

                      <Stack gap="8">
                        <Label textTransform="uppercase">
                          {trans('Installation permissions')}
                        </Label>
                        <Stack
                          border="1px solid"
                          borderColor="neutral.100"
                          borderRadius="small"
                          padding="16"
                        >
                          <GField
                            name="accessType"
                            label={trans('App Access')}
                            alignSelf="flex-start"
                            render={({ input }) => {
                              return (
                                <Select
                                  as={Button}
                                  variant="outline"
                                  iconAfter={CaretDownFill}
                                  fontWeight={500}
                                  data-testid="APP_ACCESS"
                                  defaultValue={input.value}
                                  onValueChange={input.onChange}
                                >
                                  <Option
                                    value={AppAccessType.SHARED}
                                    data-testid={`APP_ACCESS.${AppAccessType.SHARED}`}
                                  >
                                    {trans('Shared access')}
                                  </Option>
                                  <Option
                                    value={AppAccessType.PRIVATE}
                                    data-testid={`APP_ACCESS.${AppAccessType.PRIVATE}`}
                                  >
                                    {trans('Private access')}
                                  </Option>
                                </Select>
                              );
                            }}
                          />

                          <Field
                            name="accessType"
                            render={renderAllowedUsers}
                          />

                          <Field
                            name="accessType"
                            render={renderAllowedProjects}
                          />
                        </Stack>
                      </Stack>

                      {isEditing && appHasPermission(app.permissions) && (
                        <Stack>
                          <Button
                            mr="auto"
                            type="button"
                            variantColor="primary"
                            size="large"
                            onClick={() => {
                              showModal(SimpleConfirmDialog, {
                                title: trans('Regenerate App secrets'),
                                confirmButtonColor: 'primary',
                                confirmButtonText: trans(
                                  'Regenerate App secrets'
                                ),
                                bodyText: trans(
                                  'Regenerating app secrets will invalidate previous ones. Are you sure?'
                                ),
                                onConfirm: () => {
                                  return updateAppSecrets({
                                    apiId: app.apiId,
                                  }).then(data => {
                                    if (!data) return;

                                    showModal(AppSecretsModal, {
                                      clientId: data.clientId,
                                      clientSecret: data.clientSecret,
                                    });
                                  });
                                },
                              });
                            }}
                          >
                            {trans('Regenerate App secrets')}
                          </Button>
                        </Stack>
                      )}
                    </Stack>
                  </TabPanel>

                  <TabPanel>
                    <Stack gap="12">
                      <Stack gap="4">
                        <Label>
                          {trans('Elements')}
                          <>
                            &nbsp;
                            <Text color="neutral.400">
                              {trans('(optional)')}
                            </Text>
                          </>
                        </Label>
                        <Hint>
                          {trans(
                            'Elements are custom user interface that can be used by users.'
                          )}
                        </Hint>
                      </Stack>

                      <FieldArray name="elements">
                        {({ fields }) => {
                          return (
                            <Stack gap="16">
                              {fields.map((field, index) => {
                                const value = fields.value[index];

                                const isEditingElement = Boolean(
                                  app?.elements?.find(el => el.id === value.id)
                                );
                                return (
                                  <Stack
                                    border="1px solid"
                                    borderColor="neutral.100"
                                    borderRadius="small"
                                    position="relative"
                                    key={value.id}
                                  >
                                    <Flex
                                      backgroundColor="neutral.150"
                                      padding="16"
                                      borderRadius="small"
                                      borderBottomRightRadius="0"
                                      borderBottomLeftRadius="0"
                                      borderBottom="1px solid"
                                      borderColor="neutral.100"
                                    >
                                      <Label as="span">
                                        {trans('Element')} {index + 1}
                                      </Label>
                                      <IconButton
                                        ml="auto"
                                        variant="ghost"
                                        size="small"
                                        iconSize={'icon.16'}
                                        onClick={() => fields.remove(index)}
                                        icon={DeleteFilled}
                                      />
                                    </Flex>
                                    <Stack gap="16" padding="m" paddingTop="0">
                                      <GField
                                        name={`${field}.name`}
                                        label={trans('Name')}
                                        render={({ input, hasError }) => (
                                          <Input
                                            {...input}
                                            validationStatus={
                                              hasError ? 'invalid' : 'initial'
                                            }
                                          />
                                        )}
                                      />
                                      <GField
                                        name={`${field}.apiId`}
                                        label={trans('API ID')}
                                        render={({ input, hasError }) => (
                                          <Input
                                            {...input}
                                            validationStatus={
                                              hasError ? 'invalid' : 'initial'
                                            }
                                          />
                                        )}
                                      />
                                      <GField
                                        name={`${field}.type`}
                                        label={trans('Type')}
                                        render={({ input, hasError }) => (
                                          <Combobox
                                            {...input}
                                            selectedItem={input.value}
                                            items={Object.values(
                                              AppElementType
                                            )}
                                            hasError={hasError}
                                            disabled={isEditingElement}
                                          />
                                        )}
                                      />

                                      <Field
                                        name={`${field}.type`}
                                        render={({ input }) => {
                                          if (
                                            input.value !== AppElementType.field
                                          )
                                            return null;
                                          return (
                                            <Stack>
                                              <GField
                                                name={`${field}.features`}
                                                label={trans('Features')}
                                                render={({ input }) => (
                                                  <MultiSelect
                                                    {...input}
                                                    items={Object.values(
                                                      FieldAppElementFeature
                                                    )}
                                                  />
                                                )}
                                              />
                                              <GField
                                                name={`${field}.fieldType`}
                                                label={trans('Field type')}
                                                render={({ input }) => (
                                                  <Combobox
                                                    {...input}
                                                    selectedItem={input.value}
                                                    items={Object.values(
                                                      SimpleFieldType
                                                    )}
                                                    disabled={isEditingElement}
                                                  />
                                                )}
                                              />
                                            </Stack>
                                          );
                                        }}
                                      />

                                      <GField
                                        name={`${field}.src`}
                                        label={trans('URL')}
                                        render={({ input, hasError }) => (
                                          <Input
                                            {...input}
                                            inputMode="url"
                                            validationStatus={
                                              hasError ? 'invalid' : 'initial'
                                            }
                                          />
                                        )}
                                      />
                                      <GField
                                        name={`${field}.description`}
                                        label={trans('Description')}
                                        render={({ input, hasError }) => (
                                          <Input
                                            {...input}
                                            validationStatus={
                                              hasError ? 'invalid' : 'initial'
                                            }
                                          />
                                        )}
                                      />
                                      <GField
                                        name={`${field}.config`}
                                        label={trans('Config')}
                                        validate={value => {
                                          try {
                                            appValidation.element.config.validateSync(
                                              value
                                            );
                                          } catch (e) {
                                            const errors: string[] = [];

                                            JSON.stringify(
                                              yupToFormErrors(e),
                                              (_, value) => {
                                                if (typeof value === 'string')
                                                  errors.push(value);
                                                return value;
                                              }
                                            );

                                            return errors.join(', ');
                                          }
                                        }}
                                        render={({ input }) => {
                                          return (
                                            <JsonInput
                                              value={input.value}
                                              onChange={input.onChange}
                                            />
                                          );
                                        }}
                                      />
                                    </Stack>
                                  </Stack>
                                );
                              })}
                              <Button
                                variant="dashed"
                                width="100%"
                                iconBefore={Add}
                                variantColor="primary"
                                size="large"
                                onClick={() => {
                                  fields.push({
                                    id: uuid.v4(),
                                    name: '',
                                    description: '',
                                    type: AppElementType.field,
                                    config: {},
                                  });
                                }}
                              >
                                {trans('Add element')}
                              </Button>
                            </Stack>
                          );
                        }}
                      </FieldArray>
                    </Stack>
                  </TabPanel>

                  <TabPanel>
                    <Stack gap="32">
                      {app?.accessType === AppAccessType.PRIVATE && (
                        <Alert variantColor="warning" showStatusIcon>
                          {trans(
                            'Note: The app installation permissions are set to private. Only you can install this app on your projects.'
                          )}
                        </Alert>
                      )}

                      <GField
                        name="apiId"
                        displayErrors={false}
                        label={trans('App Sharing')}
                        hint={trans('Copy link and share app with other users')}
                        render={({ input }) => (
                          <Stack gap="4">
                            <Tooltip label={trans('Click to copy')}>
                              <CopyTextInput
                                value={getAppInstallUrl(input.value, true)}
                                message={trans('Share URL copied to clipboard')}
                              />
                            </Tooltip>
                            <Inline
                              color={'neutral.500'}
                              alignItems="center"
                              gap="4"
                            >
                              <Box as={Info} size="icon.16" />
                              {trans(
                                'Note: We currently allow only admin & developer roles to install apps on a project'
                              )}
                            </Inline>
                          </Stack>
                        )}
                      />

                      {/* uncomment later, when marketplace is figured out */}
                      {/* <Inline
                                    border="1px solid"
                                    borderColor={'neutral.150'}
                                    padding="16"
                                    gap="16"
                                    alignItems="center"
                                  >
                                    <Avatar size="avatar.80" />
                                    <Stack gap="12">
                                      <Text as="h1">Want to make the app public?</Text>
                                      <Text>
                                        You can now share your app publicly on our Marketplace
                                        for other users to use it in their projects!
                                      </Text>
                                    </Stack>
                                    <LearnMoreExternalLink
                                      width="fit-content"
                                      href="https://graphcms.com"
                                    >
                                      Find out more
                                    </LearnMoreExternalLink>
                                  </Inline> */}
                    </Stack>
                  </TabPanel>
                </TabPanels>
              </Tabs>

              {isEditing && app && (
                <>
                  <Divider m={0} />
                  <Stack gap="24">
                    <Heading styleAs="h4" fontWeight={700}>
                      {trans('Danger zone')}
                    </Heading>
                    <Stack gap="16">
                      <Stack gap="8">
                        <Text
                          fontSize="copy"
                          fontWeight={500}
                          color="neutral.900"
                          lineHeight="sm"
                        >
                          {trans('Delete app')}
                        </Text>
                        <Text
                          fontSize="tiny"
                          color="neutral.500"
                          lineHeight="sm"
                        >
                          {trans(
                            'Deletes the app permanently. This cannot be undone.'
                          )}
                        </Text>
                      </Stack>

                      <Button
                        mr="auto"
                        type="button"
                        variantColor="danger"
                        size="large"
                        iconBefore={DeleteFilled}
                        data-testid="OpenDeleteAppModalButton"
                        onClick={() => {
                          showModal(DeleteAppModal, {
                            app,
                            onDelete: () => {
                              navigate('..');
                            },
                          });
                        }}
                      >
                        {trans('Delete app')}
                      </Button>
                    </Stack>
                  </Stack>
                </>
              )}
            </Stack>
          </Stack>
        );
      }}
    </Form>
  );
}
