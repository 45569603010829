import * as Types from '../../../../generated/graphql';

import { ProjectBasicFragment } from './ProjectBasicFragment';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProjectMutationVariables = Types.Exact<{
  data: Types.CreateProjectInput;
}>;


export type CreateProjectMutation = { __typename: 'Mutation', createProject: { __typename: 'CreateProjectPayload', project: (
      { __typename: 'Project' }
      & ProjectBasicFragment
    ) } };


export const CreateProjectMutationDocument = gql`
    mutation createProjectMutation($data: CreateProjectInput!) {
  createProject(input: $data) {
    project {
      ...ProjectBasicFragment
    }
  }
}
    ${ProjectBasicFragment}`;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectMutationDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;