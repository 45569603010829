import { FunctionComponent } from 'react';
import { ApolloError } from '@apollo/client';
import { GlobalErrorOverlay } from 'components/Error/GlobalErrorOverlay';
import { ApolloErrorContent } from 'components/Error/ApolloErrorDisplay/ApolloErrorContent';

interface Props {
  error: ApolloError;
  resetError: () => void;
}

export const ApolloErrorDisplay: FunctionComponent<Props> = ({
  error,
  resetError,
}) => (
  <GlobalErrorOverlay>
    <ApolloErrorContent error={error} resetError={resetError} />
  </GlobalErrorOverlay>
);
