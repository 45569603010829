import { Card, Heading, Progress, StackOld, Text } from '@hygraph/baukasten';
import { trans } from 'i18n';

interface AccountProfileCompletionProps {
  percentage: number;
  isEditing: boolean;
}

export function AccountProfileCompletion(props: AccountProfileCompletionProps) {
  const { percentage, isEditing } = props;
  return (
    <Card maxWidth="420px" borderRadius="medium" p="l">
      <StackOld>
        <Heading fontWeight="bold" as="h4">
          {trans('Profile completion')}
        </Heading>
        <Text color="neutral.500">
          {trans(
            'Completing your profile will help us tailor your experience. You can update or change these at any time.'
          )}
        </Text>
        <StackOld space="s">
          <Progress
            value={percentage}
            height="8px"
            color={isEditing ? 'green.200' : 'teal.700'}
            roundedCorners
          />
          <Text color="neutral.500">
            {trans(`You've completed {{percentage}}% of your profile.`, {
              percentage,
            })}
          </Text>
        </StackOld>
      </StackOld>
    </Card>
  );
}
