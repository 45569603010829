import * as Types from '../../../../generated/graphql';

import { AppForOwnerFragment } from './AppForOwnerFragment';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ViewerAppForOwnerQueryVariables = Types.Exact<{
  apiId: Types.Scalars['String'];
}>;


export type ViewerAppForOwnerQuery = { __typename: 'Query', viewer: { __typename: 'UserViewer', id: string, app: { __typename: 'App' } | (
      { __typename: 'AppForOwner' }
      & AppForOwnerFragment
    ) | { __typename: 'AppWithSecrets' } } };


export const ViewerAppForOwnerQueryDocument = gql`
    query ViewerAppForOwnerQuery($apiId: String!) {
  viewer {
    id
    ... on UserViewer {
      app(apiId: $apiId) {
        ...AppForOwnerFragment
      }
    }
  }
}
    ${AppForOwnerFragment}`;

/**
 * __useViewerAppForOwnerQuery__
 *
 * To run a query within a React component, call `useViewerAppForOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerAppForOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerAppForOwnerQuery({
 *   variables: {
 *      apiId: // value for 'apiId'
 *   },
 * });
 */
export function useViewerAppForOwnerQuery(baseOptions: Apollo.QueryHookOptions<ViewerAppForOwnerQuery, ViewerAppForOwnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerAppForOwnerQuery, ViewerAppForOwnerQueryVariables>(ViewerAppForOwnerQueryDocument, options);
      }
export function useViewerAppForOwnerQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerAppForOwnerQuery, ViewerAppForOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerAppForOwnerQuery, ViewerAppForOwnerQueryVariables>(ViewerAppForOwnerQueryDocument, options);
        }
export type ViewerAppForOwnerQueryHookResult = ReturnType<typeof useViewerAppForOwnerQuery>;
export type ViewerAppForOwnerQueryLazyQueryHookResult = ReturnType<typeof useViewerAppForOwnerQueryLazyQuery>;