import { ModalConsumer } from './ModalContext';

const ModalRoot = () => (
  <ModalConsumer>
    {({ modals, hideModal }) =>
      modals.map(({ name, component: Component, props }, id) =>
        Component ? (
          <Component key={id} {...props} onRequestClose={hideModal(name)} />
        ) : null
      )
    }
  </ModalConsumer>
);

export default ModalRoot;
