import { useState, useEffect } from 'react';
import { BoundaryError } from 'components/Error';
import { useToast } from '@hygraph/baukasten';
import { trans } from 'i18n';

export enum ErrorCategory {
  // sample, replace with actual categories by which we want to differentiate logged errors
  'CONTENT',
}

interface LogErrorArgs {
  error: BoundaryError;
  reactErrorInfo?: React.ErrorInfo;
  category?: ErrorCategory;
}

export function logError({
  error,
  reactErrorInfo,
  category,
}: LogErrorArgs): void {
  console.error('devLog', error, reactErrorInfo, category);
}

const prefilledMessage = (errorMessage: string) =>
  trans(
    'Hello, I just received this error message:\n{{errorMessage}}\n\nThis is what I just did:\n',
    { errorMessage }
  );

interface UseErrorAlertArgs {
  category?: ErrorCategory;
  errorMessage?: string;
}

export function useErrorAlert({ category, errorMessage }: UseErrorAlertArgs) {
  const [error, setError] = useState<BoundaryError | null>(null);
  const toast = useToast();

  useEffect(() => {
    if (error) {
      logError({ error, category });
      toast({
        description: errorMessage,
        actionBtnTitle: trans('Ask for help'),
        id: `${error.name}-${error.message}`,
        onClose: () => setError(null),
        onClick: () =>
          window.Intercom(
            'showNewMessage',
            prefilledMessage(error.message || '')
          ),
      });
    }
  }, [category, error, errorMessage, toast]);

  return setError;
}
