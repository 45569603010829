import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RegionalProjectQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type RegionalProjectQuery = { __typename: 'Query', viewer: { __typename: 'UserViewer', id: string, project?: { __typename: 'Project', id: string, region: { __typename: 'Region', id: string, name: string } } | null | undefined } };


export const RegionalProjectQueryDocument = gql`
    query regionalProjectQuery($id: ID!) {
  viewer {
    id
    project(id: $id) {
      id
      region {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useRegionalProjectQuery__
 *
 * To run a query within a React component, call `useRegionalProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionalProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionalProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegionalProjectQuery(baseOptions: Apollo.QueryHookOptions<RegionalProjectQuery, RegionalProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegionalProjectQuery, RegionalProjectQueryVariables>(RegionalProjectQueryDocument, options);
      }
export function useRegionalProjectQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegionalProjectQuery, RegionalProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegionalProjectQuery, RegionalProjectQueryVariables>(RegionalProjectQueryDocument, options);
        }
export type RegionalProjectQueryHookResult = ReturnType<typeof useRegionalProjectQuery>;
export type RegionalProjectQueryLazyQueryHookResult = ReturnType<typeof useRegionalProjectQueryLazyQuery>;