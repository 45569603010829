import {
  Button,
  Text,
  Alert,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Heading,
  Stack,
  useToast,
  Input,
  Card,
  Avatar,
  Spinner,
  Box,
  Flex,
} from '@hygraph/baukasten';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import { GField } from 'components/GField';
import { ApolloInlineError } from 'components/Error/ApolloErrorDisplay/ApolloInlineError';
import { trans } from 'i18n';
import { UserFragment } from 'modules/user/gql/generated/UserFragment';
import {
  Events,
  rudderstackTrack,
} from 'modules/tracking/hooks/useTrackRudderstack';
import { useDeleteAccount } from 'modules/user/hooks/useDeleteAccount';
import inRange from 'lodash/inRange';
import { useSendFeedback } from 'modules/projects/hooks/useSendFeedback';
import { useAuthClient } from '@hygraph/client-auth';
import { useOwnedProjects } from '../../modules/projects/hooks/useOwnedProjects';

interface DeleteAccountConfirmationProps {
  user: UserFragment;
  reasons: string[];
  message: string | null;
  onRequestClose: () => void;
}

export function DeleteAccountConfirmation({
  onRequestClose,
  user,
  message,
  reasons,
}: DeleteAccountConfirmationProps) {
  const { loading, error, projectListData } = useOwnedProjects();
  const [deleteAccount] = useDeleteAccount();
  const [sendFeedback] = useSendFeedback();
  const { logout } = useAuthClient();
  const toast = useToast();

  if (error) {
    throw error;
  }

  const ownedProjects = loading ? [] : projectListData.ownedProjects;

  return (
    <Form
      initialValues={{ challenge: null }}
      validate={({ challenge }) => {
        const errors: { [key: string]: string } = {};
        const isValid: boolean = challenge === user.profile.name;
        if (!isValid) {
          errors.challenge = trans('Please type in your full name to confirm');
        }
        return errors;
      }}
      onSubmit={async () => {
        rudderstackTrack(Events.PROFILE_DELETING, {
          profile_id: user.profile.id,
        });

        await sendFeedback({
          data: { message, reasons },
        });

        return deleteAccount()
          .then(async () => {
            toast({
              variantColor: 'success',
              description: trans('Profile deleted successfully'),
            });
            rudderstackTrack(Events.PROFILE_DELETED, {
              profile_id: user.profile.id,
            });

            logout();
          })
          .catch(error => ({
            [FORM_ERROR]: error,
          }));
      }}
    >
      {({ handleSubmit, submitting, submitError, invalid }) => (
        <Dialog isOpen onDismiss={onRequestClose}>
          <Box as="form" onSubmit={handleSubmit}>
            <DialogHeader>
              <Heading as="h4" fontWeight="medium">
                {trans('Delete profile')}
              </Heading>
            </DialogHeader>
            <DialogContent>
              <ApolloInlineError error={submitError}>
                {(err, key) => (
                  <Alert mb="s" key={key}>
                    {err.message || err}
                  </Alert>
                )}
              </ApolloInlineError>

              {loading ? (
                <Flex alignItems="center" justifyContent="center">
                  <Spinner size="spinner.l" />
                </Flex>
              ) : (
                <Stack gap="24">
                  <Stack gap="8">
                    <Alert variantColor="warning" showStatusIcon>
                      {trans('This is extremely important. Please read this.')}
                    </Alert>
                    <Text as="label" fontSize="copy">
                      {/* TRANS component refactor */}
                      {trans('Are you sure you want to')}{' '}
                      <strong>{trans('delete your account?')}</strong>{' '}
                      {trans(
                        'Deleting the account would be permanent and cannot be rolled back.'
                      )}
                    </Text>
                    {ownedProjects.length > 5 && (
                      <Text fontSize="copy">
                        {trans('All your')}{' '}
                        <strong>
                          {trans('{{totalCount}} projects', {
                            totalCount: ownedProjects.length,
                          })}
                        </strong>{' '}
                        {trans('will also be deleted. This cannot be undone.')}
                      </Text>
                    )}
                  </Stack>
                  {inRange(ownedProjects.length, 1, 5) && (
                    <Stack gap="16">
                      <Text fontSize="copy">
                        {trans(
                          "All the projects below that you're an owner will also be deleted."
                        )}
                      </Text>
                      {ownedProjects.map(project => (
                        <Card
                          key={project.id}
                          borderRadius="medium"
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          p="sm"
                        >
                          <Avatar
                            src={project.picture || undefined}
                            size="avatar.40"
                            placeholder={project.name}
                            square
                          />
                          <Box ml="16">
                            <Text lineHeight="small" fontWeight="medium">
                              {project.name}
                            </Text>
                            {project.description && (
                              <Text
                                color="neutral.300"
                                lineHeight="small"
                                fontSize="tiny"
                                fontWeight="medium"
                                pt="6"
                              >
                                {project.description}
                              </Text>
                            )}
                          </Box>
                        </Card>
                      ))}
                    </Stack>
                  )}
                  <GField
                    name="challenge"
                    render={({ input, hasError }) => (
                      <Stack gap="8">
                        <Text>
                          {trans('Please type')}{' '}
                          <strong>{user.profile.name}</strong>{' '}
                          {trans('to confirm')}
                        </Text>
                        <Input
                          {...input}
                          validationStatus={hasError ? 'invalid' : 'initial'}
                          autoComplete="off"
                          placeholder={user.profile.name}
                        />
                      </Stack>
                    )}
                  />
                </Stack>
              )}
            </DialogContent>
            <DialogFooter>
              <Button
                onClick={e => {
                  e.stopPropagation();
                  rudderstackTrack(Events.DELETE_PROFILE_CANCEL, {
                    profile_id: user.profile.id,
                  });
                  onRequestClose();
                }}
                size="large"
                variant="ghost"
                disabled={submitting}
                autoFocus
              >
                {trans('Cancel')}
              </Button>
              <Button
                type="submit"
                size="large"
                variantColor="danger"
                disabled={submitting || invalid || loading}
                loading={submitting}
              >
                {trans('Delete profile')}
              </Button>
            </DialogFooter>
          </Box>
        </Dialog>
      )}
    </Form>
  );
}
