import { Card, Inline, Stack, Text } from '@hygraph/baukasten';
import { App } from 'generated/graphql';
import { AppAvatar } from './AppAvatar';

export const DialogAppPreview = ({
  app,
}: {
  app: Pick<App, 'apiId' | 'name' | 'description' | 'avatarUrl'>;
}) => {
  return (
    <Card
      as={Inline}
      boxShadow="input"
      padding={12}
      gap="8"
      sx={{ ':hover': { boxShadow: 'input' } }}
      borderColor="neutral.150"
    >
      <AppAvatar size="avatar.40" src={app.avatarUrl} />
      <Stack gap="4">
        <Text color="neutral.900" lineHeight="small" fontWeight={500}>
          {app.name}
        </Text>
        <Text
          color="neutral.500"
          lineHeight="small"
          fontWeight={500}
          fontSize="tiny"
        >
          {app.description}
        </Text>
      </Stack>
    </Card>
  );
};
