import { ApolloError } from '@apollo/client';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Flex,
  Heading,
  Combobox,
  Stack,
  Inline,
  Text,
  Input,
  useToast,
  StackOld,
} from '@hygraph/baukasten';
import {
  ChevronLeft as ChevronLeftIcon,
  DeleteFilled as DeleteIcon,
  Image,
  Info,
} from '@hygraph/icons';
import * as Validation from '@graphcms/validation';
import { AccountProfileCompletion } from 'components/Account/AccountProfileCompletion';
import { DeleteAccountSurveyDialog } from 'components/Account/DeleteAccountSurveyDialog';
import { ApolloInlineError } from 'components/Error/ApolloErrorDisplay/ApolloInlineError';
import { GField } from 'components/GField';
import { useModal } from 'components/Modal/useModal';
import { FilestackUploader } from 'modules/assets/components/FilestackUploader';
import ProjectListTopBar from 'components/ProjectList/ProjectListTopBar';
import { updatedDiff } from 'deep-object-diff';
import { PRODUCT_NAME } from 'env';
import { FORM_ERROR } from 'final-form';
import { trans } from 'i18n';
import { PageTitle } from 'lib/presentation/PageTitle';
import capitalize from 'lodash/capitalize';
import { UserFragment } from 'modules/user/gql/generated/UserFragment';
import { useCommonAssetConfig } from 'modules/user/hooks/useCommonAssetConfig';
import { useUpdateProfile } from 'modules/user/hooks/useUpdateProfile';
import { useUser } from 'modules/user/hooks/useUser';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { validateWithYup } from 'utils';
import { object as yupObject, string as yupString } from '@graphcms/validation';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';
import {
  Events,
  rudderstackTrack,
} from 'modules/tracking/hooks/useTrackRudderstack';

const others = ['Other'];

const roles = [
  'Developer',
  'Architect',
  'Product Owner / Manager',
  'Content Operations',
];

function getRoles(value: string) {
  const isOther = !!value && !roles.includes(value);
  const selectedItem = !value ? '' : isOther ? others[0] : value;
  const items = [...roles, ...others].map(role => ({
    label: role,
    value: role,
  }));
  return { selectedItem, items, isOther };
}

const purposes = [
  'Websites & Marketing',
  'Knowledge Management & Portals',
  'eCommerce & Inventory Management',
  'Streaming & Media Platform',
  'Content Unification',
  'Other Application Content',
  'Personal use',
];

function getPurposes(value: string) {
  const isOther = !!value && !purposes.includes(value);
  const selectedItem = !value ? '' : isOther ? others[0] : value;
  const items = [...purposes, ...others].map(purpose => ({
    label: purpose,
    value: purpose,
  }));
  return { selectedItem, items, isOther };
}

const companySizes = ['1-9', '10-49', '50-499', '500-999', '1000+'];

function getCompanySizes(value: string) {
  const selectedItem = value;
  const items = companySizes.map(companySize => ({
    label: `${companySize} ${trans('employees')}`,
    value: companySize,
  }));
  return { selectedItem, items };
}

interface UpdateProfileValues {
  name: string;
  email: string;
  picture: string | null;
  role: string | null;
  purpose: string | null;
  companyName: string | null;
  companySize: string | null;
}

const updateProfileSchema = yupObject().shape({
  name: Validation.project.name.required(),
  picture: yupString().nullable(),
  role: yupString().nullable(),
  purpose: yupString().nullable(),
  companyName: yupString().nullable(),
  companySize: yupString().nullable(),
});

export function AccountSettings() {
  const { user } = useUser();
  const [updateProfile] = useUpdateProfile();
  const { showModal } = useModal();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  let breadcrumbText = '';
  if ((location as any)?.state?.prevPath) {
    const prevPath = (location as any).state.prevPath;
    if (prevPath === '/') {
      breadcrumbText = trans('Back to Project list');
    } else if (prevPath.split('/').length === 3) {
      breadcrumbText = trans('Back to Dashboard');
    } else {
      breadcrumbText = trans('Back to {{prevPath}}', {
        prevPath: capitalize(prevPath.split('/')[3]),
      });
    }
  }

  if (!user) return null;

  const {
    profile: {
      id,
      name,
      email,
      picture,
      role,
      purpose,
      companyName,
      companySize,
    },
  } = user;

  const initialValues = {
    name,
    email,
    picture,
    role,
    purpose,
    companyName,
    companySize,
  };

  const userProfileCompletionPercentage = getUserProfileCompletionPercentage({
    name,
    role,
    purpose,
    companyName,
    companySize,
  });

  return (
    <>
      <PageTitle title={trans('User profile')} />
      <ProjectListTopBar />
      <Form<UpdateProfileValues>
        initialValues={initialValues}
        validate={validateWithYup(updateProfileSchema)}
        subscription={{
          values: true,
          hasValidationErrors: true,
          dirty: true,
          submitting: true,
          submitError: true,
        }}
        onSubmit={(values, form) => {
          const diffValues = updatedDiff(initialValues, values);
          return updateProfile(diffValues).then(
            () => {
              form.restart();
              toast({
                variantColor: 'success',
                title: trans('Changes applied successfully'),
              });
            },
            (error: ApolloError) => {
              return { [FORM_ERROR]: error };
            }
          );
        }}
      >
        {({
          form,
          values,
          handleSubmit,
          hasValidationErrors,
          dirty,
          submitting,
          submitError,
        }) => {
          const userValuesProfileCompletionPercentage =
            getUserProfileCompletionPercentage(values);

          const showAccountProfileCompletion =
            userProfileCompletionPercentage < 100 ||
            userValuesProfileCompletionPercentage < 100;
          return (
            <Flex pt="104px" pb="72px" justifyContent="center" gap="64">
              <Stack maxWidth="688" gap="40">
                <Stack gap="8">
                  <Button
                    variant="link"
                    variantColor="secondary"
                    onClick={
                      breadcrumbText ? () => navigate(-1) : () => navigate('/')
                    }
                    iconBefore={ChevronLeftIcon}
                    alignSelf="baseline"
                  >
                    {breadcrumbText || trans('Back to project list')}
                  </Button>
                  <StackOld as="header">
                    <Heading
                      as="h2"
                      styleAs="h2"
                      fontWeight="bold"
                      lineHeight="neutral.900"
                    >
                      {trans('User profile')}
                    </Heading>
                    <Text color="neutral.500" fontSize="copy">
                      {trans(
                        'Global settings of your profile with options to manage details and delete profile.'
                      )}
                    </Text>
                  </StackOld>
                </Stack>
                <Stack gap="24" as="form">
                  <Heading fontWeight="bold" as="h4">
                    {trans('User details')}
                  </Heading>
                  <Inline justifyContent="space-between" gap="32">
                    <Stack flex={1}>
                      {submitError && (
                        <ApolloInlineError error={submitError}>
                          {(err, key) => (
                            <Alert key={key}>{err.message || err}</Alert>
                          )}
                        </ApolloInlineError>
                      )}
                      <GField
                        name="name"
                        label={trans('Full name')}
                        render={({ input }) => (
                          <Input
                            {...input}
                            data-testid="UserProfileNameInput"
                          />
                        )}
                      />
                      <GField
                        name="email"
                        label={trans('Email address')}
                        render={({ input }) => (
                          <Stack gap="8">
                            <Input
                              {...input}
                              disabled
                              data-testid="UserProfileEmailInput"
                            />
                            <Inline gap="4" alignItems="center">
                              <Box as={Info} size="16px" color="textCaption" />
                              <Text color="neutral.500" lineHeight="small">
                                {trans('Email address cannot be changed.')}
                              </Text>
                            </Inline>
                          </Stack>
                        )}
                      />
                      <GField
                        name="role"
                        label={trans('How would you describe your role?')}
                        isOptional
                        render={({ input }) => {
                          const { selectedItem, items, isOther } = getRoles(
                            input.value
                          );
                          return (
                            <Stack gap="8" flexWrap="wrap">
                              <Combobox
                                selectedItem={selectedItem}
                                items={items}
                                sortedAlphabetically={false} // INFO: don't delete, this is needed to avoid sorting
                                data-testid="UserProfileRoleInput"
                                comboboxInputProps={{
                                  // @ts-expect-error
                                  ['data-testid']: 'UserProfileRoleInput',
                                  id: 'select-userprofileroleinput',
                                  placeholder: trans('Please specify...'),
                                }}
                                {...input}
                              />
                              {isOther && (
                                <Input
                                  {...input}
                                  value={
                                    others.includes(input.value)
                                      ? ''
                                      : input.value
                                  }
                                  autoFocus
                                  data-testid="UserProfileRoleOtherInput"
                                />
                              )}
                            </Stack>
                          );
                        }}
                      />
                      <GField
                        name="purpose"
                        label={trans('What do you use {{productName}} for?', {
                          productName: PRODUCT_NAME,
                        })}
                        isOptional
                        render={({ input }) => {
                          const { selectedItem, items, isOther } = getPurposes(
                            input.value
                          );
                          return (
                            <Stack gap="8" flexWrap="wrap">
                              <Combobox
                                selectedItem={selectedItem}
                                items={items}
                                sortedAlphabetically={false} // INFO: don't delete, this is needed to avoid sorting
                                data-testid="UserProfilePurposeInput"
                                comboboxInputProps={{
                                  id: 'select-userprofilepurpose',
                                  placeholder: trans('Please specify...'),
                                }}
                                {...input}
                              />
                              {isOther && (
                                <Input
                                  {...input}
                                  value={
                                    others.includes(input.value)
                                      ? ''
                                      : input.value
                                  }
                                  autoFocus
                                  data-test="UserProfilePurposeOtherInput"
                                />
                              )}
                            </Stack>
                          );
                        }}
                      />
                      <Inline>
                        <GField
                          name="companyName"
                          label={trans('Company name')}
                          isOptional
                          render={({ input }) => (
                            <Input
                              {...input}
                              placeholder={trans('Please specify...')}
                              data-testid="UserProfileCompanyNameInput"
                            />
                          )}
                        />
                        <GField
                          name="companySize"
                          label={trans('Company size')}
                          isOptional
                          render={({ input }) => {
                            const { selectedItem, items } = getCompanySizes(
                              input.value
                            );
                            return (
                              <Combobox
                                selectedItem={selectedItem}
                                items={items}
                                sortedAlphabetically={false} // INFO: don't delete, this is needed to avoid sorting
                                data-test="UserProfileCompanySizeInput"
                                comboboxInputProps={{
                                  id: 'select-userprofilecompanysizeinput',
                                  placeholder: trans('Please specify...'),
                                }}
                                {...input}
                              />
                            );
                          }}
                        />
                      </Inline>
                    </Stack>
                    <GField
                      name="picture"
                      label={trans('Profile picture')}
                      render={({ input }) => (
                        <Box size="avatar.148">
                          <AccountAvatar
                            name={name}
                            picture={input.value}
                            onChange={input.onChange}
                            handleSubmit={handleSubmit}
                          />
                        </Box>
                      )}
                    />
                  </Inline>
                  <Flex justifyContent="space-between">
                    <Inline>
                      <Button
                        variantColor="primary"
                        size="large"
                        disabled={submitting || hasValidationErrors || !dirty}
                        loading={submitting}
                        loadingText={trans('Saving')}
                        onClick={handleSubmit}
                        data-testid="UserProfileSaveActionButton"
                      >
                        {trans('Save changes')}
                      </Button>
                      {dirty && (
                        <Button
                          variant="ghost"
                          size="large"
                          disabled={submitting}
                          onClick={() => form.initialize(initialValues)}
                        >
                          {trans('Cancel changes')}
                        </Button>
                      )}
                    </Inline>
                  </Flex>
                </Stack>
                <Stack gap="16">
                  <Heading fontWeight="bold" as="h4" mb="s">
                    {trans('Danger zone')}
                  </Heading>
                  <Stack gap="4">
                    <Text fontSize="copy" fontWeight={500} mb="xs">
                      {trans('Delete profile')}
                    </Text>
                    <Text color="neutral.500">
                      {trans(
                        'Deletes the profile permanently. This cannot be undone.'
                      )}
                    </Text>
                  </Stack>
                  <Button
                    onClick={() => {
                      rudderstackTrack(Events.DELETE_PROFILE, {
                        profile_id: id,
                      });

                      showModal(DeleteAccountSurveyDialog, { user });
                    }}
                    size="large"
                    alignSelf="flex-start"
                    variantColor="danger"
                    iconBefore={DeleteIcon}
                    data-test="DeleteAccountDialogButton"
                  >
                    {trans('Delete profile')}
                  </Button>
                </Stack>
              </Stack>
              <Stack width="420">
                {showAccountProfileCompletion && (
                  <AccountProfileCompletion
                    percentage={userValuesProfileCompletionPercentage}
                    isEditing={dirty}
                  />
                )}
              </Stack>
            </Flex>
          );
        }}
      </Form>
    </>
  );
}

export function getUserProfileCompletionPercentage(
  userProfile?: Pick<
    UserFragment['profile'],
    'name' | 'role' | 'purpose' | 'companyName' | 'companySize'
  >
) {
  const { name, role, purpose, companyName, companySize } = userProfile ?? {};
  const inputValues = Object.values({
    name,
    role,
    purpose,
    companyName,
    companySize,
  });
  const percentage =
    (inputValues.filter(Boolean).length / inputValues.length) * 100;
  return Math.trunc(percentage);
}

interface AccountAvatarProps {
  onChange: (handle: string | null) => void;
  picture: string | null;
  name: string;
  handleSubmit: () => void;
}

function AccountAvatar({
  name,
  onChange,
  picture,
  handleSubmit,
}: AccountAvatarProps) {
  const { assetConfig } = useCommonAssetConfig();
  const [loading, setLoading] = useState(false);
  const { showModal } = useModal();
  if (!assetConfig) return null;

  return (
    <Flex
      minHeight="234px" // keep save changes button still, when remove picture button is added
      flexDirection="column"
      alignItems="center"
      justifySelf="flex-end"
    >
      <Avatar
        src={picture || undefined}
        size="avatar.148"
        placeholder={name}
        isLoading={loading}
        borderRadius="medium"
        square
      />
      <Stack gap="4" mt="m" width="100%">
        <Button
          variant="outline"
          width="100%"
          data-testid="UploadUserAvatarButton"
          iconBefore={Image}
          onClick={() => {
            showModal(FilestackUploader, {
              assetConfig,
              onUploading: setLoading,
              onUploaded: async ({ handle }) => {
                onChange(handle);
                handleSubmit();
              },
              maxFiles: 1,
              filestackOptions: { accept: 'image/*' },
            });
          }}
        >
          {trans('Upload picture')}
        </Button>
      </Stack>
    </Flex>
  );
}
