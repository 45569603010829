import * as React from 'react';
import VerifyEmailIcon from './VerifyEmailIcon';
import { Button, Flex, Heading, StackOld, Text } from '@hygraph/baukasten';
import { trans } from 'i18n';
import { Link } from 'react-router-dom-v5-compat';

export const VerifyEmail: React.FC = ({}) => (
  <StackOld
    space="layoutSM"
    width="100%"
    height="100vh"
    justifyContent="center"
    alignItems="center"
    bg="white"
    p="layoutSM"
  >
    <Flex width="100%" justifyContent="center" pb="sm">
      <VerifyEmailIcon width="300px" height="100%" />
    </Flex>
    <Heading as="h1" lineHeight="inviteMembersInfo">
      {trans('Almost there!')}
    </Heading>
    <Text fontSize="inviteMembersInfo">
      {trans(
        'Please click on the link in the verification email we just sent.'
      )}
    </Text>
    <Button<PropsOf<typeof Link>> as={Link} to=".." size="large">
      {trans('Return to login')}
    </Button>
  </StackOld>
);
