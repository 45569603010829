import { useCallback } from 'react';
import { RejectInviteInput } from 'generated/graphql';
import { useManagementApiClient } from 'modules/managementApi';
import { useViewer } from 'modules/user/hooks/useViewer';
import { useRejectInviteMutation } from '../gql/generated/rejectInviteMutation';

export function useDeclineInvite() {
  const client = useManagementApiClient();
  const viewer = useViewer();

  // TODO: see here https://linear.app/graphcms/issue/ENG-4068#comment-2bc47203, shall we use a different mutation to decline? Currently revoke erase the invite entry in the database, maybe we could add a declined_at entry to persist declined invites
  const [rejectInviteMutation, response] = useRejectInviteMutation({
    client,
    update(cache, { data: mutationData }) {
      if (mutationData) {
        const cacheKey = cache.identify({
          id: viewer.viewer?.id,
          __typename: 'UserViewer',
        });
        cache.modify({
          id: cacheKey,
          fields: {
            pendingInvites(existingPendingInvites, { readField }) {
              return existingPendingInvites.filter(
                invite =>
                  readField('id', invite) !==
                  mutationData.rejectInvite?.invite?.id
              );
            },
          },
        });
      }
    },
  });

  const declineInvite = useCallback(
    async function (input: RejectInviteInput) {
      const value = await rejectInviteMutation({
        variables: { input },
      });
      return value.data?.rejectInvite?.invite?.id;
    },
    [rejectInviteMutation]
  );

  return [
    declineInvite,
    {
      loading: response.loading,
      invite: response.data?.rejectInvite?.invite?.id,
      error: response.error,
    },
  ] as const;
}
