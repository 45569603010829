import * as React from 'react';
import { IMPERSONATE } from 'env';
import { useAuthClient, AuthClientType } from '@hygraph/client-auth';
import { trans } from 'i18n';
import {
  Events,
  rudderstackTrack,
} from 'modules/tracking/hooks/useTrackRudderstack';
import { getStoredItem } from 'utils/LocalStorageProvider';
import { useLocation } from 'react-router-dom-v5-compat';
import { AuthError } from 'components/Error/Error';

interface AuthProps {
  login: (apiToken: string) => void;
  client: AuthClientType;
  getNextUrl: (string) => string;
  hash: string;

  setError: React.Dispatch<React.SetStateAction<string>>;
}

class Auth extends React.Component<AuthProps> {
  UNSAFE_componentWillMount() {
    const { hash, /* navigate,*/ login, client, getNextUrl, setError } =
      this.props;
    if (hash) {
      client.parseHash(
        {
          __enableIdPInitiatedLogin: IMPERSONATE,
          hash,
        },
        async (error, authResult) => {
          const url = new URL(location.href);
          if (error) {
            const { errorDescription } = error;
            if (
              errorDescription &&
              errorDescription.includes('verify your email') //&&
              //navigate
            ) {
              // dirty hard redirect while "navigate" does not seem to work
              url.pathname = '/auth/verify';
              url.hash = '';
              location.replace(url.href);
              // navigate('/auth/verify');
              return;
            }

            return setError(errorDescription || trans('Auth failed'));
          }

          if (!authResult || !authResult.idToken) {
            return setError(trans('Authentication failed'));
          }

          login(authResult.idToken);

          const payload = authResult.idTokenPayload as {
            sub: string;
            'https://graphcms.com/loginsCount': number;
          };

          const loginsCount = payload['https://graphcms.com/loginsCount'];

          // auth type
          const type = payload.sub.split('|')[0];
          if (loginsCount === 1) {
            // first time login after signup
            rudderstackTrack(Events.REGISTER, {
              type,
              // when user click accept invite in email before signup, the code will be saved in local storage
              is_invited: !!getStoredItem('Last_Invite_Code'),
            });
          } else {
            rudderstackTrack(Events.LOGIN, { type });
          }

          // handle redirect
          let nextUrl: URL | undefined = undefined;
          try {
            nextUrl = new URL(getNextUrl(authResult.state));
          } catch (e) {}

          if (nextUrl && nextUrl.pathname != '/') {
            window.location.replace(nextUrl);
          } else {
            url.pathname = '/';
            url.hash = '';
          }

          location.replace(url.href);
        }
      );
    }
  }
  render() {
    return this.props.children;
  }
}

export const AuthWithProvider = props => {
  const location = useLocation();
  const { client, getNextUrl, login } = useAuthClient();
  const [error, setError] = React.useState('');

  // ErrorBoundary doesn't work when throw error in the UNSAFE_componentWillMount lifecycle of <Auth/>
  if (error) {
    throw new AuthError(error);
  }

  return (
    <Auth
      login={login}
      client={client}
      getNextUrl={getNextUrl}
      children={props.children}
      hash={location.hash}
      setError={setError}
    />
  );
};
