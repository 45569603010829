import * as Types from '../../../../generated/graphql';

import { ProjectBasicFragment } from '../../../projects/gql/generated/ProjectBasicFragment';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptInviteMutationVariables = Types.Exact<{
  input: Types.AcceptInviteInput;
}>;


export type AcceptInviteMutation = { __typename: 'Mutation', acceptInvite: { __typename: 'AcceptInvitePayload', invite: { __typename: 'Invite', id: string, project: (
        { __typename: 'Project' }
        & ProjectBasicFragment
      ) } } };


export const AcceptInviteMutationDocument = gql`
    mutation acceptInviteMutation($input: AcceptInviteInput!) {
  acceptInvite(input: $input) {
    invite {
      id
      project {
        ...ProjectBasicFragment
      }
    }
  }
}
    ${ProjectBasicFragment}`;

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInviteMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInviteMutation, AcceptInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInviteMutation, AcceptInviteMutationVariables>(AcceptInviteMutationDocument, options);
      }
export type AcceptInviteMutationHookResult = ReturnType<typeof useAcceptInviteMutation>;