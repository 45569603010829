import * as React from 'react';
import { Button, ButtonProps } from '@hygraph/baukasten';
import { trans } from 'i18n';

const prefilledMessage = (errorMessage: string) =>
  trans(
    'Hello, I just received this error message:\n{{errorMessage}}\n\nThis is what I just did:\n',
    { errorMessage }
  );

interface Props {
  errorMessage: string;
  className?: string;
}

export const AskForHelpCTA: React.FC<Props & ButtonProps> = ({
  errorMessage,
  ...otherProps
}) => (
  <Button
    variant="outline"
    size="large"
    onClick={() =>
      window.Intercom?.('showNewMessage', prefilledMessage(errorMessage))
    }
    {...otherProps}
  >
    {trans('Ask for help')}
  </Button>
);
