import { Box, ComboboxItemType } from '@hygraph/baukasten';
import { trans } from 'i18n';

import type { Region } from 'modules/projects/components/CreateProject/useGetRegions';

export function getRegionSelectItems(
  regions: Region[] | undefined
): ComboboxItemType[] {
  const items = regions
    ? regions?.map(region => {
        let label = region.label ?? region.id;
        if (!region.selectable) {
          label = `${label} (${trans('This is a paid-only region')})`;
        }

        return {
          label,
          value: region.id,
          disabled: !region.selectable,
          ...(region?.icon
            ? { Component: () => <Box as={region.icon} mr="xs" /> }
            : {}),
        };
      })
    : [];

  items?.sort((a, b) => Number(a.disabled) - Number(b.disabled));

  return items;
}
