import { useManagementApiClient } from 'modules/managementApi';
import {
  ProjectsQuery,
  useProjectsQuery,
} from '../gql/generated/projectsQuery';

export function useProjects() {
  const client = useManagementApiClient();
  const { loading, data, error } = useProjectsQuery({
    client,
  });

  return {
    loading,
    error,
    projectListData: data?.viewer as Extract<
      ProjectsQuery['viewer'],
      { __typename: 'UserViewer' }
    >,
  };
}
