import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectChangedSubscriptionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProjectChangedSubscriptionQuery = { __typename: 'Subscription', projectChanged: { __typename: 'ProjectChangeCompletedCloning', clonedProject: { __typename: 'Project', isCloning?: boolean | null | undefined, id: string, name: string, description?: string | null | undefined, picture?: string | null | undefined, region: { __typename: 'Region', id: string, name: string } } } };


export const ProjectChangedSubscriptionQueryDocument = gql`
    subscription projectChangedSubscriptionQuery {
  projectChanged {
    ... on ProjectChangeCompletedCloning {
      clonedProject {
        isCloning
        id
        name
        description
        picture
        region {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useProjectChangedSubscriptionQuery__
 *
 * To run a query within a React component, call `useProjectChangedSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectChangedSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectChangedSubscriptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectChangedSubscriptionQuery(baseOptions?: Apollo.SubscriptionHookOptions<ProjectChangedSubscriptionQuery, ProjectChangedSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ProjectChangedSubscriptionQuery, ProjectChangedSubscriptionQueryVariables>(ProjectChangedSubscriptionQueryDocument, options);
      }
export type ProjectChangedSubscriptionQueryHookResult = ReturnType<typeof useProjectChangedSubscriptionQuery>;