import { InviteStatus } from 'generated/graphql';
import { useManagementApiClient } from 'modules/managementApi';
import { useInviteChangedSubscriptionQuery } from '../gql/generated/inviteChangedSubscriptionQuery';
import { PendingInviteFragment } from '../gql/generated/PendingInviteFragment';
import { ProjectsQueryDocument } from '../gql/generated/projectsQuery';
import { useToast } from '@hygraph/baukasten';
import { trans } from '../../../i18n';

export function useInviteChangedSubscription() {
  const client = useManagementApiClient();
  const toast = useToast();
  const { loading, data, error } = useInviteChangedSubscriptionQuery({
    client,
    onSubscriptionData: ({ client: { cache }, subscriptionData }) => {
      const inviteID = subscriptionData.data?.inviteUpdate.inviteId;
      const status = subscriptionData.data?.inviteUpdate.status;

      const __typename: PendingInviteFragment['__typename'] = 'Invite';
      const cacheID = cache.identify({ id: inviteID, __typename });

      if (status === InviteStatus.ACCEPTED) {
        return client
          .query({
            query: ProjectsQueryDocument,
            // without `network-only` client.query sometimes doesnt fetch, probably cuz apollo thinks data is already in cache.
            fetchPolicy: 'network-only',
          })
          .then(() => {
            toast({
              variantColor: 'success',
              description: trans('Invite accepted successfully'),
            });
          })
          .finally(() => {
            cache.modify({
              id: cacheID,
              fields(_, { DELETE }) {
                return DELETE;
              },
            });
          });
      }

      return cache.modify({
        id: cacheID,
        fields: {
          status() {
            return status;
          },
        },
      });
    },
  });

  if (error) throw error;

  return {
    loading,
    error,
    inviteId: data && data.inviteUpdate.inviteId,
  };
}
