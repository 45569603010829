import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { Fragment } from 'react';
import { ErrorType } from 'components/Error';

interface ApolloErrorDisplayProps {
  error?: ApolloError;
  children: (error: Error, key: string, type: ErrorType) => JSX.Element | null;
}

export const ApolloInlineError: React.FC<ApolloErrorDisplayProps> = ({
  error,
  children,
}) => {
  if (error) {
    if (Array.isArray(error.graphQLErrors) && error.graphQLErrors.length > 0) {
      return (
        <Fragment>
          {error.graphQLErrors.map(
            (
              graphQLError: GraphQLError & {
                errorCode?: string;
              },
              index
            ) => {
              const err = new Error(graphQLError.message);
              let type = ErrorType.Unexpected;
              if (graphQLError.errorCode) {
                err.name = graphQLError.errorCode;
                type = ErrorType.Expected;
              }
              return children(err, String(index), type);
            }
          )}
        </Fragment>
      );
    }

    if (error.networkError) {
      return children(error.networkError, String(0), ErrorType.Network);
    } else {
      return children(error, String(0), ErrorType.Client);
    }
  }
  return null;
};
