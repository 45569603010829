import {
  ObjectSchema,
  addMethod,
  StringSchema,
  string,
} from '@graphcms/validation';
import { clone, toPath } from 'lodash';
import { trans } from 'i18n';

type FormattedErrors<Values> = {
  [K in keyof Values]?: Values[K] extends any[]
    ? Values[K][number] extends Record<string, any>
      ? FormattedErrors<Values[K][number]>[] | string | string[]
      : string | string[]
    : Values[K] extends Record<string, any>
    ? FormattedErrors<Values[K]>
    : string;
};

// eslint-disable-next-line
export function validateWithYup<V = any>(
  schema: ObjectSchema<Record<string, unknown>>,
  valueTransform: (values: V) => V = values => values
) {
  return (values: V) => {
    try {
      schema.validateSync(valueTransform(values), { abortEarly: false });
    } catch (error) {
      return yupToFormErrors(error);
    }
    return {};
  };
}

/**
 * @private is the given object an Object?
 *
 * copied from formik, apparently not the same as formik isObject
 */
const isObject = (obj: any): obj is Record<string, unknown> =>
  obj !== null && typeof obj === 'object';

/**
 * @private is the given object an integer?
 *
 * copied from formik, apparently not the same as formik isInteger
 */
const isInteger = (obj: any): boolean =>
  String(Math.floor(Number(obj))) === obj;

/**
 * Copied from formik
 */
export function yupToFormErrors<Values>(
  yupError: any
): FormattedErrors<Values> {
  let errors: FormattedErrors<Values> = {};
  if (yupError.inner) {
    if (yupError.inner.length === 0) {
      return setIn(errors, yupError.path, yupError.message);
    }
    for (const err of yupError.inner) {
      if (!getIn(errors, err.path)) {
        errors = setIn(errors, err.path, err.message);
      }
    }
  }
  return errors;
}

/**
 * Deeply get a value from an object via its path.
 * copied from formik for yupToFormErrors
 * can maybe be replaced by lodash?
 */
export function getIn(obj: any, key: string | string[], def?: any, p = 0) {
  const path = toPath(key);
  while (obj && p < path.length) {
    obj = obj[path[p++]];
  }
  return obj === undefined ? def : obj;
}

/**
 * Deeply set a value from in object via it's path. If the value at `path`
 * has changed, return a shallow copy of obj with `value` set at `path`.
 * If `value` has not changed, return the original `obj`.
 *
 * copied from formik for yupToFormErrors
 * can maybe be replaced by lodash?
 */
function setIn(obj: any, path: string, value: any): any {
  const res: any = clone(obj); // this keeps inheritance when obj is a class
  let resVal: any = res;
  let i = 0;
  const pathArray = toPath(path);

  for (; i < pathArray.length - 1; i++) {
    const currentPath: string = pathArray[i];
    const currentObj: any = getIn(obj, pathArray.slice(0, i + 1));

    if (currentObj && (isObject(currentObj) || Array.isArray(currentObj))) {
      resVal = resVal[currentPath] = clone(currentObj);
    } else {
      const nextPath: string = pathArray[i + 1];
      resVal = resVal[currentPath] =
        isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
    }
  }

  // Return original object if new value is the same as current
  if ((i === 0 ? obj : resVal)[pathArray[i]] === value) {
    return obj;
  }

  if (value === undefined) {
    delete resVal[pathArray[i]];
  } else {
    resVal[pathArray[i]] = value;
  }

  // If the path array has a single element, the loop did not run.
  // Deleting on `resVal` had no effect in this scenario, so we delete on the result instead.
  if (i === 0 && value === undefined) {
    delete res[pathArray[i]];
  }

  return res;
}

// Matches all types of urls including localhost & ips
// Taken from https://github.com/jquense/yup/issues/224#issuecomment-417172609
const urlRegex =
  /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;

addMethod<StringSchema>(string, 'gcmsUrl', function validateUrl() {
  return this.matches(urlRegex, trans("Sorry, that's an incorrect URL."));
});
