import { UserViewer } from 'generated/graphql';
import { useManagementApiClient } from 'modules/managementApi';
import { useViewerAppsQuery } from '../gql/generated/viewerAppsQuery';

export function useViewerApps() {
  const client = useManagementApiClient();
  const { loading, data, error } = useViewerAppsQuery({ client });

  return {
    loading,
    error,
    apps: (data?.viewer as UserViewer | undefined)?.apps,
  };
}
