import { useGetRegions } from 'modules/projects/components/CreateProject/useGetRegions';
import { getRegionSelectItems } from 'modules/projects/utils/getRegionSelectItems';
import { trans } from 'i18n';
import { Combobox } from '@hygraph/baukasten';
import { GField } from 'components/GField';

interface Props {
  id?: string;
  label?: string;
}

export const RegionSelector = ({
  id = 'CreateProjectRegion',
  label,
}: Props) => {
  const { regions } = useGetRegions();
  const regionItems = getRegionSelectItems(regions);

  return (
    <GField
      name="region"
      label={label ?? trans('Region')}
      render={({ input, meta: { touched, error } }) => (
        <Combobox
          {...input}
          clearable={false}
          sortedAlphabetically={false}
          hasError={touched && !!error}
          selectedItem={input.value}
          items={regionItems}
          comboboxInputProps={{
            id,
            placeholder: trans('Select region'),
          }}
        />
      )}
    />
  );
};
