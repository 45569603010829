import { useCallback } from 'react';
import { useManagementApiClient } from 'modules/managementApi';
import { DeleteAppInput } from 'generated/graphql';
import { useDeleteAppMutation } from '../gql/generated/deleteAppMutation';
import { ViewerAppsQueryDocument } from '../gql/generated/viewerAppsQuery';
import { useToast } from '@hygraph/baukasten';
import { trans } from 'i18n';

export function useDeleteApp() {
  const client = useManagementApiClient();
  const toast = useToast();
  const [deleteMutation, { loading, data, error }] = useDeleteAppMutation({
    client,
    refetchQueries: [{ query: ViewerAppsQueryDocument }],
    onCompleted: () => {
      toast({
        title: trans('App deleted'),
        variantColor: 'success',
      });
    },
  });

  const deleteApp = useCallback(
    function (input: DeleteAppInput) {
      return deleteMutation({
        variables: {
          input,
        },
      }).then(({ data }) => data && data.deleteApp.deletedAppId);
    },
    [deleteMutation]
  );

  return {
    deleteApp,
    loading,
    deleteAppId: data?.deleteApp.deletedAppId,
    error,
  } as const;
}
