import {
  Dialog,
  DialogContent,
  DialogFooter,
  Button,
  DialogHeader,
  Stack,
  Text,
  Alert,
  Heading,
  Tooltip,
  Label,
  Hint,
} from '@hygraph/baukasten';

import { WithModalProps } from 'components/Modal/ModalContext';

import { PRODUCT_DOMAIN } from 'env';
import { AppWithSecrets } from 'generated/graphql';
import { trans } from 'i18n';
import { CopyTextInput, LearnMoreExternalLink } from 'lib/presentation';
import { noop } from 'utils';

export const AppSecretsModal = (
  props: WithModalProps<Pick<AppWithSecrets, 'clientId' | 'clientSecret'>>
) => {
  const { onRequestClose, clientId, clientSecret } = props;

  if (!clientId || !clientSecret) return null;

  return (
    <Dialog
      aria-label="App secrets modal"
      onDismiss={noop}
      isOpen
      preventClosing
    >
      <DialogHeader>
        <Heading as="h4" styleAs="h4" fontWeight={500}>
          {trans('App secrets')}
        </Heading>
      </DialogHeader>
      <DialogContent>
        <Stack gap="16">
          <Text fontSize={'copy'}>
            {trans('Copy the secrets below to generate app token.')}{' '}
            <LearnMoreExternalLink
              href={`https://${PRODUCT_DOMAIN}/docs/app-framework/app-tokens`}
            />
          </Text>
          <Tooltip label={trans('Click to copy')}>
            <Stack gap="4">
              <Label>{trans('Client ID')}</Label>
              <Hint>
                {trans(
                  'Unique identifier of your app that is used for authentication and authorization.'
                )}
              </Hint>

              <CopyTextInput
                data-autofocus
                message={trans('Client ID copied to clipboard')}
                value={clientId}
              />
            </Stack>
          </Tooltip>

          <Tooltip label={trans('Click to copy')}>
            <Stack gap="4">
              <Label>{trans('Client Secret')}</Label>
              <Hint>
                {trans(
                  'Used with client ID to resolve and grant access to your app.'
                )}
              </Hint>
              <CopyTextInput
                data-autofocus
                message={trans('Client Secret copied to clipboard')}
                value={clientSecret}
              />
            </Stack>
          </Tooltip>
          <Alert variantColor="warning" showStatusIcon>
            {trans(
              'After you close the modal you cannot access these secrets again, you will have to regenerate them.'
            )}
          </Alert>
        </Stack>
      </DialogContent>
      <DialogFooter>
        <Button size="large" onClick={onRequestClose}>
          {trans('I have copied the secrets')}
        </Button>
      </DialogFooter>
    </Dialog>
  );
};
