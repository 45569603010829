import * as Types from '../../../../generated/graphql';

import { AppFragment_App_, AppFragment_AppForOwner_, AppFragment_AppWithSecrets_ } from './AppFragment';
import { gql } from '@apollo/client';
import { AppFragment } from './AppFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAppMutationVariables = Types.Exact<{
  input: Types.UpdateAppInput;
}>;


export type UpdateAppMutation = { __typename: 'Mutation', updateApp: { __typename: 'UpdateAppPayload', updatedApp: (
      { __typename: 'AppForOwner', accessType: Types.AppAccessType }
      & AppFragment_AppForOwner_
    ) } };


export const UpdateAppMutationDocument = gql`
    mutation updateAppMutation($input: UpdateAppInput!) {
  updateApp(input: $input) {
    updatedApp {
      ...AppFragment
      accessType
    }
  }
}
    ${AppFragment}`;

/**
 * __useUpdateAppMutation__
 *
 * To run a mutation, you first call `useUpdateAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppMutation, { data, loading, error }] = useUpdateAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppMutation, UpdateAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppMutation, UpdateAppMutationVariables>(UpdateAppMutationDocument, options);
      }
export type UpdateAppMutationHookResult = ReturnType<typeof useUpdateAppMutation>;