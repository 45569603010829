import { UserViewer } from 'generated/graphql';
import { useManagementApiClient } from 'modules/managementApi';
import { useViewerAppQuery } from '../gql/generated/viewerAppQuery';
import { AppFragment } from '../gql/generated/AppFragment';

export function useViewerApp(apiId: string) {
  const client = useManagementApiClient();
  const { loading, data, error } = useViewerAppQuery({
    client,
    variables: { apiId },
  });

  return {
    loading,
    error,
    app: { ...(data?.viewer as UserViewer | undefined)?.app } as AppFragment,
  };
}
