//import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import { LD_CLIENT_ID } from './env';
import { withLDProvider } from 'modules/launchDarkly/useLaunchDarkly';

import 'typeface-inter';

import { Main } from './Main';

// import i18n (needs to be bundled ;))
import './i18n';

const App = LD_CLIENT_ID
  ? withLDProvider({
      clientSideID: LD_CLIENT_ID,
    })(Main)
  : Main;

ReactDOM.render(<App />, document.getElementById('root'));
