import { Component } from 'react';
import { ApolloError } from '@apollo/client';
import { GlobalErrorOverlay } from 'components/Error/GlobalErrorOverlay';
import { ApolloErrorDisplay } from 'components/Error/ApolloErrorDisplay';
import { AuthErrorDisplay } from 'components/Error/AuthErrorDisplay';
import { GenericGlobalErrorDisplay } from 'components/Error/GenericGlobalErrorDisplay';
import {
  BoundaryError,
  AuthError,
  MaintenanceError,
} from 'components/Error/Error';
import { logError, ErrorCategory } from 'components/Error/logError';

interface Props {
  render?: (error: Error) => JSX.Element;
  errorCategory?: ErrorCategory;
}

interface State {
  error: Error | ApolloError | null;
}

class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError(error: Error | ApolloError) {
    return { error };
  }
  state: State = { error: null };

  componentDidCatch(error: BoundaryError, reactErrorInfo: React.ErrorInfo) {
    logError({ error, reactErrorInfo });
  }

  resetError = () => {
    this.setState({ error: null });
  };

  render() {
    const { error } = this.state;
    if (error) {
      if (error instanceof ApolloError) {
        // @ts-expect-error // INFO: skip error screen when redirecting to login page for a smoother transition
        if (error.graphQLErrors?.login || error.networkError?.login)
          return null;
        return (
          <ApolloErrorDisplay error={error} resetError={this.resetError} />
        );
      }
      if (error instanceof AuthError) {
        return <AuthErrorDisplay error={error} resetError={this.resetError} />;
      }
      // TODO: Handle maintenance in some other way than throwing it
      if (error instanceof MaintenanceError) {
        return (
          <GlobalErrorOverlay>
            <h2>{error.message}</h2>
          </GlobalErrorOverlay>
        );
      }
      if (error.message && error.message.includes('Loading chunk')) {
        // Whenever a new version is deployed and some old deployment file is required before the service worker could kick in,
        // reload the whole page
        window.location.reload();
      }
      if (this.props.render) {
        return this.props.render(error);
      }
      return (
        <GenericGlobalErrorDisplay error={error} resetError={this.resetError} />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
