import {
  Button,
  Text,
  Alert,
  TextArea,
  CheckBox,
  InputError,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  Heading,
  Stack,
  FieldSet,
  Box,
} from '@hygraph/baukasten';
import { Form } from 'react-final-form';
import * as yup from '@graphcms/validation';

import { GField } from 'components/GField';
import { ApolloInlineError } from 'components/Error/ApolloErrorDisplay/ApolloInlineError';
import { CheckboxGField } from 'components/GField/CheckboxGField';
import { useModal } from 'components/Modal/useModal';
import { validateWithYup } from 'utils';
import { trans } from 'i18n';
import { UserFragment } from 'modules/user/gql/generated/UserFragment';
import {
  Events,
  rudderstackTrack,
} from 'modules/tracking/hooks/useTrackRudderstack';
import { DeleteAccountConfirmation } from './DeleteAccountConfirmation';

const reasonsOptions = [
  trans("The product isn't relevant or useful for my needs"),
  trans("How to use the product isn't very clear"),
  trans('The support team did not help me enough'),
  trans("The pricing doesn't match my budget"),
  trans('Other'),
];

const feedbackSchema = yup.object().shape({
  reasons: yup
    .array()
    .of(yup.string())
    .min(1)
    .nullable()
    .required(trans('Please select at least one on the above')),
  message: yup.string(),
});

interface DeleteAccountSurveyDialogFormFields {
  reasons: string[];
  message: string | null;
}

export interface DeleteAccountSurveyDialogProps {
  user: UserFragment;
  onRequestClose: () => void;
}

export function DeleteAccountSurveyDialog({
  onRequestClose,
  user,
}: DeleteAccountSurveyDialogProps) {
  const { showModal } = useModal();

  return (
    <Dialog
      isOpen
      onDismiss={onRequestClose}
      aria-label={trans('Delete Account Feedback Dialog')}
    >
      <Form<DeleteAccountSurveyDialogFormFields>
        validate={validateWithYup(feedbackSchema)}
        initialValues={{
          reasons: [],
          message: '',
        }}
        onSubmit={values => {
          rudderstackTrack(Events.DELETE_PROFILE_NEXT_STEP, {
            profile_id: user.profile.id,
          });
          showModal(DeleteAccountConfirmation, {
            user,
            reasons: values.reasons,
            message: values.message,
          });
          onRequestClose();
        }}
      >
        {({ handleSubmit, submitError, errors, touched }) => (
          <Box as="form" onSubmit={handleSubmit}>
            <DialogHeader>
              <Heading as="h4" fontWeight="medium">
                {trans('Delete profile')}
              </Heading>
            </DialogHeader>
            <DialogContent>
              <ApolloInlineError error={submitError}>
                {(err, key) => (
                  <Alert mb="s" key={key}>
                    {err.message || err}
                  </Alert>
                )}
              </ApolloInlineError>
              <Stack gap="24">
                <Text fontSize="copy" color="neutral.700">
                  {trans(
                    "Please help us improve by telling us why you're leaving."
                  )}
                </Text>
                <Stack gap="16">
                  <FieldSet label={trans('Reason for leaving')}>
                    <Stack gap="16">
                      {reasonsOptions.map(option => (
                        <CheckboxGField
                          key={option}
                          type="checkbox"
                          name="reasons"
                          label={option}
                          hideErrors
                          value={option}
                          render={({ input }) => {
                            return <CheckBox {...input} />;
                          }}
                        />
                      ))}
                    </Stack>
                  </FieldSet>
                  {errors?.reasons && touched?.reasons && (
                    <InputError mt="negS">{errors.reasons}</InputError>
                  )}
                </Stack>
                <GField
                  name="message"
                  label={trans('Is there anything we can improve?')}
                  isOptional
                  render={({ input }) => (
                    <TextArea {...input} value={input.value} />
                  )}
                />
              </Stack>
            </DialogContent>
            <DialogFooter>
              <Button
                onClick={e => {
                  e.stopPropagation();
                  rudderstackTrack(Events.DELETE_PROFILE_CANCEL, {
                    profile_id: user.profile.id,
                  });
                  onRequestClose();
                }}
                size="large"
                variant="ghost"
                autoFocus
              >
                {trans('Cancel')}
              </Button>
              <Button type="submit" size="large">
                {trans('Continue')}
              </Button>
            </DialogFooter>
          </Box>
        )}
      </Form>
    </Dialog>
  );
}
