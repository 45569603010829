import { useUser } from 'modules/user/hooks/useUser';
import { useEffect } from 'react';

export const fieldParentMap = {
  AssetModel: 'from asset model',
  Model: 'from model',
  Component: 'from component',
};

export function rudderstackTrack<T extends Events>(...args: Params<T>) {
  window?.rudderanalytics?.track(...args);
}

export function useRudderstackIdentifyUser() {
  const { user } = useUser();

  useEffect(() => {
    if (!user) return;

    if (window?.rudderanalytics?.identify) {
      // cleanup existing rudderstack local storage values before adding the new user session values
      cleanupRudderstackLocalStorageValues();
      window.rudderanalytics.identify(user.id);
    }
  }, [user]);
}

function cleanupRudderstackLocalStorageValues() {
  Object.keys(localStorage).forEach(key => {
    if (key.startsWith('rudder.')) localStorage.removeItem(key);
  });
}

export enum Events {
  PROJECT_CREATING = 'project creating',
  PROJECT_CREATED = 'project created',
  REGISTER = 'register',
  LOGIN = 'login',
  CREATE_ENTRY = 'create entry',
  ENTRY_CREATING = 'entry creating',
  ENTRY_CREATED = 'entry created',
  ENTRY_UPDATING = 'entry updating',
  ENTRY_UPDATED = 'entry updated',
  DELETE_ENTRY = 'delete entry',
  ENTRY_DELETING = 'entry deleting',
  ENTRY_DELETED = 'entry deleted',
  UPDATE_ENTRY = 'update entry',
  CREATE_PROJECT = 'create project',
  UPDATE_COMPONENT_SETTING = 'update component setting',
  DELETE_COMPONENT = 'delete component',
  COMPONENT_DELETING = 'component deleting',
  COMPONENT_DELETED = 'component deleted',
  CREATE_FIELD = 'create field',
  UPDATE_FIELD = 'update field',
  UPDATE_ENUMERATION = 'update enumeration',
  DELETE_ENUMERATION = 'delete enumeration',
  ENUMERATION_DELETING = 'enumeration deleting',
  ENUMERATION_DELETED = 'enumeration deleted',
  ENUMERATION_CREATED = 'enumeration created',
  ENUMERATION_UPDATED = 'enumeration updated',
  ENUMERATION_UPDATING = 'enumeration updating',
  ENUMERATION_CREATING = 'enumeration creating',
  CREATE_ENUMERATION = 'create enumeration',
  MODEL_CREATED = 'model created',
  MODEL_SETTING_UPDATED = 'model setting updated',
  COMPONENT_CREATED = 'component created',
  COMPONENT_SETTING_UPDATED = 'component setting updated',
  COMPONENT_CREATING = 'component creating',
  COMPONENT_SETTING_UPDATING = 'component setting updating',
  MODEL_CREATING = 'model creating',
  MODEL_SETTING_UPDATING = 'model setting updating',
  FIELD_CREATED = 'field created',
  FIELD_CREATING = 'field creating',
  DELETE_FIELD = 'delete field',
  FIELD_DELETING = 'field deleting',
  FIELD_DELETED = 'field deleted',
  FIELD_UPDATED = 'field updated',
  FIELD_UPDATING = 'field updating',
  UPDATE_MODEL_SETTING = 'update model setting',
  DELETE_MODEL = 'delete model',
  MODEL_DELETING = 'model deleting',
  MODEL_DELETED = 'model deleted',
  REMOTE_SOURCE_CREATED = 'remote source created',
  REMOTE_SOURCE_UPDATED = 'remote source updated',
  REMOTE_SOURCE_CREATING = 'remote source creating',
  REMOTE_SOURCE_UPDATING = 'remote source updating',
  DELETE_REMOTE_SOURCE = 'delete remote source',
  REMOTE_SOURCE_DELETING = 'remote source deleting',
  REMOTE_SOURCE_DELETED = 'remote source deleted',
  UPDATE_REMOTE_SOURCE = 'update remote source',
  CREATE_MODEL = 'create model',
  CREATE_COMPONENT = 'create component',
  CREATE_REMOTE_SOURCE = 'create remote source',
  CREATE_WEBHOOK = 'create webhook',
  PROJECT_DELETING = 'project deleting',
  PROJECT_DELETED = 'project deleted',
  DELETE_PROJECT = 'delete project',
  DELETE_PROJECT_CANCEL = 'delete project cancel',
  DELETE_PROJECT_NEXT_STEP = 'delete project next step',
  PROJECT_LEAVING = 'project leaving',
  PROJECT_LEFT = 'project left',
  LEAVE_PROJECT = 'leave project',
  LEAVE_PROJECT_CANCEL = 'leave project cancel',
  LEAVE_PROJECT_NEXT_STEP = 'leave project next step',
  PLAN_SELECTED = 'plan selected',
  RECENT_PROJECTS_DROPDOWN = 'recent projects dropdown',
  RECENT_PROJECTS_ALL = 'recent projects all',
  RECENT_PROJECTS_PROJECT = 'recent projects project',
  COMMENTS_TAB_OPENED = 'comments tab opened',
  COMMENT_FILTER_SELECTED = 'comment filter selected',
  COMMENT_CREATED = 'comment created',
  REPLY_CREATED = 'reply created',
  COMMENT_UPDATED = 'comment updated',
  REPLY_UPDATED = 'reply updated',
  COMMENT_DELETED = 'comment deleted',
  REPLY_DELETED = 'reply deleted',
  COMMENT_RESOLVED = 'comment resolved',
  COMMENT_REOPENED = 'comment reopened',
  COMMENT_LINK_COPIED = 'comment link copied',
  COMMENT_MARK_ALL_AS_READ = 'comment mark all as read',
  NOTIFICATION_OPENED = 'notification opened',
  NOTIFICATION_CLICKED = 'notification clicked',
  DELETE_PROFILE = 'delete profile',
  DELETE_PROFILE_CANCEL = 'delete profile cancel',
  DELETE_PROFILE_NEXT_STEP = 'delete profile next step',
  PROFILE_DELETING = 'profile deleting',
  PROFILE_DELETED = 'profile deleted',
  SEND_FEEDBACK = 'send feedback',
  SEND_FEEDBACK_CANCEL = 'send feedback cancel',
  FEEDBACK_SENDING = 'feedback sending',
  FEEDBACK_SENT = 'feedback sent',
  FEEDBACK_BEACON = 'feedback beacon',
  FEEDBACK_BEACON_CANCEL = 'feedback beacon cancel',
  FEEDBACK_BEACON_SENDING = 'feedback beacon sending',
  FEEDBACK_BEACON_SENT = 'feedback beacon sent',
  DIRECTORY_STUDIO_TOGGLE_ENABLE = 'directory studio toggle enable',
  DIRECTORY_STUDIO_TOGGLE_DISABLE = 'directory studio toggle disable',
}

// TODO
// please add new events in google sheet: FE Tracking - Event List
// for BI and non tech people
// https://docs.google.com/spreadsheets/d/1IP8XiOYt9NEozceT-XRW48kUPKwNSduGzkeGXeuyFlw/edit#gid=0

type Params<T extends Events> = T extends
  | Events.ENUMERATION_CREATED
  | Events.ENUMERATION_CREATING
  | Events.MODEL_CREATED
  | Events.COMPONENT_CREATED
  | Events.COMPONENT_CREATING
  | Events.MODEL_CREATING
  | Events.REMOTE_SOURCE_CREATED
  | Events.REMOTE_SOURCE_CREATING
  | Events.PROJECT_DELETING
  | Events.PROJECT_DELETED
  | Events.DELETE_PROJECT
  | Events.DELETE_PROJECT_CANCEL
  | Events.DELETE_PROJECT_NEXT_STEP
  | Events.PROJECT_LEAVING
  | Events.PROJECT_LEFT
  | Events.LEAVE_PROJECT
  | Events.LEAVE_PROJECT_CANCEL
  | Events.LEAVE_PROJECT_NEXT_STEP
  | Events.RECENT_PROJECTS_DROPDOWN
  | Events.RECENT_PROJECTS_ALL
  | Events.RECENT_PROJECTS_PROJECT
  | Events.SEND_FEEDBACK
  | Events.SEND_FEEDBACK_CANCEL
  | Events.FEEDBACK_SENDING
  | Events.FEEDBACK_SENT
  ? [event: T, properties: { project_id: string }]
  : T extends
      | Events.MODEL_SETTING_UPDATED
      | Events.MODEL_SETTING_UPDATING
      | Events.UPDATE_MODEL_SETTING
      | Events.DELETE_MODEL
      | Events.MODEL_DELETING
      | Events.MODEL_DELETED
  ? [event: T, properties: { project_id: string; model_id: string }]
  : T extends
      | Events.ENTRY_CREATING
      | Events.ENTRY_UPDATING
      | Events.ENTRY_DELETING
  ? [event: T, properties: { project_id: string; schema_id: string }]
  : T extends Events.ENTRY_CREATED | Events.ENTRY_UPDATED | Events.ENTRY_DELETED
  ? [
      event: T,
      properties: {
        project_id: string;
        schema_id: string;
        entry_id?: string[];
        content_view_id?: string;
      }
    ]
  : T extends
      | Events.UPDATE_ENUMERATION
      | Events.DELETE_ENUMERATION
      | Events.ENUMERATION_DELETING
      | Events.ENUMERATION_DELETED
      | Events.ENUMERATION_UPDATED
      | Events.ENUMERATION_UPDATING
  ? [event: T, properties: { project_id: string; enumeration_id: string }]
  : T extends
      | Events.UPDATE_COMPONENT_SETTING
      | Events.DELETE_COMPONENT
      | Events.COMPONENT_DELETING
      | Events.COMPONENT_DELETED
      | Events.COMPONENT_SETTING_UPDATED
      | Events.COMPONENT_SETTING_UPDATING
  ? [event: T, properties: { project_id: string; component_id: string }]
  : T extends
      | Events.REMOTE_SOURCE_UPDATED
      | Events.REMOTE_SOURCE_UPDATING
      | Events.DELETE_REMOTE_SOURCE
      | Events.REMOTE_SOURCE_DELETING
      | Events.REMOTE_SOURCE_DELETED
      | Events.UPDATE_REMOTE_SOURCE
  ? [event: T, properties: { project_id: string; remote_source_id: string }]
  : T extends
      | Events.FIELD_CREATED
      | Events.FIELD_CREATING
      | Events.FIELD_DELETING
      | Events.FIELD_DELETED
      | Events.FIELD_UPDATED
      | Events.FIELD_UPDATING
  ? [
      event: T,
      properties: { project_id: string; schema_id: string; field_name: string }
    ]
  : T extends Events.CREATE_FIELD | Events.UPDATE_FIELD | Events.DELETE_FIELD
  ? [
      event: T,
      properties: {
        type: typeof fieldParentMap[keyof typeof fieldParentMap];
        project_id: string;
        schema_id: string;
        field_name: string;
      }
    ]
  : T extends
      | Events.CREATE_MODEL
      | Events.CREATE_COMPONENT
      | Events.CREATE_REMOTE_SOURCE
      | Events.CREATE_WEBHOOK
  ? [
      event: T,
      properties: {
        project_id: string;
        type: 'from empty screen' | 'from empty screen demo' | 'from list';
      }
    ]
  : T extends Events.PROJECT_CREATING
  ? [
      event: T,
      properties: {
        type:
          | 'template project creating'
          | 'empty project creating'
          | 'public project cloning'
          | 'private project cloning';
        template_id?: string;
        public_access_id?: string;
        private_id?: string;
      }
    ]
  : T extends Events.PROJECT_CREATED
  ? [
      event: T,
      properties: {
        type:
          | 'template project created'
          | 'empty project created'
          | 'public project cloned'
          | 'private project cloned';
        project_id: string;
        template_id?: string;
        public_access_id?: string;
        private_id?: string;
      }
    ]
  : T extends Events.REGISTER
  ? [event: T, properties: { type: string; is_invited: boolean }]
  : T extends Events.LOGIN
  ? [event: T, properties: { type: string }]
  : T extends Events.CREATE_ENTRY
  ? [
      event: T,
      properties: {
        type:
          | 'from upload'
          | 'from assets'
          | 'from content'
          | 'from entry detail';
        project_id: string;
        schema_id: string;
      }
    ]
  : T extends Events.DELETE_ENTRY
  ? [
      event: T,
      properties: {
        type: 'from dropdown' | 'from tab';
        project_id: string;
        schema_id: string;
      }
    ]
  : T extends Events.UPDATE_ENTRY
  ? [
      event: T,
      properties: {
        type: 'from tab' | 'from icon' | 'from dropdown';
        project_id: string;
        schema_id: string;
      }
    ]
  : T extends Events.CREATE_PROJECT
  ? [
      event: T,
      properties: {
        type:
          | 'create empty project'
          | 'create template project'
          | 'clone public project'
          | 'clone private project';
        template_id?: string;
        public_access_id?: string;
        private_id?: string;
      }
    ]
  : T extends Events.CREATE_ENUMERATION
  ? [
      event: T,
      properties: {
        project_id: string;
        type:
          | 'from empty screen top'
          | 'from empty screen center'
          | 'from list';
      }
    ]
  : T extends Events.PLAN_SELECTED
  ? [event: T, properties: { project_id: string; plan_id: string }]
  : T extends Events.COMMENT_FILTER_SELECTED
  ? [
      event: T,
      properties: {
        type: 'open' | 'done' | 'all' | 'assigned';
        project_id: string;
        environment_id: string;
        model_id: string;
        entry_id: string;
      }
    ]
  : T extends
      | Events.COMMENT_CREATED
      | Events.COMMENT_DELETED
      | Events.COMMENT_UPDATED
      | Events.NOTIFICATION_CLICKED
      | Events.COMMENT_RESOLVED
      | Events.COMMENT_REOPENED
      | Events.COMMENT_LINK_COPIED
  ? [
      event: T,
      properties: {
        project_id: string;
        environment_id: string;
        model_id: string;
        entry_id: string;
        comment_id: string;
      }
    ]
  : T extends Events.REPLY_CREATED | Events.REPLY_DELETED | Events.REPLY_UPDATED
  ? [
      event: T,
      properties: {
        project_id: string;
        environment_id: string;
        model_id: string;
        entry_id: string;
        comment_id: string;
        reply_id: string;
      }
    ]
  : T extends Events.NOTIFICATION_OPENED | Events.COMMENT_MARK_ALL_AS_READ
  ? [
      event: T,
      properties: {
        project_id: string;
        environment_id: string;
      }
    ]
  : T extends Events.COMMENTS_TAB_OPENED
  ? [
      event: T,
      properties: {
        project_id: string;
        environment_id: string;
        model_id: string;
        entry_id: string;
      }
    ]
  : T extends
      | Events.DELETE_PROFILE
      | Events.DELETE_PROFILE_CANCEL
      | Events.DELETE_PROFILE_NEXT_STEP
      | Events.PROFILE_DELETING
      | Events.PROFILE_DELETED
  ? [event: T, properties: { profile_id: string }]
  : T extends
      | Events.FEEDBACK_BEACON
      | Events.FEEDBACK_BEACON_CANCEL
      | Events.FEEDBACK_BEACON_SENDING
      | Events.FEEDBACK_BEACON_SENT
  ? [
      event: T,
      properties: {
        project_id: string;
        feature_id: string;
        rating?: number | null;
      }
    ]
  : T extends
      | Events.DIRECTORY_STUDIO_TOGGLE_ENABLE
      | Events.DIRECTORY_STUDIO_TOGGLE_DISABLE
  ? [event: T]
  : never;
