import { useCallback } from 'react';
import { useManagementApiClient } from 'modules/managementApi';
import _ from 'lodash';
import { UpdateAppInput } from 'generated/graphql';
import { AppFragment } from '../gql/generated/AppFragment';
import { useUpdateAppMutation } from '../gql/generated/updateAppMutation';

export function useUpdateApp() {
  const client = useManagementApiClient();

  const [updateMutation, { loading, data, error }] = useUpdateAppMutation({
    client,
  });

  const updateApp = useCallback(
    function (data: UpdateAppInput) {
      const filteredData = _.omit(
        data,
        'publicationStatus',
        'author',
        'permissions'
      );
      return updateMutation({
        variables: {
          input: filteredData,
        },
      }).then(({ data }) => data && data.updateApp.updatedApp);
    },
    [updateMutation]
  );

  return [
    updateApp,
    {
      loading,
      app: data && (data.updateApp.updatedApp as AppFragment),
      error,
    },
  ] as const;
}
