import * as Types from '../../../../generated/graphql';

import { AppSummaryFragment_App_, AppSummaryFragment_AppForOwner_, AppSummaryFragment_AppWithSecrets_ } from './AppSummaryFragment';
import { AppElementFragment_FieldAppElement_, AppElementFragment_FormSidebarAppElement_, AppElementFragment_PageAppElement_ } from './AppElementFragment';
import { AppPermissionsFragment } from './AppPermissionsFragment';
import { ProjectBasicFragment } from '../../../projects/gql/generated/ProjectBasicFragment';
import { gql } from '@apollo/client';
import { AppSummaryFragment } from './AppSummaryFragment';
import { AppElementFragment } from './AppElementFragment';
export type AppFragment_App_ = (
  { __typename: 'App', setupUrl: string, webhookUrl?: string | null | undefined, configurationUrl?: string | null | undefined, elements?: Array<(
    { __typename: 'FieldAppElement' }
    & AppElementFragment_FieldAppElement_
  ) | (
    { __typename: 'FormSidebarAppElement' }
    & AppElementFragment_FormSidebarAppElement_
  ) | (
    { __typename: 'PageAppElement' }
    & AppElementFragment_PageAppElement_
  )> | null | undefined, permissions: (
    { __typename: 'AppPermissions' }
    & AppPermissionsFragment
  ), allowedProjects?: Array<(
    { __typename: 'Project' }
    & ProjectBasicFragment
  )> | null | undefined }
  & AppSummaryFragment_App_
);

export type AppFragment_AppForOwner_ = (
  { __typename: 'AppForOwner', setupUrl: string, webhookUrl?: string | null | undefined, configurationUrl?: string | null | undefined, elements?: Array<(
    { __typename: 'FieldAppElement' }
    & AppElementFragment_FieldAppElement_
  ) | (
    { __typename: 'FormSidebarAppElement' }
    & AppElementFragment_FormSidebarAppElement_
  ) | (
    { __typename: 'PageAppElement' }
    & AppElementFragment_PageAppElement_
  )> | null | undefined, permissions: (
    { __typename: 'AppPermissions' }
    & AppPermissionsFragment
  ), allowedProjects?: Array<(
    { __typename: 'Project' }
    & ProjectBasicFragment
  )> | null | undefined }
  & AppSummaryFragment_AppForOwner_
);

export type AppFragment_AppWithSecrets_ = (
  { __typename: 'AppWithSecrets', setupUrl: string, webhookUrl?: string | null | undefined, configurationUrl?: string | null | undefined, elements?: Array<(
    { __typename: 'FieldAppElement' }
    & AppElementFragment_FieldAppElement_
  ) | (
    { __typename: 'FormSidebarAppElement' }
    & AppElementFragment_FormSidebarAppElement_
  ) | (
    { __typename: 'PageAppElement' }
    & AppElementFragment_PageAppElement_
  )> | null | undefined, permissions: (
    { __typename: 'AppPermissions' }
    & AppPermissionsFragment
  ), allowedProjects?: Array<(
    { __typename: 'Project' }
    & ProjectBasicFragment
  )> | null | undefined }
  & AppSummaryFragment_AppWithSecrets_
);

export type AppFragment = AppFragment_App_ | AppFragment_AppForOwner_ | AppFragment_AppWithSecrets_;

export const AppFragment = gql`
    fragment AppFragment on IApp {
  ...AppSummaryFragment
  setupUrl
  webhookUrl
  configurationUrl
  elements {
    ...AppElementFragment
  }
  permissions {
    ...AppPermissionsFragment
  }
  allowedProjects {
    ...ProjectBasicFragment
  }
}
    ${AppSummaryFragment}
${AppElementFragment}
${AppPermissionsFragment}
${ProjectBasicFragment}`;