import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
export type ProjectTemplateFragment_StarterTemplate_ = { __typename: 'StarterTemplate', id: string, name: string, picture?: string | null | undefined, description?: string | null | undefined, details?: string | null | undefined, coverPicture?: string | null | undefined, stack: Array<{ __typename: 'TechnologyStack', image: string, title: string }>, resources: Array<{ __typename: 'TemplateResource', title: string, url: string }> };

export type ProjectTemplateFragment_Template_ = { __typename: 'Template', id: string, name: string, picture?: string | null | undefined, description?: string | null | undefined, details?: string | null | undefined, coverPicture?: string | null | undefined, resources: Array<{ __typename: 'TemplateResource', title: string, url: string }> };

export type ProjectTemplateFragment = ProjectTemplateFragment_StarterTemplate_ | ProjectTemplateFragment_Template_;

export const ProjectTemplateFragment = gql`
    fragment ProjectTemplateFragment on ITemplate {
  id
  name
  picture
  description
  details
  coverPicture
  resources {
    title
    url
  }
  ... on StarterTemplate {
    stack {
      image
      title
    }
  }
}
    `;