import { AppLayout } from './AppLayout';
import { AppForm } from './AppForm';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { PageTitle } from 'lib/presentation/PageTitle';
import { trans } from 'i18n';
import { Progress } from '@hygraph/baukasten';
import { useViewerAppForOwner } from 'modules/apps/hooks/useViewerAppForOwner';
import { ApolloErrorDisplay } from 'components/Error/ApolloErrorDisplay';

export function AppDetail() {
  const { apiId } = useParams<{ apiId: string }>();
  const { app, loading, error } = useViewerAppForOwner(apiId!);
  const navigate = useNavigate();
  const resetError = () => navigate('/apps');
  if (loading) return <Progress variant="slim" />;
  if (error)
    return <ApolloErrorDisplay error={error} resetError={resetError} />;

  return (
    <AppLayout>
      <PageTitle title={trans('Edit {{appName}}', { appName: app.name })} />
      <AppForm app={app} />
    </AppLayout>
  );
}
