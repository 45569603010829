import * as React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Heading,
  Progress,
  Stack,
  Text,
} from '@hygraph/baukasten';
import { type FC, useMemo } from 'react';
import { trans } from 'i18n';
import {
  regionalApiUrl,
  regionalSubscriptionsUrl,
} from 'modules/projects/helpers/regionalProjectUrl';
import { ProjectBasicFragment } from 'modules/projects/gql/generated/ProjectBasicFragment';
import { createClient } from '../../modules/managementApi/createClient';
import { useAuthClient } from '@hygraph/client-auth';

import { useProjectChangedSubscriptionQuery } from '../../modules/projects/gql/generated/projectChangedSubscriptionQuery';
import { useGetStudioOrClassicURL } from 'hooks/useGetStudioOrClassicURL';

import { useRegionalProjectQuery } from 'modules/projects/gql/generated/regionalProjectQuery';

const MigratingDialogRender = () => (
  <Dialog
    isOpen
    onDismiss={() => undefined}
    aria-labelledby="migrating"
    data-test="CreatingProjectDialog"
    size="large"
  >
    <Progress variant="slim" />
    <DialogHeader>
      <Heading as="h4" fontWeight="medium" id="migrating">
        {trans('Hang on! We are preparing your project.')}
      </Heading>
    </DialogHeader>
    <DialogContent>
      <Stack>
        <Text fontSize="copy" color="neutral.500">
          {trans("You're all set! We've started creating your project.")}
        </Text>
        <Text fontSize="copy" color="neutral.500">
          {trans(
            "Depending on the size of the source project, this may take some time. As soon as the process is completed, you'll be able to interact with your new project."
          )}
        </Text>
      </Stack>
    </DialogContent>
  </Dialog>
);

interface Props {
  projectId: string;
}

export const MigratingDialog: FC<Props> = props => {
  const { projectId } = props;

  const url = new URL(window.location.href);
  const region = url.searchParams.get('region') as string;

  const { token, logout } = useAuthClient();
  const { getUrl } = useGetStudioOrClassicURL();
  const regionalClient = useMemo(
    () =>
      createClient({
        token,
        onAuthError: logout,
        apiUrl: regionalApiUrl(region) ?? '',
        subscriptionsUrl: regionalSubscriptionsUrl(region),
      }),
    [token, region, logout]
  );

  // This polling is required in case project cloning is finished before subscription is set up.
  // In those cases managementApi won't send any message.
  const { startPolling, stopPolling } = useRegionalProjectQuery({
    variables: {
      id: projectId,
    },
    client: regionalClient,
    fetchPolicy: 'network-only',
    onCompleted: ({ viewer }) => {
      if (!viewer.project) return;
      window.location.href = getUrl(viewer.project as ProjectBasicFragment);
    },
  });

  React.useEffect(() => {
    const onFocus = () => {
      startPolling(5000);
    };
    const onFocusOut = () => {
      stopPolling();
    };

    window.addEventListener('focus', onFocus);
    window.addEventListener('focusout', onFocusOut);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('focusout', onFocusOut);
    };
  }, [startPolling, stopPolling]);

  useProjectChangedSubscriptionQuery({
    skip: region === undefined,
    client: regionalClient,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data) return;
      if (
        data.projectChanged.clonedProject.id.replace(/-/g, '') !==
        projectId.replace(/-/g, '')
      )
        return;

      window.location.href = getUrl({
        id: data.projectChanged.clonedProject.id,
        region: { id: region },
      } as ProjectBasicFragment);
    },
  });

  // INFO: show a dialog while we wait for cloning to finish
  return <MigratingDialogRender />;
};
