import {
  Card,
  Avatar,
  Flex,
  Heading,
  Text,
  Button,
  useToast,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogFooter,
  Stack,
  Inline,
} from '@hygraph/baukasten';
import { WithModalProps } from 'components/Modal/ModalContext';
import { useModal } from 'components/Modal/useModal';
import { useAcceptInvite, useDeclineInvite } from 'modules/invites/hooks';
import { trans } from 'i18n';
import { PendingInviteFragment } from 'modules/projects/gql/generated/PendingInviteFragment';
import { getTimeAgo } from 'utils/dates';
import { InviteStatus } from 'generated/graphql';

interface DeclineInviteDialogProps {
  inviteDetails: PendingInviteFragment;
}

export const DeclineInviteDialog = ({
  onRequestClose,
  inviteDetails,
}: WithModalProps<DeclineInviteDialogProps>) => {
  const [declineInvite, { loading }] = useDeclineInvite();
  const toast = useToast();

  return (
    <Dialog
      isOpen
      onDismiss={onRequestClose}
      aria-labelledby="simple-confirm-header"
      data-test="DeclineInviteDialog"
    >
      <DialogHeader>
        <Heading as="h4" fontWeight="medium" id="simple-confirm-header">
          {trans('Decline invite')}
        </Heading>
      </DialogHeader>
      <DialogContent>
        <Stack gap="24">
          <Text fontSize="copy" fontWeight="medium">
            {trans('Are you sure you want to decline this invite?')}
          </Text>
          <Card
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
            p="sm"
          >
            <Avatar
              size="avatar.40"
              alignItems="center"
              placeholder={inviteDetails.project.name}
              src={inviteDetails.project.picture || undefined}
              square
              display="block"
              role="presentation"
            />
            <Stack gap="none" ml="sm" height="100%">
              <Heading as="h6" fontSize="tiny" fontWeight="medium">
                {inviteDetails.project.name}
              </Heading>
              {inviteDetails.issuer && (
                <Inline alignItems="center" gap="4">
                  <Avatar
                    size="avatar.16"
                    placeholder={inviteDetails.issuer.profile.name}
                    src={inviteDetails.issuer.profile.picture || undefined}
                  />
                  <Text fontWeight="medium" fontSize="tiny" color="neutral.500">
                    {inviteDetails.issuer.profile.name}
                  </Text>
                </Inline>
              )}
            </Stack>
          </Card>
        </Stack>
      </DialogContent>
      <DialogFooter>
        <Button
          onClick={onRequestClose}
          variant="ghost"
          size="large"
          disabled={loading}
          data-test="PendingInviteDeclineCancelButton"
        >
          {trans('Cancel')}
        </Button>
        <Button
          onClick={handleDeclineInvite}
          size="large"
          variantColor="danger"
          disabled={loading}
          loading={loading}
          loadingText={trans('Declining')}
          data-test="PendingInviteDeclineButton"
        >
          {trans('Decline invite')}
        </Button>
      </DialogFooter>
    </Dialog>
  );

  function handleDeclineInvite() {
    declineInvite({ id: inviteDetails.id })
      .then(() => {
        onRequestClose();
        toast({
          variantColor: 'success',
          description: trans('Invite declined successfully'), // TODO: add proper message for declining status
        });
      })
      .catch(() =>
        toast({
          description: trans(
            'Something wrong happened while declining the invitation'
          ),
        })
      )
      .finally(onRequestClose);
  }
};

interface PendingInviteDetailsProps {
  pendingInvite: PendingInviteFragment;
}

const PendingInviteDetails = ({ pendingInvite }: PendingInviteDetailsProps) => {
  const {
    project: { picture, name },
  } = pendingInvite;

  const [acceptInvite, { loading: loadingAcceptInvite }] = useAcceptInvite();
  const toast = useToast();
  const { showModal } = useModal();

  const roles = pendingInvite?.roles?.join(', ');

  return (
    <Card
      borderRadius="medium"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      p="sm"
    >
      <Avatar
        src={picture || undefined}
        size="avatar.80"
        placeholder={name}
        borderRadius="small"
        square
      />
      <Stack gap="4" ml="m" width="100%" height="100%">
        <Flex justifyContent="space-between" width="100%">
          <Stack gap="none">
            <Heading as="h6" fontWeight="medium">
              {name}
            </Heading>
            <Inline gap="4">
              <Text fontWeight="medium" fontSize="tiny" color="neutral.500">
                {/* TRANS component refactor */}
                {trans('You are invited as')}
              </Text>
              <Text fontWeight="medium" fontSize="tiny" color="neutral.900">
                {roles}
              </Text>
            </Inline>
          </Stack>
          <Text fontSize="tiny" color="neutral.500">
            {getTimeAgo(new Date(pendingInvite.createdAt))}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" width="100%">
          {pendingInvite.issuer && (
            <Inline alignItems="center" gap="4">
              <Avatar
                size="avatar.16"
                placeholder={pendingInvite.issuer.profile.name}
                src={pendingInvite.issuer.profile.picture || undefined}
              />
              <Text fontWeight="medium" fontSize="tiny" color="neutral.500">
                {pendingInvite.issuer.profile.name}
              </Text>
            </Inline>
          )}
          <Inline>
            <Button
              variant="ghost"
              size="small"
              minWidth="80px"
              onClick={() =>
                showModal(DeclineInviteDialog, {
                  inviteDetails: pendingInvite,
                })
              }
              data-testid="PendingInviteDeclineDialogButton"
              disabled={pendingInvite.status !== 'PENDING'}
            >
              {trans('Decline')}
            </Button>
            <Button
              size="small"
              minWidth="80px"
              onClick={handleAcceptInvite}
              loading={
                loadingAcceptInvite ||
                pendingInvite.status === InviteStatus.ACCEPTING
              }
              loadingText={trans('Accepting')}
              // The button is too small to accommodate text and spinner, hence temporarily adding null
              spinnerComp={() => null}
              data-testid="PendingInviteAcceptButton"
              disabled={pendingInvite.status !== 'PENDING'}
            >
              {trans('Accept')}
            </Button>
          </Inline>
        </Flex>
      </Stack>
    </Card>
  );

  function handleAcceptInvite() {
    acceptInvite({ code: pendingInvite.code }).catch(() => {
      toast({
        description: trans(
          'Something wrong happened while processing the invitation'
        ),
      });
    });
  }
};

interface ProjectListPendingInvitesProps {
  pendingInvites: PendingInviteFragment[];
}

export const ProjectListPendingInvites = (
  props: ProjectListPendingInvitesProps
) => {
  const { pendingInvites } = props;
  return (
    <Stack maxWidth="960" gap="4" px="ml" py="xl" margin="0 auto">
      <Heading fontSize="h4" fontWeight="bold" ml="72px">
        {trans('My invites ({{length}})', {
          length: pendingInvites.length,
        })}
      </Heading>
      <Stack gap="16" ml="72px" mr="52px" data-testid="PendingInviteList">
        {pendingInvites.map(pendingInvite => (
          <PendingInviteDetails
            key={pendingInvite.id}
            pendingInvite={pendingInvite}
          />
        ))}
      </Stack>
    </Stack>
  );
};
