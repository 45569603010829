import CodeMirror from 'codemirror';
import jsonlint from 'jsonlint-mod';

// CodeMirror requires window.jsonlint
window.jsonlint = jsonlint;

interface Loc {
  first_line: number;
  last_line: number;
  first_column: number;
  last_column: number;
}

interface Hash {
  loc: Loc;
}

interface CodeMirrorJSONLintErrors {
  from: CodeMirror.Position;
  to: CodeMirror.Position;
  message: string;
}

/**
 * Returns if a string is equal to the error that jsonlint emits when it analyzes an empty JSON value
 */
const isEmptyEditorError = (str: string) => {
  return (
    str ===
    "Parse error on line 1:\n\n^\nExpecting 'STRING', 'NUMBER', 'NULL', 'TRUE', 'FALSE', '{', '[', got 'EOF'"
  );
};

/**
 * CodeMirror JSON Lint helper
 *
 * Based on a JSON Linting solution from the React CodeMirror 2 issue tracker
 * https://github.com/scniro/react-codemirror2/issues/21#issuecomment-373591088
 */
export const JSONLintHelper = (text: string) => {
  const found: CodeMirrorJSONLintErrors[] = [];
  jsonlint.parser.parseError = (str: string, hash: Hash) => {
    const loc = hash.loc;
    if (!isEmptyEditorError(str)) {
      found.push({
        from: CodeMirror.Pos(loc.first_line - 1, loc.first_column),
        to: CodeMirror.Pos(loc.last_line - 1, loc.last_column),
        message: str,
      });
    }
  };
  try {
    jsonlint.parser.parse(text);
  } catch (e) {}
  return found;
};
