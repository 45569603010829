import * as Types from '../../../../generated/graphql';

import { UserFragment } from '../../../user/gql/generated/UserFragment';
import { ProjectBasicFragment } from './ProjectBasicFragment';
import { PendingInviteFragment } from './PendingInviteFragment';
import { ProjectTemplateFragment_StarterTemplate_, ProjectTemplateFragment_Template_ } from './ProjectTemplateFragment';
import { RegionFragment } from './RegionFragment';
import { gql } from '@apollo/client';
import { ProjectTemplateFragment } from './ProjectTemplateFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProjectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProjectsQuery = { __typename: 'Query', viewer: { __typename: 'UserViewer', id: string, user: (
      { __typename: 'User' }
      & UserFragment
    ), projects: Array<(
      { __typename: 'Project' }
      & ProjectBasicFragment
    )>, pendingInvites: Array<(
      { __typename: 'Invite' }
      & PendingInviteFragment
    )>, pendingProjects: Array<{ __typename: 'Project', id: string, name: string, description?: string | null | undefined, picture?: string | null | undefined }>, templates: Array<(
      { __typename: 'StarterTemplate' }
      & ProjectTemplateFragment_StarterTemplate_
    ) | (
      { __typename: 'Template' }
      & ProjectTemplateFragment_Template_
    )>, regions: Array<(
      { __typename: 'Region' }
      & RegionFragment
    )> } };


export const ProjectsQueryDocument = gql`
    query projectsQuery {
  viewer {
    id
    ... on UserViewer {
      user {
        ...UserFragment
      }
      projects {
        ...ProjectBasicFragment
      }
      pendingInvites {
        ...PendingInviteFragment
      }
      pendingProjects {
        id
        name
        description
        picture
      }
    }
    templates {
      ...ProjectTemplateFragment
    }
    regions {
      ...RegionFragment
    }
  }
}
    ${UserFragment}
${ProjectBasicFragment}
${PendingInviteFragment}
${ProjectTemplateFragment}
${RegionFragment}`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsQueryDocument, options);
      }
export function useProjectsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsQueryDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsQueryLazyQueryHookResult = ReturnType<typeof useProjectsQueryLazyQuery>;