import { Field, FieldRenderProps } from 'react-final-form';
import { trans } from 'i18n';
import {
  Label,
  Hint,
  InputError,
  Text,
  Stack,
  StackProps,
} from '@hygraph/baukasten';
import identity from 'lodash/identity';
import { FieldValidator } from 'final-form';

interface GFieldProps extends Omit<StackProps, 'children'> {
  name: string;
  label?: string;
  hint?: string;
  isOptional?: boolean;
  displayErrorsOnlyAfterSubmit?: boolean;
  validate?: FieldValidator<any>;
  render: (
    fieldProps: FieldRenderProps<any> & { hasError: boolean }
  ) => JSX.Element | null;
  displayErrors?: boolean;
}

export const GField = ({
  name,
  label,
  render,
  hint,
  isOptional = false,
  displayErrorsOnlyAfterSubmit = false,
  displayErrors = true,
  validate,
  defaultValue,
  ...rest
}: GFieldProps) => (
  <Field
    name={name}
    parse={isOptional ? identity : undefined}
    validate={validate}
    defaultValue={defaultValue}
  >
    {({ input, meta }) => {
      const { error, touched, submitFailed } = meta;
      const hasError =
        !!error && (displayErrorsOnlyAfterSubmit ? submitFailed : touched);

      return (
        <Stack gap="8" {...rest}>
          {(label || hint) && (
            <Stack gap="4">
              {label && (
                <Label htmlFor={name}>
                  {label}
                  {isOptional ? (
                    <>
                      &nbsp;
                      <Text color="neutral.500">{trans('(optional)')}</Text>
                    </>
                  ) : null}
                </Label>
              )}
              {hint && <Hint>{hint}</Hint>}
            </Stack>
          )}
          {render({
            input: { ...input, id: name },
            meta,
            hasError: Boolean(hasError),
          })}
          {hasError && displayErrors && <InputError>{error}</InputError>}
        </Stack>
      );
    }}
  </Field>
);
