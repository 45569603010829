import { useCallback } from 'react';
import { useManagementApiClient } from 'modules/managementApi';
import { CreateProjectInput } from 'generated/graphql';
import { useCreateProjectMutation } from '../gql/generated/createProjectMutation';
import { ProjectsQueryDocument } from '../gql/generated/projectsQuery';

export function useCreateProject() {
  const client = useManagementApiClient();

  const [createMutation, response] = useCreateProjectMutation({
    client,
    refetchQueries: [
      {
        query: ProjectsQueryDocument,
      },
    ],
    awaitRefetchQueries: true,
  });

  const createProject = useCallback(
    function (project: CreateProjectInput) {
      return createMutation({
        variables: {
          data: { ...project },
        },
      }).then(value => value.data);
    },
    [createMutation]
  );

  return [
    createProject,
    {
      loading: response.loading,
      project: response.data && response.data.createProject,
      error: response.error,
    },
  ] as const;
}
