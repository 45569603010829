import { Progress, Box } from '@hygraph/baukasten';
import { ModalProvider } from 'components/Modal/ModalContext';
import ModalRoot from 'components/Modal/ModalRoot';
import ProjectListBottomBar from 'components/ProjectList/ProjectListBottomBar';
import ProjectListTopBar from 'components/ProjectList/ProjectListTopBar';
import { UseUserContext } from 'hooks/useUser';
import { useAuthClient } from '@hygraph/client-auth';
import { useUser } from 'modules/user/hooks/useUser';
import { AppDetail } from './AppDetail';
import { AppInstallScopePicker } from './AppInstallScopePicker';
import { AppsList } from './AppsList';
import { NewApp } from './NewApp';
import { Routes, Route } from 'react-router-dom-v5-compat';

export function Apps() {
  const { logout } = useAuthClient();
  const { loading, error, user } = useUser();

  if (error) {
    throw error;
  }
  if (loading) {
    return <Progress variant="slim" />;
  }
  if (!user) {
    return null;
  }

  return (
    <UseUserContext.Provider value={user}>
      <ModalProvider>
        <ProjectListTopBar />
        <Box
          pt="layoutXL"
          pb="56px"
          minHeight="100vh"
          fontSize="1rem"
          sx={{
            '@media only screen and (max-width: 1200px)': {
              fontSize: '0.9rem',
            },
            '@media only screen and (max-width: 960px)': {
              fontSize: '0.8rem',
            },
          }}
        >
          <Routes>
            <Route index element={<AppsList />} />
            <Route path="new" element={<NewApp />} />
            <Route path=":apiId" element={<AppDetail />} />
            <Route path=":appId/new" element={<AppInstallScopePicker />} />
          </Routes>
        </Box>
        <ProjectListBottomBar logout={logout} user={user} />
        <ModalRoot />
      </ModalProvider>
    </UseUserContext.Provider>
  );
}
