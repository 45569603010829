import { CRM_API_ADDR as apiUrl } from 'env';
import { useEffect, useState } from 'react';
import { welcomeSurveyQuery } from 'modules/projects/gql/welcomeSurveyQuery';

export interface WelcomeSurveyType {
  welcomeSurveyCompaniesLogos: {
    id: string;
    logo: {
      id: string;
      url: string;
    };
    logoTitle: string;
  }[];
  welcomeSurveyCasesStudies: {
    id: string;
    title: string;
    authorName: string;
    authorRole: string;
    authorPicture: {
      id: string;
      url: string;
    };
  }[];
}

export function useWelcomeSurvey() {
  const [data, setData] = useState<WelcomeSurveyType | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!apiUrl) {
      setError(new Error('CRM_API_ADDR is missing!'));
    } else {
      const query = welcomeSurveyQuery();
      setLoading(true);
      fetch(apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query,
        }),
      })
        .then(res => res.json())
        .then(result => setData(result?.data))
        .catch(err => setError(err))
        .finally(() => setLoading(false));
    }
  }, []);

  return { data, loading, error };
}
