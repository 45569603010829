import { UserViewer } from 'generated/graphql';
import { useManagementApiClient } from 'modules/managementApi';
import { useCommonAssetConfigQuery } from '../gql/generated/commonAssetConfigQuery';

export function useCommonAssetConfig() {
  const client = useManagementApiClient();
  const { loading, data, error } = useCommonAssetConfigQuery({
    client,
  });

  if (error) {
    throw error;
  }

  return {
    loading,
    error,
    assetConfig: data && (data.viewer as UserViewer).commonAssetConfig,
  };
}
