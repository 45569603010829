import * as React from 'react';
import { GlobalErrorOverlay } from 'components/Error/GlobalErrorOverlay';
import { Link } from 'react-router-dom';
import { Button, Text, Inline, PropsOf } from '@hygraph/baukasten';
import { AskForHelpCTA } from 'components/Error/AskForHelpCTA';
import { trans } from 'i18n';

interface Props {
  error: Error;
  resetError: () => void;
}

export const GenericGlobalErrorDisplay: React.FC<Props> = ({
  error,
  resetError,
}) => (
  <GlobalErrorOverlay>
    <Text color="neutral.500">{error.message}</Text>
    <Inline>
      <AskForHelpCTA errorMessage={error.message} />
      <Button<PropsOf<Link>> size="large" onClick={resetError} as={Link} to="/">
        {trans('Return to Projects')}
      </Button>
    </Inline>
  </GlobalErrorOverlay>
);
