import { useProjects } from 'modules/projects/hooks';

export function usePendingInvite(code: string) {
  const { loading, error, projectListData } = useProjects();

  if (error) {
    throw error;
  }

  return {
    loading,
    error,
    pendingInvite: projectListData
      ? projectListData?.pendingInvites?.find(p => p.code === code)
      : null,
  };
}
