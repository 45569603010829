import * as React from 'react';
import { Box, Flex, Button, Heading, Stack } from '@hygraph/baukasten';
import {
  Warning as WarningIcon,
  ExternalLink as ExternalLinkIcon,
} from '@hygraph/icons';
import { trans } from 'i18n';
import { PRODUCT_DOMAIN } from 'env';

export const GlobalErrorOverlay: React.FC = ({ children }) => (
  <Stack
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    data-test="ErrorWrapper"
    height="100vh"
  >
    <Stack justifyContent="center" alignItems="center">
      <Flex
        size="80"
        backgroundColor="yellow.50"
        justifyContent="center"
        alignItems="center"
      >
        <Box as={WarningIcon} color="yellow.500" size="28" />
      </Flex>
      <Heading fontFamily="heading" fontSize="h1">
        {trans('Something went wrong')}
      </Heading>
    </Stack>
    <Stack alignItems="center">{children}</Stack>
    <Box>
      <Button<PropsOf<'a'>>
        as="a"
        variant="link"
        target="_blank"
        href={`https://status.${PRODUCT_DOMAIN}/`}
        iconAfter={ExternalLinkIcon}
      >
        {trans('Subscribe to updates on our Statuspage')}
      </Button>
    </Box>
  </Stack>
);
