import { useCallback } from 'react';
import { AcceptInviteInput } from 'generated/graphql';
import { useManagementApiClient } from 'modules/managementApi';
import { useAcceptInviteMutation } from '../gql/generated/acceptInviteMutation';

export function useAcceptInvite() {
  const client = useManagementApiClient();

  const [inviteMutation, response] = useAcceptInviteMutation({
    client,
  });

  const acceptInvite = useCallback(
    function (input: AcceptInviteInput) {
      return inviteMutation({
        variables: { input },
      }).then(value => value.data && value.data.acceptInvite.invite);
    },
    [inviteMutation]
  );

  return [
    acceptInvite,
    {
      loading: response.loading,
      invite: response.data && response.data.acceptInvite.invite,
      error: response.error,
    },
  ] as const;
}
