import { UserViewer } from 'generated/graphql';
import { useManagementApiClient } from 'modules/managementApi';
import { useViewerAppForOwnerQuery } from '../gql/generated/viewerAppForOwnerQuery';
import { AppForOwnerFragment } from '../gql/generated/AppForOwnerFragment';

export function useViewerAppForOwner(apiId: string) {
  const client = useManagementApiClient();
  const { loading, data, error } = useViewerAppForOwnerQuery({
    client,
    variables: { apiId },
  });

  return {
    loading,
    error,
    app: {
      ...(data?.viewer as UserViewer | undefined)?.app,
    } as AppForOwnerFragment,
  };
}
