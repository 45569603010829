import * as React from 'react';
import { Stack } from '@hygraph/baukasten';

export function AppLayout({ children }) {
  React.useLayoutEffect(() => {
    window.scroll({ top: 0 });
  }, []);
  return (
    <Stack maxWidth="792" py="xl" width="100%" margin="0 auto">
      {children}
    </Stack>
  );
}
