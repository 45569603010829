import {
  differenceInYears,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from 'date-fns';

export const getTimeAgo = (createdAt: Date, now = new Date()) => {
  const diffMins = differenceInMinutes(now, createdAt);
  const diffHrs = differenceInHours(now, createdAt);
  const diffDays = differenceInDays(now, createdAt);
  const diffYears = differenceInYears(now, createdAt);

  if (diffYears) return `${diffYears} ${diffYears > 1 ? 'years' : 'year'} ago`;
  if (diffDays) return `${diffDays} ${diffDays > 1 ? 'days' : 'day'} ago`;
  if (diffHrs) return `${diffHrs} ${diffHrs > 1 ? 'hours' : 'hour'} ago`;
  if (diffMins) return `${diffMins} ${diffMins > 1 ? 'minutes' : 'minute'} ago`;
};
