import * as Types from '../../../../generated/graphql';

import { gql } from '@apollo/client';
export type AppSummaryFragment_App_ = { __typename: 'App', id: string, apiId: string, author: string, name: string, description: string, avatarUrl: string, publicationStatus: Types.AppPublicationStatus };

export type AppSummaryFragment_AppForOwner_ = { __typename: 'AppForOwner', id: string, apiId: string, author: string, name: string, description: string, avatarUrl: string, publicationStatus: Types.AppPublicationStatus };

export type AppSummaryFragment_AppWithSecrets_ = { __typename: 'AppWithSecrets', id: string, apiId: string, author: string, name: string, description: string, avatarUrl: string, publicationStatus: Types.AppPublicationStatus };

export type AppSummaryFragment = AppSummaryFragment_App_ | AppSummaryFragment_AppForOwner_ | AppSummaryFragment_AppWithSecrets_;

export const AppSummaryFragment = gql`
    fragment AppSummaryFragment on IApp {
  id
  apiId
  author
  name
  description
  avatarUrl
  publicationStatus
}
    `;