import { useCallback } from 'react';
import { useManagementApiClient } from 'modules/managementApi';
import { CreateAppInput } from 'generated/graphql';
import { useCreateAppMutation } from '../gql/generated/createAppMutation';
import { ViewerAppsQueryDocument } from '../gql/generated/viewerAppsQuery';
import { AppWithSecretsFragment } from 'modules/apps/gql/generated/AppWithSecretsFragment';

export function useCreateApp() {
  const client = useManagementApiClient();

  const [createMutation, { loading, data, error }] = useCreateAppMutation({
    client,
    refetchQueries: [{ query: ViewerAppsQueryDocument }],
  });

  const createApp = useCallback(
    function (input: CreateAppInput) {
      return createMutation({
        variables: {
          input,
        },
      }).then(({ data }) => data && data.createApp.createdApp);
    },
    [createMutation]
  );

  return [
    createApp,
    {
      loading,
      app: data && (data.createApp.createdApp as AppWithSecretsFragment),
      error,
    },
  ] as const;
}
