import { useState, createContext, useCallback } from 'react';

type Modal = {
  name: string;
  component: React.ElementType | null;
  props: Record<string, unknown>;
};
export interface ModalContextProps {
  modals: Array<Modal>;
  showModal<T extends React.ElementType>(
    component: T,
    props?: Omit<React.ComponentPropsWithoutRef<T>, 'onRequestClose'>,
    name?: string
  ): void;
  hideModal: (name: string) => () => void;
}

export type WithModalProps<
  T extends Record<string, any> = Record<string, never>
> = {
  onRequestClose: () => void;
} & T;

export const ModalContext = createContext<ModalContextProps>({
  modals: [],
  showModal: () => {
    console.error('Not in a ModalContext');
  },
  hideModal: () => () => {
    console.error('Not in a ModalContext');
  },
});

export const ModalProvider: React.FC = function ({ children }) {
  const [modals, setModals] = useState<Array<Modal>>([]);

  const showModal: ModalContextProps['showModal'] = useCallback(
    function showModal(component, props, name) {
      const modalId = Math.random().toString(36).substring(7);

      setModals(currentModals => {
        return [
          ...currentModals,
          {
            name: name || `modal-${modalId}`,
            component,
            props,
          } as Modal,
        ];
      });
    },
    []
  );

  const hideModal = useCallback(
    (name: string) => () => {
      setModals(currentModals =>
        currentModals.filter(modal => modal.name !== name)
      );
    },
    []
  );

  const contextData = {
    modals,
    showModal,
    hideModal,
  };

  return (
    <ModalContext.Provider value={contextData}>
      {children}
    </ModalContext.Provider>
  );
};

export const ModalConsumer = ModalContext.Consumer;
