import { ApolloError } from '@apollo/client';

export enum ErrorType {
  Network,
  Expected,
  Unexpected,
  Client,
}

export type BoundaryError = ApolloError | Error;

export class AuthError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AuthError';
  }
}

export class MaintenanceError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'MaintenanceError';
  }
}
