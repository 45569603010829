import React from 'react';
import { BrowserRouter as ReactRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';

import { BaukastenProvider, ToastContainer } from '@hygraph/baukasten';
import { MuiThemeProvider } from '@hygraph/theme-legacy';
import App from 'components/App';
import { ErrorBoundary, MaintenanceError } from 'components/Error';

import { AuthClientProvider } from '@hygraph/client-auth';

import InviteProvider from 'modules/user/invite/InviteProvider';
import { ManagementApiProvider } from 'modules/managementApi';

import {
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH_COOKIE_DOMAIN,
  MAINTENANCE,
  MANAGEMENT_API_ADDR,
  MANAGEMENT_API_ADDR_SUBSCRIPTIONS,
  STUDIO_LOGOUT_HOSTNAME,
} from 'env';

// for local development and preview deployments
function topDomainFromLocation() {
  const { hostname } = location;
  if (hostname === 'localhost') return hostname;
  const index = hostname.search(/\.(hygraph|hygraph-previews|vercel)\./);
  return hostname.slice(index + 1);
}

function directoryAppHostnameFromLocation() {
  // to be refactored once we have regional subdomains
  return location.hostname;
}

const authConfig = {
  auth0Domain: AUTH0_DOMAIN,
  auth0ClientId: AUTH0_CLIENT_ID,
  cookieDomain: AUTH_COOKIE_DOMAIN || topDomainFromLocation(),
  directoryHostname: directoryAppHostnameFromLocation(),
  studioLogoutHostname: STUDIO_LOGOUT_HOSTNAME,
};

export function Main() {
  if (MAINTENANCE) {
    // dirty hack to make the error boundary display a MaintenanceError
    const Thrower = () => {
      throw new MaintenanceError(
        'Planned maintenance in progress. We will be back soon.'
      );
    };
    return (
      <BaukastenProvider global>
        <ErrorBoundary>
          <Thrower />
        </ErrorBoundary>
      </BaukastenProvider>
    );
  }

  return (
    <AuthClientProvider config={authConfig}>
      <InviteProvider>
        <AppShell />
      </InviteProvider>
    </AuthClientProvider>
  );
}

function AppShell() {
  return (
    <BaukastenProvider global>
      <MuiThemeProvider>
        <ManagementApiProvider
          apiUrl={MANAGEMENT_API_ADDR}
          subscriptionsUrl={MANAGEMENT_API_ADDR_SUBSCRIPTIONS}
        >
          <ReactRouter>
            <CompatRouter>
              <App />
            </CompatRouter>
          </ReactRouter>
          <ToastContainer />
        </ManagementApiProvider>
      </MuiThemeProvider>
    </BaukastenProvider>
  );
}
