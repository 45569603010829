import { Field, FieldRenderProps } from 'react-final-form';
import * as React from 'react';
import {
  Stack,
  Label,
  Hint,
  InputError,
  Box,
  BoxProps,
  LabelProps,
} from '@hygraph/baukasten';
import { generateRandomId } from 'utils';
import { isObject } from 'lodash';

interface RadioGFieldProps {
  name: string;
  label?: string | JSX.Element;
  hint?: string;
  disabled?: boolean;
  hideErrors?: boolean;
  value?: string;
  render: (fieldProps: FieldRenderProps<any>) => JSX.Element | null;
  labelProps?: BoxProps;
  labelParentProps?: LabelProps;
  defaultValue?: any;
}

export const RadioGField: React.FC<RadioGFieldProps> = ({
  name,
  label,
  render,
  hint,
  value,
  disabled = false,
  labelProps,
  labelParentProps,
  hideErrors = false,
  defaultValue,
  ...rest
}) => {
  const fieldId = `${name}-${generateRandomId()}`;
  return (
    <Field name={name} type="radio" value={value} defaultValue={defaultValue}>
      {({ input, meta }) => {
        const { error, touched } = meta;
        const hasError = hideErrors ? false : !!error && touched;

        return (
          <Stack gap="4" {...rest}>
            <Label
              htmlFor={fieldId}
              sx={{ width: 'fit-content' }}
              gap="8"
              {...labelParentProps}
            >
              {render({ input: { ...input, id: fieldId }, meta })}
              <Box
                as="span"
                color={
                  disabled ? 'input.label.disabled' : 'input.label.default'
                }
                {...labelProps}
              >
                {label}
              </Box>
            </Label>
            {hint && (
              <Hint
                ml="24"
                color={disabled ? 'input.hint.disabled' : 'input.hint.default'}
              >
                {hint}
              </Hint>
            )}
            {hasError ? (
              isObject(error) ? (
                Object.values(error).map((e, index) => (
                  <InputError key={index}>{e}</InputError>
                ))
              ) : (
                <InputError>{error}</InputError>
              )
            ) : null}
          </Stack>
        );
      }}
    </Field>
  );
};
