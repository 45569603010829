import { DropdownMenuList, DropdownMenuItem } from '@hygraph/baukasten';
import { useNavigate } from 'react-router-dom-v5-compat';
import { trans } from 'i18n';
import { getTitleWithoutSuffix } from 'lib/presentation/PageTitle';
import { first } from 'lodash';

interface AccountMenuProps {
  logout: ({ logoutFromStudio: boolean }) => void;
  menuListProps?: React.ComponentProps<typeof DropdownMenuList>;
}

export function AccountMenu({ logout, menuListProps = {} }: AccountMenuProps) {
  const navigate = useNavigate();

  return (
    <DropdownMenuList {...menuListProps}>
      <DropdownMenuItem
        onSelect={() => {
          navigate('/apps', {
            state: {
              prevTitle: first(getTitleWithoutSuffix()),
            },
          });
        }}
      >
        {trans('Your apps')}
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={() =>
          navigate('/account', { state: { prevPath: location.pathname } })
        }
      >
        {trans('Account Settings')}
      </DropdownMenuItem>
      <DropdownMenuItem onClick={() => logout({ logoutFromStudio: true })}>
        {trans('Logout')}
      </DropdownMenuItem>
    </DropdownMenuList>
  );
}
