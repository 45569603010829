import { FULL_STORY_ENABLED } from 'env';
import * as React from 'react';

export function useTrackFullstoryUser(
  user?: {
    id: string;
    email: string;
    createdAt: Date;
  } | null,
  project?: {
    projectPlan: string;
    projectId: string;
    projectRegion: string;
    projectRoles: string[];
    projectIsOwner: boolean;
  } | null
) {
  const FS = typeof window !== 'undefined' && window.FS;
  React.useEffect(() => {
    if (FULL_STORY_ENABLED && user && FS) {
      const { id, email, createdAt } = user;

      let payload = {
        email: email.replace(/^.*@/i, ''),
        // custom fields
        createdAt,
      };

      if (project) {
        payload = {
          ...payload,
          // custom fields
          ...project,
        };
      }

      FS.identify(id, payload);
    }
  }, [user, FS, project]);
}
